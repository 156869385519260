import { getBalances } from "../axios/api";
import store from "@/store";
import { setCookie, getCookie, getStore, removeStore } from "../config/utils";

let common = {
  getLevelCode: (levelName) => {
    let res = levelName;
    switch (levelName) {
      case "新会员":
        res = 1;
        break;
      case "忠实会员":
        res = 2;
        break;
      case "星级会员":
        res = 3;
        break;
      case "银牌VIP":
        res = 4;
        break;
      case "金牌VIP":
        res = 5;
        break;
      case "钻石VIP":
        res = 6;
        break;
      case "超级VIP":
        res = 7;
        break;
      case "至尊VIP":
        res = 8;
        break;
      case "特邀VIP":
        res = 9;
        break;
      case "黑耀会员":
        res = 10;
        break;
    }
    return String(res);
  },
  logoutEvent: (object) => {
    setCookie("zz", "", -1);
    setCookie("LoginName", "", -1);
    setCookie("blance", "", -1);
    setCookie("test", "", -1);
    setCookie("p", "", -1);
    // removeStore('ui');
    // removeStore('lv');
  },
  uuid: () => {
    return "xxx4xx".replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  getOnlinePayType: () => {
    let payType = {
      1: {
        code: "Wechat",
        name: "微信支付",
      },
      2: {
        code: "Alipay",
        name: "支付宝",
      },
      3: {
        code: "QQWallet",
        name: "QQ钱包",
      },
      5: {
        code: "WechatH5",
        name: "微信H5",
      },
      6: {
        code: "AlipayH5",
        name: "支付宝H5",
      },
      7: {
        code: "QQWalletH5",
        name: "QQ钱包H5",
      },
      8: {
        code: "WechatWap",
        name: "微信WAP",
      },
      9: {
        code: "AlipayWap",
        name: "支付宝WAP",
        url: "ico_alipay.png",
      },
      10: {
        code: "QQWalletWap",
        name: "QQ钱包WAP",
      },
      11: {
        code: "UnionWap",
        name: "银联WAP",
      },
      12: {
        code: "JdWap",
        name: "京东WAP",
      },
      13: {
        code: "QuickPay",
        name: "快捷支付",
      },
      14: {
        code: "UnionPay",
        name: "银联扫码",
      },
      15: {
        code: "Cashier",
        name: "收银台快捷",
      },
      16: {
        code: "UnionPc",
        name: "银联PC",
      },
      17: {
        code: "Jd",
        name: "京东扫码",
      },
      18: {
        code: "SuNing",
        name: "苏宁扫码",
      },
      19: {
        code: "BaiDu",
        name: "百度扫码",
      },
      20: {
        code: "TenPay",
        name: "财付通扫码",
      },
      21: {
        code: "TenPayWap",
        name: "财付通wap",
      },
      22: {
        code: "UnionH5",
        name: "银联H5",
      },
      23: {
        code: "WechatBarCode",
        name: "微信条码",
      },
      24: {
        code: "AlipayBarCode",
        name: "支付宝条码",
      },
      25: {
        code: "QQWalletBarCode",
        name: "QQ钱包条码",
      },
      26: {
        code: "JdH5",
        name: "京东H5",
      },
      27: {
        code: "Mbank",
        name: "手机App扫码",
      },
      28: {
        code: "MwabQuick",
        name: "手机快捷支付",
      },
      29: { code: "YunShanFu", name: "云闪付" },
      30: { code: "AliPay_QR_ToBank", name: "手机快捷支付" },
      31: { code: "WeChat_QR_ToBank", name: "手机快捷支付" },
      32: { code: "AliPay_QR_ToBankApp", name: "手机快捷支付" },
      33: { code: "OnlineBank_ManualTransfer", name: "网银转账" },

      38: { code: "NetBanking", name: "Net Banking" },
      39: { code: "NetWallets", name: "Net Wallets" },

      53: { code: "VND_NetBanking", name: "Net Banking" },
      54: { code: "VND_BankTransfer", name: "Bank Transfer" },
      55: { code: "VND_Momo", name: "Momo" },
      56: { code: "VND_QR", name: "QR Pay" },

      57: { code: "THB_QR", name: "QR Pay" },
      58: { code: "THB_BankTransfer", name: "Bank Transfer" },
      61: { code: "USDT", name: "USDT支付" },
      62: { code: "CNY_OKPay", name: "OKPay钱包" },
      63: { code: "CNY_WBOTC", name: "万币 OTC" },
      64: { code: "CNY_GOPay", name: "GOPay钱包" },
      65: { code: "CNY_ERMB", name: "数字人民币" },
      66: { code: "CNY_BOBPay", name: "波币钱包" },
      67: { code: "CNY_ToPay", name: "ToPay钱包" },
      68: { code: "THB_Truemoney", name: "Truemoney" },
      87: { code: "PKR_Easypaisa", name: "Easypaisa" },
      88: { code: "PKR_JazzCash", name: "JazzCash" },
      89: { code: "PKR_SadaPay", name: "SadaPay" },
    };
    return payType;
  },
  publicFile: {
    sportsType: {
      S: "足球",
      "34D": "3D/4D游戏",
      "12D": "1D/2D游戏",
      BB: "篮球",
      FS: "室内足球",
      BC: "海滩足球",
      UF: "美式足球",
      BE: "棒球",
      IH: "冰球",
      TN: "网球",
      FB: "金融投注",
      BA: "羽毛球",
      GF: "高尔夫球",
      CK: "板球",
      VB: "排球",
      HB: "手球",
      PL: "撞球",
      BL: "台球",
      NS: "桌球",
      RB: "橄榄球",
      GP: "汽车运动",
      DT: "飞镖",
      BX: "拳击",
      AT: "田径",
      AR: "射箭",
      CH: "棋",
      DV: "跳水",
      EQ: "马术",
      ET: "综艺",
      CN: "皮划艇",
      CS: "格斗体育",
      CY: "骑自行车",
      HK: "曲棍球",
      GM: "体操",
      FL: "地板球",
      NT: "Novelties",
      OL: "奥林匹克",
      OT: "其他",
      PO: "政治",
      QQ: "壁球",
      MN: "游泳",
      RU: "橄榄球联盟",
      TT: "乒乓球",
      WG: "举重",
      WI: "冬季运动",
      WP: "水球",
      WS: "赛道",
      ES: "电子竞技",
      MT: "泰拳",
    },
    sportsStatus: {
      R: "拒绝",
      C: "取消",
      P: "进行中",
      S: "出售",
      WH: "赢半",
      WA: "赢",
      W: "赢",
      LH: "输半",
      LA: "输",
      L: "输",
      D: "和",
    },
    betTypes: {
      HDP: "HDP",
      OU: "OU",
      OE: "OE",
      1: "1X2",
      X: "1X2",
      2: "1X2",
      "1X": "DC",
      X2: "DC",
      12: "DC",
      CS: "CS",
      TG: "TG",
      OUT: "OUT",
      "1D": "1D",
      "2D": "2D",
      HFT: "HFT",
      FLG: "FLG",
      PAR: "PAR",
      "1X2": "1X2",
      "3D": "HDP",
      "4D": "OU",
    },
    betTypes_CN: {
      HDP: "亚洲盘",
      OU: "大小",
      OE: "单双",
      "1X2": "1X2",
      DC: "双重机会",
      CS: "波胆",
      TG: "总入球",
      OUT: "优胜冠军",
      "1D": "1D",
      "2D": "2D",
      HFT: "半场 / 全场",
      FLG: "最先进球 / 最后进球",
    },
    choiceLanguage: {
      OU: {
        1: "大",
        2: "小",
      },
      OE: {
        1: "单",
        2: "双",
      },
      "1X2": "1X2",
      DC: {
        1: "主或和局",
        2: "主或客",
        3: "客或和局",
      },
      CS: {
        1: "1-0",
        2: "2-0",
        3: "2-1",
        4: "3-0",
        5: "3-1",
        6: "3-2",
        7: "4-0",
        8: "4-1",
        9: "4-2",
        10: "4-3",
        13: "0-0",
        14: "1-1",
        15: "2-2",
        16: "3-3",
        17: "4-4",
        18: "0-1",
        19: "0-2",
        20: "1-2",
        21: "0-3",
        22: "1-3",
        23: "2-3",
        24: "0-4",
        25: "1-4",
        26: "2-4",
        27: "3-4",
        "-99": "AOS",
      },
      TG: {
        1: "0-1",
        2: "2-3",
        3: "4-6",
        4: "7+",
      },
      HFT: {
        1: "主主",
        2: "主和",
        3: "主客",
        4: "和主",
        5: "和和",
        6: "和客",
        7: "客主",
        8: "客和",
        9: "客客",
      },
    },
  },
  getBetSource: (oddType, choice, isFH, betType, hdp, isHomeGive, isBetHome, home, away, isRun, betScore, parOdds, isPar, transType, percent, leagueName, matchDate, HTScore, FTScore, parDangerStatus, extend) => {
    var isMmr = oddType.trim() == "MR" ? "MMR" : "";
    var betClass = betType == "OU" ? (choice == 1 ? "r" : "b") : betType == "HDP" ? (hdp > 0 && ((isHomeGive && isBetHome) || (!isHomeGive && !isBetHome)) ? "r" : "b") : "d";
    var betPosition = betType == "HDP" ? (isFH ? "上半场-" : "") : "";
    var choiceLanguage = common.getChoiceLanguage(choice, home, away, betType, isFH, isBetHome);
    var betChoice = betType == "OU" ? (choice == 1 ? "-" : "+") : betType == "HDP" && hdp > 0 ? ((isHomeGive && isBetHome) || (!isHomeGive && !isBetHome) ? "-" : "+") : "";
    var betBall = betType == "HDP" || betType == "OU" ? (hdp == 0 ? parseInt(hdp) : hdp.toFixed(2)) : "";
    var betScore = isRun ? "[" + betScore + "]" : "";
    var betOdds = isPar ? "@ <span" + (betType == "HDP" && parOdds > 0 ? ' class="d"' : "") + ">" + parOdds.toFixed(2) + "</span>" : "";
    var betInfo = oddType.trim() == "MR" ? common.PaintHDPMMR(FormatBetType(transType), isBetHome, 0, hdp, isHomeGive, percent, oddType) : "";

    var sb = '<span class="' + betClass + '">' + betPosition + choiceLanguage + " " + betChoice + betBall + betInfo + "</span> " + betScore + betOdds + "<br>" + isMmr + " " + (common.publicFile.betTypes_CN[betType] ? common.publicFile.betTypes_CN[betType] : "") + "<br>";
    //third row
    var third = {
      one: betType == "HDP" || betType == "OU" ? (isHomeGive && hdp > 0 ? "r" : "b") : "b",
      two: isFH ? "上半场-" + home : home,
      three: betType == "HDP" || betType == "OU" ? (!isHomeGive && hdp > 0 ? "r" : "b") : "b",
      four: isFH ? "上半场-" + away : away,
    };
    if (betType != "OUT" && betType != "1D" && betType != "2D") {
      sb += '<span class="' + third.one + '">' + third.two + '</span> -vs- <span class="' + third.three + '">' + third.four + "</span><br>";
    }

    //forth row
    var forth = {
      one: betType == "1D" || betType == "2D" ? matchDate : "",
    };
    sb += forth.one + leagueName + "<br>";

    //fith row
    if (betType != "OUT" && betType != "1D" && betType != "2D")
      if (isPar) {
        sb += "" + "HT" + ":" + HTScore + " / " + "FT" + ":" + FTScore + "";
      }

    if (isPar && parDangerStatus == "R") {
      sb += '<br><span class="r">(拒绝)</span>';
    } else if (isPar && parDangerStatus == "C") {
      sb += '<br><span class="r">(已取消)</span>';
    }
    if (extend != "") {
      sb += '<br><span class="d">Excluding:' + extend + "</span>";
    }

    // sb+="<hr>";
    return sb;
  },
  PaintHDPMMR: (BetType, IsBetHome, Choice, Hdp, IsHomeGive, mmr, OddsType) => {
    var type = MapDBBetType(BetType, IsBetHome, Choice);
    var hdp = GetReleaseBallColor(Hdp, IsBetHome, IsHomeGive, type);
    var mr = GetReleaseBallColorMMR(OddsType, mmr, BetType, IsBetHome, IsHomeGive);
    return string.Format('<span class="mmr" style=color:{0}>({1})</span>', mr.color, mr.result);
  },
  getChoiceLanguage: (choice, home, away, type, isFH, isBetHome) => {
    choice = parseInt(choice);
    if (type == "FLG") {
      switch (choice) {
        case 1:
          return home + " (最先进球)";
        case 2:
          return away + " (最先进球)";
        case 3:
          return home + " (最后进球)";
        case 4:
          return away + " (最后进球)";
        case 5:
          return " (没进球)";
        default:
          return "";
      }
    }
    if (type == "1X2") {
      switch (choice) {
        case 1:
          return isFH ? "HT.1" : "FT.1";
        case 2:
          return isFH ? "HT.X" : "FT.X";
        case 3:
          return isFH ? "HT.2" : "FT.2";
      }
    }
    if (type == "HDP") {
      return isBetHome ? home : away;
    }
    if (type == "OUT") {
      return home.trim();
    }
    if (!(common.publicFile.choiceLanguage[type] && common.publicFile.choiceLanguage[type][choice.toString()])) {
      return choice.toString();
    }
    return common.publicFile.choiceLanguage[type][choice.toString()];
  },
  getBrowser: {
    isUC: window.navigator.userAgent.indexOf("UCBrowser") > -1,
    isOppoBrowser: window.navigator.userAgent.toLowerCase().indexOf("oppo") > -1,
    isSougou: window.navigator.userAgent.indexOf("Sogou") > -1,
    isOPR: window.navigator.userAgent.indexOf("OPR") > -1,
  },
  redirectURL(url) {
    if (!url) return;
    setTimeout(() => {
      window.open(url);
    });
  },
};

export default common;
