import Vue from "vue";
import moment from "moment";
import i18n from "@/locales/index";

Vue.filter("time", (data, formatString) => {
  formatString = formatString || "YYYY-MM-DD HH:mm:ss";
  return moment(data).format(formatString);
});
Vue.filter("time2", (data, formatString) => {
  formatString = formatString || "DD/MM/YYYY";
  return moment(data).format(formatString);
});

Vue.filter("downTime", (data, formatString) => {
  return;
});

Vue.filter("time_utc04", function (value) {
  if (value) {
    const date = new Date(value);
    return (
      date
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        })
        .replace(",", "") + " (UTC+5)"
    );
  }
});

Vue.filter("time_local", function (value) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString("en-PK") + " (UTC+5)";
  }
});
Vue.filter("time_local2", function (value) {
  if (value) {
    const date = new Date(value);
    var formatString = formatString || "DD/MM/YYYY";
    return moment(date).format(formatString) + " (UTC+5)";
  }
});
Vue.filter("time_local1", function (value) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString("en-PK");
  }
});
Vue.filter("time_release", function (value) {
  if (value) {
    const date = new Date(value);
    date.setHours(date.getHours() + 1);
    date.setMinutes("05");
    date.setSeconds("00");
    return date.toLocaleString("en-PK");
  }
});

Vue.filter("date_local", function (value) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleDateString("en-PK");
  }
});

Vue.filter("formatPhoneNumber", function (value) {
  if (!value) return "******";
  value = value.toString();
  if (value.length >= 4) {
    const lastFourChars = value.substring(value.length - 4);
    return `******${lastFourChars}`;
  } else {
    return `******${value}`;
  }
});

Vue.filter("formatCurrency", (value) => {
  if (!value) return "0.00";
  value = value.toFixed(2);
  let intPart = Math.trunc(value); // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  let floatPart = ".00"; // 预定义小数部分
  let value2Array = value.split(".");
  // =2表示数据有小数位
  if (value2Array.length === 2) {
    floatPart = value2Array[1].toString(); // 拿到小数部分
    if (floatPart.length === 1) {
      // 补0,实际上用不着
      return intPartFormat + "." + floatPart + "0";
    } else {
      return intPartFormat + "." + floatPart;
    }
  } else {
    return intPartFormat + floatPart;
  }
});

Vue.filter("formatCurrency2", (value) => {
  if (!value) return "0";
  value = Number(value);
  value = value.toFixed(2);
  let intPart = Math.trunc(value); // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  return intPartFormat;
});

Vue.filter("tolower", (value) => {
  return value ? value.toLowerCase() : "";
});

Vue.filter("fmtBankNumber", (value) => {
  if (/\S{5}/.test(value)) {
    let str = value.substring(0, 6);
    return (
      str +
      " " +
      value
        .substring(6)
        .replace(/\s/g, "")
        .replace(/(.{4})/g, "$1 ")
    );
  }
  return value;
});
Vue.filter("filterAuditType", (data) => {
  if (data.length === 0) {
    return i18n.t("v1_filter_0001");
  } else {
    let str = "";
    data.forEach((item) => {
      switch (item) {
        case "Casino":
          str += i18n.t("v1_filter_0002") + "/";
          break;
        case "SportsBook":
          str += i18n.t("v1_filter_0003") + "/";
          break;
        case "Egames":
          str += i18n.t("v1_filter_0004") + "/";
          break;
        case "Lottery":
          str += i18n.t("v1_filter_0005") + "/";
          break;
        case "Card":
          str += i18n.t("v1_filter_0006") + "/";
          break;
        case "Fish":
          str += i18n.t("v1_filter_0007") + "/";
          break;
      }
    });
    return str.substring(0, str.lastIndexOf("/"));
  }
});

Vue.filter("formatActiveDetail", (data) => {
  if (data) {
    var formatData = JSON.parse(data);
    var arr = [];
    if (formatData.BonusSettingTitle) {
      arr.push('<span class="color-blue1">' + formatData.BonusSettingTitle + "</span>");
    }
    Object.keys(formatData.Input).forEach(function (item, index) {
      arr.push(item + " : " + formatData.Input[item]);
    });
    return arr.join("<br/>");
  }
  return data;
});

Vue.filter("formatDepositType", (data, type) => {
  let offlinePayType = {
    0: i18n.t("v1_filter_0008"),
    1: i18n.t("v1_filter_0009"),
    2: i18n.t("v1_filter_0010"),
    3: i18n.t("v1_filter_0011"),
    4: i18n.t("v1_filter_0012"),
    5: i18n.t("v1_filter_0013"),
    6: i18n.t("v1_filter_0014"),
    102: i18n.t("v1_filter_0015"),
    103: i18n.t("v1_filter_0016"),
    91: i18n.t("v1_filter_0017"),
    92: i18n.t("v1_filter_0018"),
    93: i18n.t("v1_filter_0019"),
  };
  let onlinePayType = {
    1: {
      code: "Wechat",
      name: i18n.t("v1_filter_0020"),
    },
    2: {
      code: "Alipay",
      name: i18n.t("v1_filter_0021"),
    },
    3: {
      code: "QQWallet",
      name: i18n.t("v1_filter_0022"),
    },
    4: {
      code: "OnlineBank",
      name: i18n.t("v1_filter_0023"),
    },
    5: {
      code: "WechatH5",
      name: i18n.t("v1_filter_0024"),
    },
    6: {
      code: "AlipayH5",
      name: i18n.t("v1_filter_0025"),
    },
    7: {
      code: "QQWalletH5",
      name: i18n.t("v1_filter_0026"),
    },
    8: {
      code: "WechatWap",
      name: i18n.t("v1_filter_0027"),
    },
    9: {
      code: "AlipayWap",
      name: i18n.t("v1_filter_0028"),
    },
    10: {
      code: "QQWalletWap",
      name: i18n.t("v1_filter_0029"),
    },
    11: {
      code: "UnionWap",
      name: i18n.t("v1_filter_0030"),
    },
    12: {
      code: "JdWap",
      name: i18n.t("v1_filter_0031"),
    },
    13: {
      code: "QuickPay",
      name: i18n.t("v1_filter_0032"),
    },
    14: {
      code: "UnionPay",
      name: i18n.t("v1_filter_0033"),
    },
    15: {
      code: "Cashier",
      name: i18n.t("v1_filter_0034"),
    },
    16: {
      code: "UnionPc",
      name: i18n.t("v1_filter_0035"),
    },
    17: {
      code: "Jd",
      name: i18n.t("v1_filter_0036"),
    },
    18: {
      code: "SuNing",
      name: i18n.t("v1_filter_0037"),
    },
    19: {
      code: "BaiDu",
      name: i18n.t("v1_filter_0038"),
    },
    20: {
      code: "TenPay",
      name: i18n.t("v1_filter_0039"),
    },
    21: {
      code: "TenPayWap",
      name: i18n.t("v1_filter_0040"),
    },
    22: {
      code: "UnionH5",
      name: i18n.t("v1_filter_0041"),
    },
    23: {
      code: "WechatBarCode",
      name: i18n.t("v1_filter_0042"),
    },
    24: {
      code: "AlipayBarCode",
      name: i18n.t("v1_filter_0043"),
    },
    25: {
      code: "QQWalletBarCode",
      name: i18n.t("v1_filter_0044"),
    },
    26: {
      code: "JdH5",
      name: i18n.t("v1_filter_0045"),
    },
    27: {
      code: "MBANK",
      name: i18n.t("v1_filter_0046"),
    },
    28: {
      code: "MwabQuick",
      name: i18n.t("v1_filter_0047"),
    },
    29: { code: "YunShanFu", name: i18n.t("v1_filter_0048") },
    30: { code: "AliPay_QR_ToBank", name: i18n.t("v1_filter_0049") },
    31: { code: "WeChat_QR_ToBank", name: i18n.t("v1_filter_0050") },
    32: { code: "AliPay_QR_ToBankApp", name: i18n.t("v1_filter_0051") },

    38: { code: "NetBanking", name: "Net Banking" },
    39: { code: "NetWallets", name: "Net Wallets" },

    53: { code: "VND_NetBanking", name: "Net Banking" },
    54: { code: "VND_BankTransfer", name: "Bank Transfer" },
    55: { code: "VND_Momo", name: "Momo" },
    56: { code: "VND_QR", name: "QR Pay" },

    57: { code: "THB_QR", name: "QR Pay" },
    58: { code: "THB_BankTransfer", name: "Bank Transfer" },
    68: { code: "THB_Truemoney", name: "Truemoney" },
    
    87: { code: "PKR_Easypaisa", name: "Easypaisa" },
    88: { code: "PKR_JazzCash", name: "JazzCash" },
    89: { code: "PKR_SadaPay", name: "SadaPay" }
  };
  if (data === 1) {
    return onlinePayType[type]["name"];
  } else {
    return offlinePayType[type];
  }
});

Vue.filter("T", (data) => {
  if (data) {
    data = data.split("^");
    data = '<span style="color:blue;word-wrap:break-word;" title="' + data[data.length - 1] + '">' + data[data.length - 1] + '</span> @  <span style="color: red;">' + data[4] + "</span>";
    return data;
  }
  return data;
});

Vue.filter("OG", (data) => {
  var beting = {
    //百家乐
    101: "闲",
    102: "庄",
    103: "和",
    104: "闲对",
    105: "庄对",
    126: "Super6",
    //龙虎
    201: "龙",
    202: "虎",
    203: "和",

    //番摊
    742: "正 1",
    743: "正 2",
    744: "正 3",
    745: "正 4",
    746: "角1_2",
    747: "角2_3",
    748: "角3_4",
    749: "角4_1",
    750: "1 番",
    751: "2 番",
    752: "3 番",
    753: "4 番",
    754: "1 念2",
    755: "1 念3",
    756: "1 念4",
    757: "2 念1",
    758: "2 念3",
    759: "2 念4",
    760: "3 念1",
    761: "3 念2",
    762: "3 念4",
    763: "4 念1",
    764: "4 念2",
    765: "4 念3",
    766: "单",
    767: "双",
    768: "1,2 四 通",
    769: "1,2 三 通",
    770: "1,3 四 通",
    771: "1,3 二 通",
    772: "1,4 三 通",
    773: "1,4 二 通",
    774: "2,3 四 通",
    775: "2,3 一 通",
    776: "2,4 三 通",
    777: "2,4 一 通",
    778: "3,4 二 通",
    779: "3,4 一 通",
    780: "三門(3,2,1)",
    781: "三門(2,1,4)",
    782: "三門(1,4,3)",
    783: "三門(4,3,2)",
    //骰宝
    401: "点数4",
    402: "点数5",
    403: "点数6",
    404: "点数7",
    405: "点数8",
    406: "点数9",
    407: "点数10",
    408: "点数11",
    409: "点数12",
    410: "点数13",
    411: "点数14",
    412: "点数15",
    413: "点数16",
    414: "点数17",
    415: "小",
    416: "大",
    417: "三军1",
    418: "三军2",
    419: "三军3",
    420: "三军4",
    421: "三军5",
    422: "三军6",
    423: "短牌1",
    424: "短牌2",
    425: "短牌3",
    426: "短牌4",
    427: "短牌5",
    428: "短牌6",
    429: "围骰1",
    430: "围骰2",
    431: "围骰3",
    432: "围骰4",
    433: "围骰5",
    434: "围骰6",
    435: "全骰",
    436: "长牌1~2",
    437: "长牌1~3",
    438: "长牌1~4",
    439: "长牌1~5",
    440: "长牌1~6",
    441: "长牌2~3",
    442: "长牌2~4",
    443: "长牌2~5",
    444: "长牌2~6",
    445: "长牌3~4",
    446: "长牌3~5",
    447: "长牌3~6",
    448: "长牌4~5",
    449: "长牌4~6",
    450: "长牌5~6",
    451: "单",
    452: "双",
    453: 112,
    454: 113,
    455: 114,
    456: 115,
    457: 116,
    458: 122,
    459: 133,
    460: 144,
    461: 155,
    462: 166,
    463: 223,
    464: 224,
    465: 225,
    466: 226,
    467: 233,
    468: 244,
    469: 255,
    470: 266,
    471: 334,
    472: 335,
    473: 336,
    474: 344,
    475: 355,
    476: 366,
    477: 445,
    478: 446,
    479: 455,
    480: 466,
    481: 556,
    482: 566,
    483: 123,
    484: 124,
    485: 125,
    486: 126,
    487: 134,
    488: 135,
    489: 136,
    490: 145,
    491: 146,
    492: 156,
    493: 234,
    494: 235,
    495: 236,
    496: 245,
    497: 246,
    498: 256,
    499: 345,
    4100: 346,
    4101: 356,
    4102: 456,
    //轮盘
    501: 1,
    502: 2,
    503: 3,
    504: 4,
    505: 5,
    506: 6,
    507: 7,
    508: 8,
    509: 9,
    510: 10,
    511: 11,
    512: 12,
    513: 13,
    514: 14,
    515: 15,
    516: 16,
    517: 17,
    518: 18,
    519: 19,
    520: 20,
    521: 21,
    522: 22,
    523: 23,
    524: 24,
    525: 25,
    526: 26,
    527: 27,
    528: 28,
    529: 29,
    530: 30,
    531: 31,
    532: 32,
    533: 33,
    534: 34,
    535: 35,
    536: 36,
    537: 0,
    538: "分注[3,0]",
    539: "分注[3,6]",
    540: "分注[6,9]",
    541: "分注[9,12]",
    542: "分注[12,15]",
    543: "分注[15,18]",
    544: "分注[18,21]",
    545: "分注[21,24]",
    546: "分注[24,27]",
    547: "分注[27,30]",
    548: "分注[30,33]",
    549: "分注[33,36]",
    550: "分注[2,3]",
    551: "分注[5,6]",
    552: "分注[8,9]",
    553: "分注[11,12]",
    554: "分注[14,15]",
    555: "分注[17,18]",
    556: "分注[20,21]",
    557: "分注[23,24]",
    558: "分注[26,27]",
    559: "分注[29,30]",
    560: "分注[32,33]",
    561: "分注[35,36]",
    562: "分注[2,0]  ",
    563: "分注[2,5]  ",
    564: "分注[5,8]  ",
    565: "分注[8,11] ",
    566: "分注[11,14]",
    567: "分注[14,17]",
    568: "分注[17,20]",
    569: "分注[20,23]",
    570: "分注[23,26]",
    571: "分注[26,29]",
    572: "分注[29,32]",
    573: "分注[32,35]",
    574: "分注[1,2]",
    575: "分注[4,5]",
    576: "分注[7,8]",
    577: "分注[10,11]",
    578: "分注[13,14]",
    579: "分注[16,17]",
    580: "分注[19,20]",
    581: "分注[22,23]",
    582: "分注[25,26]",
    583: "分注[28,29]",
    584: "分注[31,32]",
    585: "分注[34,35]",
    586: "分注[1,0]",
    587: "分注[1,4]",
    588: "分注[4,7]",
    589: "分注[7,10]",
    590: "分注[10,13]",
    591: "分注[13,16]",
    592: "分注[16,19]",
    593: "分注[19,22]",
    594: "分注[22,25]",
    595: "分注[25,28]",
    596: "分注[28,31]",
    597: "分注[31,34]",
    598: "三数[2,3,0]",
    599: "三数[1,2,0]",
    600: "街注[1,2,3]",
    601: "街注[4,5,6]",
    602: "街注[7,8,9]",
    603: "街注[10,11,12]",
    604: "街注[13,14,15]",
    605: "街注[16,17,18]",
    606: "街注[19,20,21]",
    607: "街注[22,23,24]",
    608: "街注[25,26,27]",
    609: "街注[28,29,30]",
    610: "街注[31,32,33]",
    611: "街注[34,35,36]",
    612: "角注[2,5,3,6]",
    613: "角注[5,8,6,9]",
    614: "角注[8,11,9,12]",
    615: "角注[11,14,12,15]",
    616: "角注[14,17,15,18]",
    617: "角注[17,20,18,21]",
    618: "角注[20,23,21,24]",
    619: "角注[23,26,24,27]",
    620: "角注[26,29,27,30]",
    621: "角注[29,32,30,33]",
    622: "角注[32,35,33,36]",
    623: "角注[1,4,2,5]",
    624: "角注[4,7,5,8]",
    625: "角注[7,10,8,11]",
    626: "角注[10,13,11,14]",
    627: "角注[13,16,14,17]",
    628: "角注[16,19,17,20]",
    629: "角注[19,22,20,23]",
    630: "角注[22,25,23,26]",
    631: "角注[25,28,26,29]",
    632: "角注[28,31,29,32]",
    633: "角注[31,34,32,35]",
    634: "四個号码[1,2,3,0]",
    635: "线注[1,2,3,4,5,6]",
    636: "线注[4,5,6,7,8,9]",
    637: "线注[7,8,9,10,11,12]",
    638: "线注[10,11,12,13,14,15]",
    639: "线注[13,14,15,16,17,18]",
    640: "线注[16,17,18,19,20,21]",
    641: "线注[19,20,21,22,23,24]",
    642: "线注[22,23,24,25,26,27]",
    643: "线注[25,26,27,28,29,30]",
    644: "线注[28,29,30,31,32,33]",
    645: "线注[31,32,33,34,35,36]",
    646: "列[3,6,9,12,15,18,21,24,27,30,33,36]",
    647: "列[2,5,8,11,14,17,20,23,26,29,32,35]",
    648: "列[1,4,7,10,13,16,19,22,25,28,31,34]",
    649: "第一打[1,2,3,4,5,6,7,8,9,10,11,12]",
    650: "第二打[13,14,15,16,17,18,19,20,21,22,23,24]",
    651: "第三打[25,26,27,28,29,30,31,32,33,34,35,36]",
    652: "小[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]",
    653: "双[2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36]",
    654: "红[1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36]=",
    655: "黑[2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35]",
    656: "单[1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35]",
    657: "大[19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]",
  };
  if (data) {
    var btdata = data.split("^");
    var result = "";
    if (btdata[1] == "V2") {
      data = JSON.parse((btdata[2] + btdata[3] + btdata[4] + btdata[5]).match(/\{(\S*)\}/)[0]);
      result = '<span style="color:blue;">桌号:' + data.gameid + "</span><br>";

      result += '<span style="color:blue;">' + beting[data.bet] + "</span>";
    } else {
      result += "";
    }
    return result;
  }
});

Vue.filter("GD", (data) => {
  var beting = {
    //百家乐
    "Tie|": "和",
    "PlayerPair|": "闲對",
    "BankerPair|": "莊對",
    "Player|": "闲",
    "Banker|": "莊",
    "Big|": "大",
    "Small|": "小",
    "Dragon|": "龍",
    "Tiger|": "虎",
    "Banker 0|": "庄家点数0",
    "Banker 1|": "庄家点数1",
    "Banker 2|": "庄家点数2",
    "Banker 3|": "庄家点数3",
    "Banker 4|": "庄家点数4",
    "Banker 5|": "庄家点数5",
    "Banker 6|": "庄家点数6",
    "Banker 7|": "庄家点数7",
    "Banker 8|": "庄家点数8",
    "Banker 9|": "庄家点数9",
    "Player 0|": "闲家点数0",
    "Player 1|": "闲家点数1",
    "Player 2|": "闲家点数2",
    "Player 3|": "闲家点数3",
    "Player 4|": "闲家点数4",
    "Player 5|": "闲家点数5",
    "Player 6|": "闲家点数6",
    "Player 7|": "闲家点数7",
    "Player 8|": "闲家点数8",
    "Player 9|": "闲家点数9",
    "Banker Spade|": "庄家第一牌黑桃",
    "Banker Heart|": "庄家第一牌红桃",
    "Banker Club|": "庄家第一牌梅花",
    "Banker Diamond|": "庄家第一牌方块",
    "Player Spade|": "闲家第一牌黑桃",
    "Player Heart|": "闲家第一牌红桃",
    "Player Club|": "闲家第一牌梅花",
    "Player Diamond|": "闲家第一牌方块",
    "Banker Big|": "庄总和大",
    "Banker Small|": "庄总和小",
    "Player Big|": "闲总和大",
    "Player Small|": "闲总和小",
    "Player Odd|": "單",
    "Banker Odd|": "單",
    "Player Even|": "雙",
    "Banker Even|": "雙",
    "Super 6|": "免佣百家樂",
    "PerfectPair|": "完美對子",
    "EitherPair|": "任意對子",
    "Straight|": "直注",
    "Split|": "拆注",
    "Street|": "街注",
    "Corner|": "角注",
    "Trio|": "(0, 1, 2)或(0, 2 ,3)",
    "Line|": "線注",
    "1st12|": "頭12號碼",
    "2nd12|": "中12號碼",
    "3rd12|": "尾 12 號",
    "2:1(1st)|": "第1行",
    "2:1(2nd)|": "第2行",
    "2:1(3rd)|": "第3行",
    "Odd|": "單",
    "Even|": "雙",
    "Red|": "紅",
    "Black|": "黑",
    "1-18|": "1-18",
    "19-36|": "19 -36",
    "Four|": "四個號碼(0,1,2,3)",
    "AnyTriple|": "全圍",
    "Triple(1)|": "單圍1",
    "Triple(2)|": "單圍2",
    "Triple(3)|": "單圍3",
    "Triple(4)|": "單圍4",
    "Triple(5)|": "單圍5",
    "Triple(6)|": "單圍6",
    "Double(1)|": "双骰1",
    "Double(2)|": "双骰2",
    "Double(3)|": "双骰3",
    "Double(4)|": "双骰4",
    "Double(5)|": "双骰5",
    "Double(6)|": "双骰6",
    "Number(1)|": "点数盘1",
    "Number(2)|": "点数盘2",
    "Number(3)|": "点数盘3",
    "Number(4)|": "点数盘4",
    "Number(5)|": "点数盘5",
    "Number(6)|": "点数盘6",
    "Total (4)|": "总和盘4",
    "Total (5)|": "总和盘5",
    "Total (6)|": "总和盘6",
    "Total (7)|": "总和盘7",
    "Total (8)|": "总和盘8",
    "Total (9)|": "总和盘9",
    "Total (10)|": "总和盘10",
    "Total (11)|": "总和盘11",
    "Total (12)|": "总和盘12",
    "Total (13)|": "总和盘13",
    "Total (14)|": "总和盘14",
    "Total (15)|": "总和盘15",
    "Total (16)|": "总和盘16",
    "Total (17)|": "总和盘17",
    "Pair(1,2)|": "对子盘1,2",
    "Pair(1,3)|": "对子盘1,3",
    "Pair(1,4)|": "对子盘1,4",
    "Pair(1,5)|": "对子盘1,5",
    "Pair(1,6)|": "对子盘1,6",
    "Pair(2,3)|": "对子盘2,3",
    "Pair(2,4)|": "对子盘2,4",
    "Pair(2,5)|": "对子盘2,5",
    "Pair(2,6)|": "对子盘2,6",
    "Pair(3,4)|": "对子盘3,4",
    "Pair(3,5)|": "对子盘3,5",
    "Pair(3,6)|": "对子盘3,6",
    "Pair(4,5)|": "对子盘4,5",
    "Pair(4,6)|": "对子盘4,6",
    "Pair(5,6)|": "对子盘5,6",
  };
  if (data) {
    var btdata = data.split("^");
    data = data.split("^")[2];
    if (btdata[1] == "V2" && beting[JSON.parse(data).SubBetType]) {
      data = '<span style="color:blue;">' + beting[JSON.parse(data).SubBetType] + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("AB", (data) => {
  var allbet = {
    1001: "庄",
    1002: "闲",
    1003: "和",
    1004: "大",
    1005: "小",
    1006: "庄对",
    1007: "闲对",
    // 超级 6 5001
    //欧洲厅特有 欧洲厅特有
    //龙虎 名称 值 备注
    2001: "龙",
    2002: "虎",
    2003: "和",
    //骰宝 名称 值 备注
    3001: "小",
    3002: "单",
    3003: "双",
    3004: "大",
    //- 35 -
    3005: "围一",
    3006: "围二",
    3007: "围三",
    3008: "围四",
    3009: "围五",
    3010: "围六",
    3011: "全围",
    3012: "对 1",
    3013: "对 2",
    3014: "对 3",
    3015: "对 4",
    3016: "对 5",
    3017: "对 6",
    3018: "和值 :4",
    3019: "和值 :5",
    3020: "和值 :6",
    3021: "和值 :7",
    3022: "和值 :8",
    3023: "和值 :9",
    3024: "和值 :10",
    3025: "和值 :11",
    3026: "和值 :12",
    3027: "和值 :13",
    3028: "和值 :14",
    3029: "和值 :15",
    3030: "和值 :16",
    3031: "和值 :17",
    3032: "", //(这里多出一个 3032，放空 )
    3033: "牌九式 :12",
    3034: "牌九式 :13",
    3035: "牌九式 :14",
    3036: "牌九式 :15",
    3037: "牌九式 :16",
    3038: "牌九式 :23",
    3039: "牌九式 :24",
    3040: "牌九式 :25",
    3041: "牌九式 :26",
    3042: "牌九式 :34",
    3043: "牌九式 :35",
    3044: "牌九式 :36",
    3045: "牌九式 :45",
    3046: "牌九式 :46",
    3047: "牌九式 :56",
    3048: "单骰 :1",
    3049: "单骰 :2",
    3050: "单骰 :3",
    3051: "单骰 :4",
    3052: "单骰 :5",
    3053: "单骰 :6",
    //轮盘 名称 值
    4001: "小",
    4002: "双",
    4003: "红",
    4004: "黑",
    4005: "单",
    4006: "大",
    4007: "第一打",
    4008: "第二打",
    4009: "第三打",
    4010: "第一列",
    4011: "第二列",
    4012: "第三列",
    4013: "直接注 :0",
    4014: "直接注 :1",
    4015: "直接注 :2",
    4016: "直接注 :3",
    4017: "直接注 :4",
    4018: "直接注 :5",
    4019: "直接注 :6",
    4020: "直接注 :7",
    4021: "直接注 :8",
    4022: "直接注 :9",
    4023: "直接注 :10",
    4024: "直接注 :11",
    4025: "直接注 :12",
    4026: "直接注 :13",
    4027: "直接注 :14",
    4028: "直接注 :15",
    4029: "直接注 :16",
    4030: "直接注 :17",
    4031: "直接注 :18",
    4032: "直接注 :19",
    4033: "直接注 :20",
    4034: "直接注 :21",
    4035: "直接注 :22",
    4036: "直接注 :23",
    4037: "直接注 :24",
    4038: "直接注 :25",
    4039: "直接注 :26",
    4040: "直接注 :27",
    4041: "直接注 :28",
    4042: "直接注 :29",
    4043: "直接注 :30",
    4044: "直接注 :31",
    4045: "直接注 :32",
    4046: "直接注 :33",
    4047: "直接注 :34",
    4048: "直接注 :35",
    4049: "直接注 :36",
    4050: "三数：（0/1/2）",
    4051: "三数：（0/2/3）",
    4052: "四数：（ 0/1/2/3）",
    4053: "分注: (0/1)",
    4054: "分注：(0/2)",
    4055: "分注：(0/3)",
    4056: "分注: (1/2)",
    4057: "分注: (2/3)",
    4058: "分注: (4/5)",
    4059: "分注: (5/6)",
    4060: "分注: (7/8)",
    4061: "分注: (8/9)",
    4062: "分注: (10/11)",
    4063: "分注: (11/12)",
    4064: "分注: (13/14)",
    4065: "分注: (14/15)",
    4066: "分注: (16/17)",
    4067: "分注: (17/18)",
    4068: "分注: (19/20)",
    4069: "分注: (20/21)",
    4070: "分注: (22/23)",
    4071: "分注: (23/24)",
    4072: "分注: (25/26)",
    4073: "分注: (26/27)",
    4074: "分注: (28/29)",
    4075: "分注: (29/30)",
    4076: "分注: (31/32)",
    4077: "分注: (32/33)",
    4078: "分注: (34/35)",
    4079: "分注: (35/36)",
    4080: "分注: (1/4)",
    4081: "分注: (4/7)",
    4082: "分注: (7/10)",
    4083: "分注: (10/13)",
    4084: "分注: (13/16)",
    4085: "分注: (16/19)",
    4086: "分注: (19/22)",
    4087: "分注: (22/25)",
    4088: "分注: (25/28)",
    4089: "分注: (28/31)",
    4090: "分注: (31/34)",
    4091: "分注: (2/5)",
    4092: "分注: (5/8)",
    4093: "分注: (8/11)",
    4094: "分注: (11/14)",
    4095: "分注: (14/17)",
    4096: "分注: (17/20)",
    4097: "分注: (20/23)",
    4098: "分注: (23/26)",
    4099: "分注: (26/29)",
    4100: "分注: (29/32)",
    4101: "分注: (32/35)",
    4102: "分注: (3/6)",
    4103: "分注: (6/9)",
    4104: "分注: (9/12)",
    4105: "分注: (12/15)",
    4106: "分注: (15/18)",
    4107: "分注: (18/21)",
    4108: "分注 : (21/24)",
    4109: "分注 : (24/27)",
    4110: "分注 : (27/30)",
    //- 36 -
    4111: "分注 : (30/33)",
    4112: "分注 : (33/36)",
    4113: "角注 : (1/5)",
    4114: "角注 : (2/6)",
    4115: "角注 : (4/8)",
    4116: "角注 : (5/9)",
    4117: "角注 : (7/11)",
    4118: "角注 : (8/12)",
    4119: "角注 : (10/14)",
    4120: "角注 : (11/15)",
    4121: "角注 : (13/17)",
    4122: "角注 : (14/18)",
    4123: "角注 : (16/20)",
    4124: "角注 : (17/21)",
    4125: "角注 : (19/23)",
    4126: "角注 : (20/24)",
    4127: "角注 : (22/26)",
    4128: "角注 : (23/27)",
    4129: "角注 : (25/29)",
    4130: "角注 : (26/30)",
    4131: "角注 : (28/32)",
    4132: "角注 : (29/33)",
    4133: "角注 : (31/35)",
    4134: "角注 : (32/36)",
    4135: "街注 : (1~3)",
    4136: "街注 : (4~6)",
    4137: "街注 : (7~9)",
    4138: "街注 : (10~12)",
    4139: "街注 : (13~15)",
    4140: "街注 : (16~18)",
    4141: "街注 : (19~21)",
    4142: "街注 : (22~24)",
    4143: "街注 : (25~27)",
    4144: "街注 : (28~30)",
    4145: "街注 : (31~33)",
    4146: "街注 : (34~36)",
    4147: "线注 : (1~6)",
    4148: "线注 : (4~9)",
    4149: "线注 : (7~12)",
    4150: "线注 : (10~15)",
    4151: "线注 : (13~18)",
    4152: "线注 : (16~21)",
    4153: "线注 : (19~24)",
    4154: "线注 : (22~27)",
    4155: "线注 : (28~33)",
    4156: "线注 : (31~36)",
    4157: "线注 :（25~30）",
    6001: "好运注 (1) 80x",
    6002: "好运注 (2) 18x",
    6003: "好运注 (3) 3x",
    6004: "好运注 (4) 1x",
    6005: "好运注 (5) 3x",
    6006: "好运注 (6) 18x",
    6007: "好运注 (7) 80x",
    //21点 名称 值
    7000: "座位 1 -普通",
    7001: "座位 1 -保险",
    7002: "座位 1 -双倍",
    7003: "座位 1 -分牌",
    7004: "座位 1 - 21+3",
    7005: "座位 1 - 完美对子",
    7010: "座位 2 -普通",
    7011: "座位 2 -保险",
    7012: "座位 2 -双倍",
    7013: "座位 2 -分牌",
    7014: "座位 2 - 21+3",
    7015: "座位 2 - 完美对子",
    7020: "座位 3 -普通",
    7021: "座位 3 -保险",
    7022: "座位 3 -双倍",
    7023: "座位 3 -分牌",
    7024: "座位 3 - 21+3",
    7025: "座位 3 - 完美对子",
    7030: "座位 4 -普通",
    7031: "座位 4 -保险",
    7032: "座位 4 -双倍",
    //- 37 -
    7033: "座位 4 -分牌",
    7034: "座位 4 - 21+3",
    7035: "座位 4 - 完美对子",
    7040: "座位 5 -普通",
    7041: "座位 5 -保险",
    7042: "座位 5 -双倍",
    7043: "座位 5 -分牌",
    7044: "座位 5 - 21+3",
    7045: "座位 5 - 完美对子",
    7050: "座位 6 -普通",
    7051: "座位 6 -保险",
    7052: "座位 6 -双倍",
    7053: "座位 6 -分牌",
    7054: "座位 6 - 21+3",
    7055: "座位 6 - 完美对子",
    7060: "座位 7 -普通",
    7061: "座位 7 -保险",
    7062: "座位 7 -双倍",
    7063: "座位 7 -分牌",
    7064: "座位 7 - 21+3",
    7065: "座位 7 - 完美对子",
    7100: "搭注 -座位 1 -普通",
    7101: "搭注 -座位 1 -保险",
    7102: "搭注 -座位 1 -双倍",
    7103: "搭注 -座位 1 -分牌",
    7110: "搭注 -座位 2 -普通",
    7111: "搭注 -座位 2 -保险",
    7112: "搭注 -座位 2 -双倍",
    7113: "搭注 -座位 2 -分牌",
    7120: "搭注 -座位 3 -普通",
    7121: "搭注 -座位 3 -保险",
    7122: "搭注 -座位 3 -双倍",
    7123: "搭注 -座位 3 -分牌",
    7130: "搭注 -座位 4 -普通",
    7131: "搭注 -座位 4 -保险",
    7132: "搭注 -座位 4 -双倍",
    7133: "搭注 -座位 4 -分牌",
    7140: "搭注 -座位 5 -普通",
    7141: "搭注 -座位 5 -保险",
    7142: "搭注 -座位 5 -双倍",
    7143: "搭注 -座位 5 -分牌",
    7150: "搭注 -座位 6 -普通",
    //- 38 -
    7151: "搭注 -座位 6 -保险",
    7152: "搭注 -座位 6 -双倍",
    7153: "搭注 -座位 6 -分牌",
    7160: "搭注 -座位 7 -普通",
    7161: "搭注 -座位 7 -保险",
    7162: "搭注 -座位 7 -双倍",
    7163: "搭注 -座位 7 -分牌",

    8001: "庄1平倍",
    8011: "庄1翻倍",
    8101: "闲1平倍",
    8111: "闲1翻倍",
    8002: "庄2平倍",
    8012: "庄2翻倍",
    8102: "闲2平倍",
    8112: "闲2翻倍",
    8003: "庄3平倍",
    8013: "庄3翻倍",
    8103: "闲3平倍",
    8113: "闲3翻倍",

    //炸金花
    9001: "龙",
    9002: "凤",
    9003: "对8以上",
    9004: "顺子",
    9005: "同花",
    9006: "同花顺",
    9007: "豹子",
  };
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    if (btdata[1] == "V2" && btdata[2] != "AllBetEgame" && "betType" in data && allbet[data.betType]) {
      data = '<span style="color:blue;">' + allbet[data.betType] + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("MG", (data) => {
  var mgbet = {
    //Baccarat
    "": "",
    Tie: "和",
    "Player Pair": "闲对",
    "Banker Pair": "庄对",
    Player: "闲",
    Banker: "庄",
    "Dragon Bonus Player": "闲家龙宝",
    "Dragon Bonus Banker": "庄家龙宝",
    "Dragon Tiger - Dragon": "龙",
    "Dragon Tiger - Tiger": "虎",
    "Dragon Tiger - Tie": "龙虎和",
    Big: "大",
    Small: "小",
    "Phoenix Pair": "朱雀对子",
    "Turtle Pair": "玄武对子",

    //Roulette
    "Straight 0": "0",
    "Straight 1": "1",
    "Straight 2": "2",
    "Straight 3": "3",
    "Straight 4": "4",
    "Straight 5": "5",
    "Straight 6": "6",
    "Straight 7": "7",
    "Straight 8": "8",
    "Straight 9": "9",
    "Straight 10": "10",
    "Straight 11": "11",
    "Straight 12": "12",
    "Straight 13": "13",
    "Straight 14": "14",
    "Straight 15": "15",
    "Straight 16": "16",
    "Straight 17": "17",
    "Straight 18": "18",
    "Straight 19": "19",
    "Straight 20": "20",
    "Straight 21": "21",
    "Straight 22": "22",
    "Straight 23": "23",
    "Straight 24": "24",
    "Straight 25": "25",
    "Straight 26": "26",
    "Straight 27": "27",
    "Straight 28": "28",
    "Straight 29": "29",
    "Straight 30": "30",
    "Straight 31": "31",
    "Straight 32": "32",
    "Straight 33": "33",
    "Straight 34": "34",
    "Straight 35": "35",
    "Straight 36": "36",
    "Split 1, 2": "1, 2",
    "Split 4, 5": "4, 5",
    "Split 7, 8": "7, 8",
    "Split 10, 11": "10, 11",
    "Split 13, 14": "13, 14",
    "Split 16, 17": "16, 17",
    "Split 19, 20": "19, 20",
    "Split 22, 23": "22, 23",
    "Split 25, 26": "25, 26",
    "Split 28, 29": "28, 29",
    "Split 31, 32": "31, 32",
    "Split 34, 35": "34, 35",
    "Split 2, 3": "2, 3",
    "Split 5, 6": "5, 6",
    "Split 8, 9": "8, 9",
    "Split 11, 12": "11, 12",
    "Split 14, 15": "14, 15",
    "Split 17, 18": "17, 18",
    "Split 20, 21": "20, 21",
    "Split 23, 24": "23, 24",
    "Split 26, 27": "26, 27",
    "Split 29, 30": "29, 30",
    "Split 32, 33": "32, 33",
    "Split 35, 36": "35, 36",
    "Split 1, 4": "1, 4",
    "Split 2, 5": "2, 5",
    "Split 3, 6": "3, 6",
    "Split 4, 7": "4, 7",
    "Split 5, 8": "5, 8",
    "Split 6, 9": "6, 9",
    "Split 7, 10": "7, 10",
    "Split 8, 11": "8, 11",
    "Split 9, 12": "9, 12",
    "Split 10, 13": "10, 13",
    "Split 11, 14": "11, 14",
    "Split 12, 15": "12, 15",
    "Split 13, 16": "13, 16",
    "Split 14, 17": "14, 17",
    "Split 15, 18": "15, 18",
    "Split 16, 19": "16, 19",
    "Split 17, 20": "17, 20",
    "Split 18, 21": "18, 21",
    "Split 19, 22": "19, 22",
    "Split 20, 23": "20, 23",
    "Split 21, 24": "21, 24",
    "Split 22, 25": "22, 25",
    "Split 23, 26": "23, 26",
    "Split 24, 27": "24, 27",
    "Split 25, 28": "25, 28",
    "Split 26, 29": "26, 29",
    "Split 27, 30": "27, 30",
    "Split 28, 31": "28, 31",
    "Split 29, 32": "29, 32",
    "Split 30, 33": "30, 33",
    "Split 31, 34": "31, 34",
    "Split 32, 35": "32, 35",
    "Split 33, 36": "33, 36",
    "Street 1, 2, 3": "1, 2, 3",
    "Street 4, 5, 6": "4, 5, 6",
    "Street 7, 8, 9": "7, 8, 9",
    "Street 10, 11, 12": "10, 11, 12",
    "Street 13, 14, 15": "13, 14, 15",
    "Street 16, 17, 18": "16, 17, 18",
    "Street 19, 20, 21": "19, 20, 21",
    "Street 22, 23, 24": "22, 23, 24",
    "Street 25, 26, 27": "25, 26, 27",
    "Street 28, 29, 30": "28, 29, 30",
    "Street 31, 32, 33": "31, 32, 33",
    "Street 34, 35, 36": "34, 35, 36",
    "Corner 1, 2, 4, 5": "1, 2, 4, 5",
    "Corner 4, 5, 7, 8": "4, 5, 7, 8",
    "Corner 7, 8, 10, 11": "7, 8, 10, 11",
    "Corner 10, 11, 13, 14": "10, 11, 13, 14",
    "Corner 13, 14, 16, 17": "13, 14, 16, 17",
    "Corner 16, 17, 19, 20": "16, 17, 19, 20",
    "Corner 19, 20, 22, 23": "19, 20, 22, 23",
    "Corner 22, 23, 25, 26": "22, 23, 25, 26",
    "Corner 25, 26, 28, 29": "25, 26, 28, 29",
    "Corner 28, 29, 31, 32": "28, 29, 31, 32",
    "Corner 31, 32, 34, 35": "31, 32, 34, 35",
    "Corner 2, 3, 5, 6": "2, 3, 5, 6",
    "Corner 5, 6, 8, 9": "5, 6, 8, 9",
    "Corner 8, 9, 11, 12": "8, 9, 11, 12",
    "Corner 11, 12, 14, 15": "11, 12, 14, 15",
    "Corner 14, 15, 17, 18": "14, 15, 17, 18",
    "Corner 17, 18, 20, 21": "17, 18, 20, 21",
    "Corner 20, 21, 23, 24": "20, 21, 23, 24",
    "Corner 23, 24, 26, 27": "23, 24, 26, 27",
    "Corner 26, 27, 29, 30": "26, 27, 29, 30",
    "Corner 29, 30, 32, 33": "29, 30, 32, 33",
    "Corner 32, 33, 35, 36": "32, 33, 35, 36",
    "Line 1, 2, 3, 4, 5, 6": "1, 2, 3, 4, 5, 6",
    "Line 4, 5, 6, 7, 8, 9": "4, 5, 6, 7, 8, 9",
    "Line 7, 8, 9, 10, 11, 12": "7, 8, 9, 10, 11, 12",
    "Line 10, 11, 12, 13, 14, 15": "10, 11, 12, 13, 14, 15",
    "Line 13, 14, 15, 16, 17, 18": "13, 14, 15, 16, 17, 18",
    "Line 16, 17, 18, 19, 20, 21": "16, 17, 18, 19, 20, 21",
    "Line 19, 20, 21, 22, 23, 24": "19, 20, 21, 22, 23, 24",
    "Line 22, 23, 24, 25, 26, 27": "22, 23, 24, 25, 26, 27",
    "Line 25, 26, 27, 28, 29, 30": "25, 26, 27, 28, 29, 30",
    "Line 28, 29, 30, 31, 32, 33": "28, 29, 30, 31, 32, 33",
    "Line 31, 32, 33, 34, 35, 36": "31, 32, 33, 34, 35, 36",
    "Dozen 1": "第一组",
    "Dozen 2": "第二组",
    "Dozen 3": "第三组",
    "Column 1": "第一列",
    "Column 2": "第二列",
    "Column 3": "第三列",
    Odd: "单",
    Even: "双",
    Red: "红色",
    Black: "黑色",
    Low: "1-18",
    High: "19-36",
    "0, 1": "0, 1",
    "0, 2": "0, 2",
    "0, 3": "0, 3",
    "0, 1, 2": "0, 1, 2",
    "0, 2, 3": "0, 2, 3",
    "0, 1, 2, 3": "0, 1, 2, 3",

    //BlackJack
    "0, 1, 2, 3": "",
    Seated: "",
    Insurance: "",
    Split: "",
    Double: "",
    "Bet behind left": "",
    "Bet behind right": "",

    //Casino Hold'em
    Ante: "",
    Call: "",
    Bonus: "",
    Fold: "",
    "Timeout Fold": "",
    "Technical Error Fold": "",
    "Bonus Fold": "",
    "Bonus Timeout Fold": "",
    "Bonus Technical Error Fold": "",

    //Side
    "Total 4": "点数4",
    "Total 5": "点数5",
    "Total 6": "点数6",
    "Total 7": "点数7",
    "Total 8": "点数8",
    "Total 9": "点数9",
    "Total 10": "点数10",
    "Total 11": "点数11",
    "Total 12": "点数12",
    "Total 13": "点数13",
    "Total 14": "点数14",
    "Total 15": "点数15",
    "Total 16": "点数16",
    "Total 17": "点数17",
    "TwoDice 1, 2": "骨牌 1,2",
    "TwoDice 1, 3": "骨牌 1,3",
    "TwoDice 1, 4": "骨牌 1,4",
    "TwoDice 1, 5": "骨牌 1,5",
    "TwoDice 1, 6": "骨牌 1,6",
    "TwoDice 2, 3": "骨牌 2,3",
    "TwoDice 2, 4": "骨牌 2,4",
    "TwoDice 2, 5": "骨牌 2,5",
    "TwoDice 2, 6": "骨牌 2,6",
    "TwoDice 3, 4": "骨牌 3,4",
    "TwoDice 3, 5": "骨牌 3,5",
    "TwoDice 3, 6": "骨牌 3,6",
    "TwoDice 4, 5": "骨牌 4,5",
    "TwoDice 4, 6": "骨牌 4,6",
    "TwoDice 5, 6": "骨牌 5,6",
    Small: "小",
    Big: "大",
    Odd: "单",
    Even: "双",
    "Double 1": "对子1",
    "Double 2": "对子2",
    "Double 3": "对子3",
    "Double 4": "对子4",
    "Double 5": "对子5",
    "Double 6": "对子6",
    "Triple 1": "围骰1",
    "Triple 2": "围骰2",
    "Triple 3": "围骰3",
    "Triple 4": "围骰4",
    "Triple 5": "围骰5",
    "Triple 6": "围骰6",
    "Any Triple": "全围",
    "Single 1": "单骰1",
    "Single 2": "单骰2",
    "Single 3": "单骰3",
    "Single 4": "单骰4",
    "Single 5": "单骰5",
    "Single 6": "单骰6",
  };
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    if (btdata[1] == "V2" && "BetType" in data) {
      data = '<span style="color:blue;">' + mgbet[data.BetType.trim()] + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("BBIN", (data) => {
  var allbet = {
    30011: "庄",
    30012: "闲",
    30013: "和",
    30014: "庄对",
    30015: "闲对",
    30016: "大",
    30017: "小",
    30018: "庄单",
    30019: "庄双",
    300110: "闲单",
    300111: "闲双",
    300112: "任意对子",
    300113: "完美对子",
    300114: "庄(免佣)",
    300115: "超级六(免佣)",

    30031: "虎",
    30032: "龙",
    30033: "和",
    30034: "虎单",
    30035: "虎双",
    30036: "龙单",
    30037: "龙双",
    30038: "虎黑",
    30039: "虎红",
    300310: "龙黑",
    300311: "龙红",

    30051: "闲 1 赢",
    30052: "闲 1 输",
    30053: "闲 1 和",
    30054: "闲 1 三公",
    30055: "闲 1 对牌以上",
    30056: "闲 2 赢",
    30057: "闲 2 输",
    30058: "闲 2 和",
    30059: "闲 2 三公",
    300510: "闲 2 对牌以上",
    300511: "闲 3 赢",
    300512: "闲 3 输",
    300513: "闲 3 和",
    300514: "闲 3 三公",
    300515: "闲 3 对牌以上",
    300516: "庄 对牌以上",

    30061: "顺门赢",
    30062: "闲家一输",
    30063: "出门赢",
    30064: "闲家二输",
    30065: "到门赢",
    30066: "闲家三输",

    30070: "直注(0)",
    30071: "直注(1)",
    30072: "直注(2)",
    30073: "直注(3)",
    30074: "直注(4)",
    30075: "直注(5)",
    30076: "直注(6)",
    30077: "直注(7)",
    30078: "直注(8)",
    30079: "直注(9)",
    300710: "直注(10)",
    300711: "直注(11)",
    300712: "直注(12)",
    300713: "直注(13)",
    300714: "直注(14)",
    300715: "直注(15)",
    300716: "直注(16)",
    300717: "直注(17)",
    300718: "直注(18)",
    300719: "直注(19)",
    300720: "直注(20)",
    300721: "直注(21)",
    300722: "直注(22)",
    300723: "直注(23)",
    300724: "直注(24)",
    300725: "直注(25)",
    300726: "直注(26)",
    300727: "直注(27)",
    300728: "直注(28)",
    300729: "直注(29)",
    300730: "直注(30)",
    300731: "直注(31)",
    300732: "直注(32)",
    300733: "直注(33)",
    300734: "直注(34)",
    300735: "直注(35)",
    300736: "直注(36)",
    300737: "分注( 0,1 )",
    300738: "分注( 0,2 )",
    300739: "分注( 0,3 )",
    300740: "分注( 1,2 )",
    300741: "分注( 1,4 )",
    300742: "分注( 2,3 )",
    300743: "分注( 2,5 )",
    300744: "分注( 3,6 )",
    300745: "分注( 4,5 )",
    300746: "分注( 4,7 )",
    300747: "分注( 5,6 )",
    300748: "分注( 5,8 )",
    300749: "分注( 6,9 )",
    300750: "分注( 7,8 )",
    300751: "分注( 7,10 )",
    300752: "分注( 8,9 )",
    300753: "分注( 8,11 )",
    300754: "分注( 9,12 )",
    300755: "分注( 10,11 )",
    300756: "分注( 10,13 )",
    300757: "分注( 11,12 )",
    300758: "分注( 11,14 )",
    300759: "分注( 12,15 )",
    300760: "分注( 13,14 )",
    300761: "分注( 13,16 )",
    300762: "分注( 14,15 )",
    300763: "分注( 14,17 )",
    300764: "分注( 15,18 )",
    300765: "分注( 16,17 )",
    300766: "分注( 16,19 )",
    300767: "分注( 17,18 )",
    300768: "分注( 17,20 )",
    300769: "分注( 18,21 )",
    300770: "分注( 19,20 )",
    300771: "分注( 19,22 )",
    300772: "分注( 20,21 )",
    300773: "分注( 20,23 )",
    300774: "分注( 21,24 )",
    300775: "分注( 22,23 )",
    300776: "分注( 22,25 )",
    300777: "分注( 23,24 )",
    300778: "分注( 23,26 )",
    300779: "分注( 24,27 )",
    300780: "分注( 25,26 )",
    300781: "分注( 25,28 )",
    300782: "分注( 26,27 )",
    300783: "分注( 26,29 )",
    300784: "分注( 27,30 )",
    300785: "分注( 28,29 )",
    300786: "分注( 28,31 )",
    300787: "分注( 29,30 )",
    300788: "分注( 29,32 )",
    300789: "分注( 30,33 )",
    300790: "分注( 31,32 )",
    300791: "分注( 31,34 )",
    300792: "分注( 32,33 )",
    300793: "分注( 32,35 )",
    300794: "分注( 33,36 )",
    300795: "分注( 34,35 )",
    300796: "分注( 35,36 )",
    300797: "街注(1,2,3)",
    300798: "街注(4,5,6)",
    300799: "街注(7,8,9)",
    3007100: "街注(10,11,12)",
    3007101: "街注(13,14,15)",
    3007102: "街注(16,17,18)",
    3007103: "街注(19,20,21)",
    3007104: "街注(22,23,24)",
    3007105: "街注(25,26,27)",
    3007106: "街注(28,29,30)",
    3007107: "街注(31,32,33)",
    3007108: "街注(34,35,36)",
    3007109: "三数(0,1,2)",
    3007110: "三数(0,2,3 )",
    3007111: "角注(1,2,4,5)",
    3007112: "角注(2,3,5,6)",
    3007113: "角注(4,5,7,8)",
    3007114: "角注(5,6,8,9)",
    3007115: "角注(7,8,10,11)",
    3007116: "角注(8,9,11,12)",
    3007117: "角注(10,11,13,14)",
    3007118: "角注(11,12,14,15)",
    3007119: "角注(13,14,16,17)",
    3007120: "角注(14,15,17,18)",
    3007121: "角注(16,17,19,20)",
    3007122: "角注(17,18,20,21)",
    3007123: "角注(19,20,22,23)",
    3007124: "角注(20,21,23,24)",
    3007125: "角注(22,23,25,26)",
    3007126: "角注(23,24,26,27)",
    3007127: "角注(25,26,28,29)",
    3007128: "角注(26,27,29,30)",
    3007129: "角注(28,29,31,32)",
    3007130: "角注(29,30,32,33)",
    3007131: "角注(31,32,34,35)",
    3007132: "角注(32,33,35,36)",
    3007133: "四个号码(0,1,2,3)",
    3007134: "线注(1,2,3,4,5,6)",
    3007135: "线注(4,5,6,7,8,9)",
    3007136: "线注(7,8,9,10,11,12)",
    3007137: "线注(10,11,12,13,14,15)",
    3007138: "线注(13,14,15,16,17,18)",
    3007139: "线注(16,17,18,19,20,21)",
    3007140: "线注(19,20,21,22,23,24)",
    3007141: "线注(22,23,24,25,26,27)",
    3007142: "线注(25,26,27,28,29,30)",
    3007143: "线注(28,29,30,31,32,33)",
    3007144: "线注(31,32,33,34,35,36)",
    3007145: "列注(1st)",
    3007146: "列注(2nd)",
    3007147: "列注(3th)",
    3007148: "打(1st)",
    3007149: "打(2nd)",
    3007150: "打(3th)",
    3007151: "红/黑(红)",
    3007152: "红/黑(黑)",
    3007153: "单/双(单)",
    3007154: "单/双(双)",
    3007155: "大/小(1-18)",
    3007156: "大/小(19-36)",

    30081: "大/小(小)",
    30082: "大/小(大)",
    30084: "点数/4 点",
    30085: "点数/5 点",
    30086: "点数/6 点",
    30087: "点数/7 点",
    30088: "点数/8 点",
    30089: "点数/9 点",
    300810: "点数/10 点",
    300811: "点数/11 点",
    300812: "点数/12 点",
    300813: "点数/13 点",
    300814: "点数/14 点",
    300815: "点数/15 点",
    300816: "点数/16 点",
    300817: "点数/17 点",
    300818: "短牌(1,2)",
    300819: "短牌(1,3)",
    300820: "短牌(1,4)",
    300821: "短牌(1,5)",
    300822: "短牌(1,6)",
    300823: "短牌(2,3)",
    300824: "短牌(2,4)",
    300825: "短牌(2,5)",
    300826: "短牌(2,6)",
    300827: "短牌(3,4)",
    300828: "短牌(3,5)",
    300829: "短牌(3,6)",
    300830: "短牌(4,5)",
    300831: "短牌(4,6)",
    300832: "短牌(5,6)",
    300833: "长牌(1,1)",
    300834: "长牌(2,2)",
    300835: "长牌(3,3)",
    300836: "长牌(4,4)",
    300837: "长牌(5,5)",
    300838: "长牌(6,6)",
    300839: "围骰(1,1,1)",
    300840: "围骰(2,2,2)",
    300841: "围骰(3,3,3)",
    300842: "围骰(4,4,4)",
    300843: "围骰(5,5,5)",
    300844: "围骰(6,6,6)",
    300845: "全围",
    300846: "三军(1)",
    300847: "三军(2)",
    300848: "三军(3)",
    300849: "三军(4)",
    300850: "三军(5)",
    300851: "三军(6)",
    300852: "单/双(单)",
    300853: "单/双(双)",

    30101: "底注",
    30102: "翻牌",
    30103: "转牌",
    30104: "河牌",
    30105: "Bonus",

    30111: "4 白",
    30112: "4 红",
    30113: "3 白 1红",
    30114: "3 红 1白",
    30115: "单",
    30116: "双",

    30121: "闲 1 平倍",
    30122: "闲 1 翻倍",
    30123: "闲 1 预扣额度",
    30124: "闲 2 平倍",
    30125: "闲 2 翻倍",
    30126: "闲 2 预扣额度",
    30127: "闲 3 平倍",
    30128: "闲 3 翻倍",
    30129: "闲 3 预扣额度",

    30141: "闲",
    30142: "分牌",
    30143: "保险",
    30144: "加倍",

    30151: "1 番",
    30152: "2 番",
    30153: "3 番",
    30154: "4 番",
    30155: "1 念 2",
    30156: "1 念 3",
    30157: "1 念 4",
    30158: "2 念 1",
    30159: "2 念 3",
    301510: "2 念 4",
    301511: "3 念 1",
    301512: "3 念 2",
    301513: "3 念 4",
    301514: "4 念 1",
    301515: "4 念 2",
    301516: "4 念 3",
    301517: "1,2 角",
    301518: "2,3 角",
    301519: "3,4 角",
    301520: "4,1 角",
    301521: "2,3 一通",
    301522: "2,4 一通",
    301523: "3,4 一通",
    301524: "1,3 二通",
    301525: "1,4 二通",
    301526: "3,4 二通",
    301527: "1,2 三通",
    301528: "1,4 三通",
    301529: "2,4 三通",
    301530: "1,2 四通",
    301531: "1,3 四通",
    301532: "2,3 四通",
    301533: "三门（4,3,2）",
    301534: "三门（1,4,3）",
    301535: "三门（2,1,4）",
    301536: "三门（3,2,1）",
    301537: "单",
    301538: "双",

    30161: "大/小(小)",
    30162: "大/小(大)",
    30164: "点数/4 点",
    30165: "点数/5 点",
    30166: "点数/6 点",
    30167: "点数/7 点",
    30168: "点数/8 点",
    30169: "点数/9 点",
    301610: "点数/10 点",
    301611: "点数/11 点",
    301612: "点数/12 点",
    301613: "点数/13 点",
    301614: "点数/14 点",
    301615: "点数/15 点",
    301616: "点数/16 点",
    301617: "点数/17 点",
    301618: "指定单色(绿)",
    301619: "指定单色(蓝)",
    301620: "指定单色(红)",
    301621: "指定双色(绿)",
    301622: "指定双色(蓝)",
    301623: "指定双色(红)",
    301624: "指定三色(绿)",
    301625: "指定三色(蓝)",
    301626: "指定三色(红)",
    301627: "任意三色",
    301628: "围骰(1,1,1)",
    301629: "围骰(2,2,2)",
    301630: "围骰(3,3,3)",
    301631: "围骰(4,4,4)",
    301632: "围骰(5,5,5)",
    301633: "围骰(6,6,6)",
    301634: "全围",
    301635: "三军(1)",
    301636: "三军(2)",
    301637: "三军(3)",
    301638: "三军(4)",
    301639: "三军(5)",
    301640: "三军(6)",
    301641: "单/双(单)",
    301642: "单/双(双)",

    30171: "庄",
    30172: "闲",
    30173: "和",
    30174: "庄对",
    30175: "闲对",
    30176: "大",
    30177: "小",
    301716: "庄保险",
    301717: "闲保险",

    30181: "龙",
    30182: "凤",
    30184: "对9 以上",
    30188: "顺子",
    301816: "同花",
    301832: "同花顺",
    301864: "豹子",
    30191: "1",
    30192: "3",
    30193: "5",
    30194: "16",
    30195: "24",
    30196: "50(银)",
    30197: "50(金)",
    30211: "高",
    30212: "相同",
    30213: "低",
    30214: "2/3/4/5",
    30215: "6/7/8/9",
    30216: "J/Q/K/A",
    30217: "红",
    30218: "黑",
    30219: "单",
    302110: "双",
    30201: "庄",
    30202: "闲",
    30203: "和",
    30204: "庄对",
    30205: "闲对",
    30206: "大",
    30207: "小",
    30208: "庄单",
    30209: "庄双",
    302010: "闲单",
    302011: "闲双",
    302012: "任意对子",
    302013: "完美对子",
    302014: "庄同花",
    302015: "闲同花",
  };

  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);

    var datastr = "";
    var betlist = data.WagerDetail;
    if (betlist == null || betlist.length == 0) {
      return "";
    }
    if (btdata[1] == "V2" && btdata[2] != "AllBetEgame" && "WagerDetail" in data) {
      if (betlist.indexOf("*") > -1) {
        var betl = betlist.split("*");
        // var bet =betlist.split('*');

        betl.forEach(function (e) {
          var result = "";
          if (e.split(",")[3] > 0) {
            result = " 赢" + Math.abs(e.split(",")[3]);
          } else {
            result = " 输" + Math.abs(e.split(",")[3]);
          }
          datastr += '<span style="color:blue;">[' + allbet[parseInt(data.GameType + e.split(",")[0])] + "] 投注" + e.split(",")[2] + result + "</span><br>";
        });
      } else {
        var result = "";
        if (betlist.split(",")[3] > 0) {
          result = " 赢" + Math.abs(betlist.split(",")[3]);
        } else {
          result = " 输" + Math.abs(betlist.split(",")[3]);
        }
        datastr = '<span style="color:blue;">[' + allbet[parseInt(data.GameType + betlist.split(",")[0])] + "] 投注" + betlist.split(",")[2] + result + "</span>";
      }
    } else {
      datastr = "";
    }
    return datastr;
  }
});

Vue.filter("KK", (data) => {
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    var betconten = "";
    if (btdata[1] == "V2" && "betcontent" in data) {
      data = '<span style="color:blue;" title="' + data.betcontent + '">' + data.betcontent.substr(0, 20) + " @" + data.betodds + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("EB", (data) => {
  var eblbet = {
    60: "閒",
    66: "閒對",
    68: "和",
    80: "莊",
    88: "莊對",
    //龍虎：
    10: "龍",
    11: "虎",
    68: "和",
    //骰寶:
    100: "單",
    101: "雙",
    102: "大",
    103: "小",
    104: "對子 1",
    105: "對子 2",
    106: "對子 3",
    107: "對子 4",
    108: "對子 5",
    109: "對子 6",
    110: "圍骰 1",
    111: "圍骰 2",
    112: "圍骰 3",
    113: "圍骰 4",
    114: "圍骰 5",
    115: "圍骰 6",
    116: "全圍",
    117: "4 點",
    118: "5 點",
    119: "6 點",
    120: "7 點",
    121: "8 點",
    125: "9 點",
    126: "10 點",
    127: "11 點",
    128: "12 點",
    129: "13 點",
    130: "14 點",
    131: "15 點",
    132: "16 點",
    133: "17 點",
    134: "單點數",
    135: "單點數 2",
    136: "單點數 3",
    137: "單點數 4",
    138: "單點數 5",
    139: "單點數 6",
    140: "組合 1-2",
    141: "組合 1-3",
    142: "組合 1-4",
    143: "組合 1-5",
    144: "組合 1-6",
    145: "組合 2-3",
    146: "組合 2-4",
    147: "組合 2-5",
    148: "組合 2-6",
    149: "組合 3-4",
    150: "組合 3-5",
    151: "組合 3-6",
    152: "組合 4-5",
    153: "組合 4-6",
    154: "組合 5-6",
    155: "二同號",
    156: "三不同",
    //輪盤:
    200: "直接注",
    201: "分注",
    202: "街注",
    203: "角注",
    204: "三數",
    205: "四個號碼",
    206: "線注",
    207: "列注",
    208: "打注",
    209: "紅",
    210: "黑",
    211: "單",
    212: "雙",
    213: "大",
    214: "小",
    301: "闲家 1 号 平倍",
    302: "闲家 1 号 翻倍",
    303: "闲家 2 号 平倍",
    304: "闲家 2 号 翻倍",
    305: "闲家 3 号 平倍",
    306: "闲家 3 号 翻倍",
  };
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    var betconten = "";
    if (btdata[1] == "V2" && "judgeResult" in data) {
      if (data.gameType == 8) {
        for (var aa in data.betMap) {
          if (eblbet[data.betMap[aa].betType]) {
            betconten += eblbet[data.betMap[aa].betType] + " </br>";
          }
        }
      } else {
        for (var aa in data.judgeResult) {
          if (eblbet[data.judgeResult[aa]]) {
            betconten += eblbet[data.judgeResult[aa]] + "  ";
          }
        }
      }

      data = '<span style="color:blue;">' + betconten + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("SB", (data) => {
  var sblbet = {
    babank: "庄",
    badeal: "闲",
    batie: "和",
    bappa: "闲对",
    babpa: "庄对",
    babig: "大",
    basm: "小",
    bapodd: "闲单",
    bapevn: "闲双",
    babodd: "庄单",
    babevn: "庄双",
    babsix: "超級六庄",
    basix: "超級六",
    bajp: "jackpot",
    bsbank: "庄",
    bsbevn: "庄双",
    bsbig: "大",
    bsbodd: "庄单",
    bsbpa: "庄对",
    bsdeal: "闲",
    bsjp: "jackpot",
    bspevn: "闲双",
    bspodd: "闲单",
    bsppa: "闲对",
    bssm: "小",
    bstie: "和",
    //咪牌百家乐
    bpbank: "庄",
    bpdeal: "闲",
    bptie: "和",
    bpbpa: "庄对",
    bpppa: "闲对",
    //轮盘
    rosi88: "单号 0",
    rosi01: "单号 1",
    rosi02: "单号 2",
    rosi03: "单号 3",
    rosi04: "单号 4",
    rosi05: "单号 5",
    rosi06: "单号 6",
    rosi07: "单号 7",
    rosi08: "单号 8",
    rosi09: "单号 9",
    rosi10: "单号 10",
    rosi11: "单号 11",
    rosi12: "单号 12",
    rosi13: "单号 13",
    rosi14: "单号 14",
    rosi15: "单号 15",
    rosi16: "单号 16",
    rosi17: "单号 17",
    rosi18: "单号 18",
    rosi19: "单号 19",
    rosi20: "单号 20",
    rosi21: "单号 21",
    rosi22: "单号 22",
    rosi23: "单号 23",
    rosi24: "单号 24",
    rosi25: "单号 25",
    rosi26: "单号 26",
    rosi27: "单号 27",
    rosi28: "单号 28",
    rosi29: "单号 29",
    rosi30: "单号 30",
    rosi31: "单号 31",
    rosi32: "单号 32",
    rosi33: "单号 33",
    rosi34: "单号 34",
    rosi35: "单号 35",
    rosi36: "单号 36",
    rodu_1: "双号 0,1",
    rodu_2: "双号 0,2",
    rodu_3: "双号 0,3",
    rodu12: "双号 1,2",
    rodu45: "双号 4,5",
    rodu78: "双号 7,8",
    rodu0a: "双号 10,11",
    roducd: "双号 13,14",
    rodufg: "双号 16,17",
    roduij: "双号 19,20",
    rodulm: "双号 22,23",
    roduop: "双号 25,26",
    rodurs: "双号 28,29",
    roduuv: "双号 31,32",
    roduxy: "双号 34,35",
    rodu23: "双号 2,3",
    rodu56: "双号 5,6",
    rodu89: "双号 8,9",
    roduab: "双号 11,12",
    rodude: "双号 14,15",
    rodugh: "双号 17,18",
    rodujk: "双号 20,21",
    rodumn: "双号 23,24",
    rodupq: "双号 26,27",
    rodust: "双号 29,30",
    roduvw: "双号 32,33",
    roduyz: "双号 35,36",
    rodu14: "双号 1,4",
    rodu25: "双号 2,5",
    rodu36: "双号 3,6",
    rodu47: "双号 4,7",
    rodu58: "双号 5,8",
    rodu69: "双号 6,9",
    rodu70: "双号 7,10",
    rodu8a: "双号 8,11",
    rodu9b: "双号 9,12",
    rodu0c: "双号 10,13",
    roduad: "双号 11,14",
    rodube: "双号 12,15",
    roducf: "双号 13,16",
    rodudg: "双号 14,17",
    rodueh: "双号 15,18",
    rodufi: "双号 16,19",
    rodugj: "双号 17,20",
    roduhk: "双号 18,21",
    roduil: "双号 19,22",
    rodujm: "双号 20,23",
    rodukn: "双号 21,24",
    rodulo: "双号 22,25",
    rodump: "双号 23,26",
    rodunq: "双号 24,27",
    roduor: "双号 25,28",
    rodups: "双号 26,29",
    roduqt: "双号 27,30",
    roduru: "双号 28,31",
    rodusv: "双号 29,32",
    rodutw: "双号 30,33",
    roduux: "双号 31,34",
    roduvy: "双号 32,35",
    roduwz: "双号 33,36",
    rot123: "三号 1,2,3",
    rot456: "三号 4,5,6",
    rot789: "三号 7,8,9",
    rot0ab: "三号 10,11,12",
    rotcde: "三号 13,14,15",
    rotfgh: "三号 16,17,18",
    rotijk: "三号 19,20,21",
    rotlmn: "三号 22,34,24",
    rotopq: "三号 25,26,27",
    rotrst: "三号 28,29,30",
    rotuvw: "三号 31,32,33",
    rotxyz: "三号 34,35,36",
    ro1245: "四号 1,2,4,5",
    ro4578: "四号 4,5,7,8",
    ro780a: "四号 7,8,10,11",
    ro0acd: "四号 10,11,13,14",
    rocdfg: "四号 13,14,16,17",
    rofgij: "四号 16,17,19,20",
    roijlm: "四号 19,20,22,23",
    rolmop: "四号 22,23,25,26",
    rooprs: "四号 25,26,28,29",
    rorsuv: "四号 28,29,31,32",
    rouvxy: "四号 31,32,34,35",
    ro2356: "四号 2,3,5,6",
    ro5689: "四号 5,6,8,9",
    ro89ab: "四号 8,9,11,12",
    roabde: "四号 11,12,14,15",
    rodegh: "四号 14,15,17,18",
    roghjk: "四号 17,18,20,21",
    rojkmn: "四号 20,21,23,24",
    romnpq: "四号 23,24,26,27",
    ropqst: "四号 26,27,29,30",
    rostvw: "四号 29,30,32,33",
    rovwyz: "四号 32,33,35,36",
    rocol0: "六号 1,2,3,4,5,6",
    rocol1: "六号 4,5,6,7,8,9",
    rocol2: "六号 7,8,9,10,11,12",
    rocol3: "六号 10,11,12,13,14,15",
    rocol4: "六号 13,14,15,16,17,18",
    rocol5: "六号 16,17,18,19,20,21",
    rocol6: "六号 19,20,21,22,23,24",
    rocol7: "六号 22,23,24,25,26,27",
    rocol8: "六号25,26,27,28,29,30",
    rocol9: "六号28,29,30,31,32,33",
    rocola: "六号31,32,33,34,35,36",
    ro12s1: "组1",
    ro12s2: "组2",
    ro12s3: "组3",
    rorow1: "列1",
    rorow2: "列2",
    rorow3: "列3",
    roodd: "单",
    roeven: "双",
    rored: "红色",
    robak: "黑色",
    rosm: "小",
    robig: "大",
    rot_12: "三号 0,1,2",
    rot_23: "三号 0,2,3",
    ro_123: "四号 0,1,2,3",
    ro890a: "四号 8,9,10,11",
    //骰宝
    dibig: "大",
    dism: "小",
    dieven: "单",
    diodd: "双",
    ditri0: "围骰",
    ditri1: "围骰 1",
    ditri2: "围骰 2",
    ditri3: "围骰 3",
    ditri4: "围骰 4",
    ditri5: "围骰 5",
    ditri6: "围骰 6",
    didou1: "逢双 1",
    didou2: "逢双 2",
    didou3: "逢双 3",
    didou4: "逢双 4",
    didou5: "逢双 5",
    didou6: "逢双 6",
    dione1: "骰点 1",
    dione2: "骰点 2",
    dione3: "骰点 3",
    dione4: "骰点 4",
    dione5: "骰点 5",
    dione6: "骰点 6",
    dito04: "点数 4",
    dito05: "点数 5",
    dito06: "点数 6",
    dito07: "点数 7",
    dito08: "点数 8",
    dito09: "点数 9",
    dito10: "点数 10",
    dito11: "点数 11",
    dito12: "点数 12",
    dito13: "点数 13",
    dito14: "点数 14",
    dito15: "点数 15",
    dito16: "点数 16",
    dito17: "点数 17",
    ditw12: "骰点 1, 2",
    ditw13: "骰点 1, 3",
    ditw14: "骰点 1, 4",
    ditw15: "骰点 1, 5",
    ditw16: "骰点 1, 6",
    ditw23: "骰点 2, 3",
    ditw24: "骰点 2, 4",
    ditw25: "骰点 2, 5",
    ditw26: "骰点 2, 6",
    ditw34: "骰点 3, 4",
    ditw35: "骰点 3, 5",
    ditw36: "骰点 3, 6",
    ditw45: "骰点 4, 5",
    ditw46: "骰点 4, 6",
    ditw56: "骰点 5, 6",
    //龙虎
    dtdbak: "龙黑",
    dtdevn: "龙双",
    dtdodd: "龙单",
    dtdrag: "龙",
    dtdred: "龙紅",
    dttbak: "虎黑",
    dttevn: "虎双",
    dttie: "和",
    dttigr: "虎",
    dttodd: "虎单",
    dttred: "虎紅",
    //斗牛
    cob2pa: "庄两对",
    cob3ki: "庄三条",
    cobank: "庄",
    cobas2: "庄押金 2",
    cobas3: "庄押金 3",
    codeal: "闲",
    codes2: "闲押金 2",
    codes3: "闲押金 3",
    cop2pa: "闲两对",
    cop3ki: "闲三条",
    //雀九
    mjc1: "闲门号 1",
    mjc2: "闲门号 2",
    mjc3: "闲门号 3",
    mjc4: "闲门号 4",
    mjc5: "闲门号 5",
    mjc6: "闲门号 6",
    //三公
    "3kp12": "庄闲門12",
    "3kp13": "庄闲門13",
    "3kp14": "庄闲門14",
    "3kp15": "庄闲門15",
    "3kp16": "庄闲門16",
    "3kp21": "庄闲門21",
    "3kp23": "庄闲門23",
    "3kp24": "庄闲門24",
    "3kp25": "庄闲門25",
    "3kp26": "庄闲門26",
    "3kp31": "庄闲門31",
    "3kp32": "庄闲門32",
    "3kp34": "庄闲門34",
    "3kp35": "庄闲門35",
    "3kp36": "庄闲門36",
    "3kp41": "庄闲門41",
    "3kp42": "庄闲門42",
    "3kp43": "庄闲門43",
    "3kp45": "庄闲門45",
    "3kp46": "庄闲門46",
    "3kp51": "庄闲門51",
    "3kp52": "庄闲門52",
    "3kp53": "庄闲門53",
    "3kp54": "庄闲門54",
    "3kp56": "庄闲門56",
    "3kp61": "庄闲門61",
    "3kp62": "庄闲門62",
    "3kp63": "庄闲門63",
    "3kp64": "庄闲門64",
    "3kp65": "庄闲門65",
  };
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    if (btdata[1] == "V2" && "playtype" in data && sblbet[data.playtype]) {
      data = '<span style="color:blue;">' + sblbet[data.playtype] + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("AG", (data) => {
  var agBet = {
    //百家乐玩法
    1: "庄",
    2: "闲",
    3: "和",
    4: "庄对",
    5: "闲对",
    6: "大",
    7: "小",
    8: "庄保险",
    9: "闲保险",
    11: "庄免佣",
    12: "庄龙宝",
    13: "闲龙宝",
    14: "超級六",
    15: "任意对子",
    16: "完美对子",
    //龙虎玩法
    21: "龙",
    22: "虎",
    23: "和（龙虎）",
    //骰宝玩法
    41: "大",
    42: "小",
    43: "单",
    44: "双",
    45: "全围",
    46: "围1",
    47: "围2",
    48: "围3",
    49: "围4",
    50: "围5",
    51: "围6",
    52: "单点1",
    53: "单点2",
    54: "单点3",
    55: "单点4",
    56: "单点5",
    57: "单点6",
    58: "对子1",
    59: "对子2",
    60: "对子3",
    61: "对子4",
    62: "对子5",
    63: "对子6",
    64: "组合12",
    65: "组合13",
    66: "组合14",
    67: "组合15",
    68: "组合16",
    69: "组合23",
    70: "组合24",
    71: "组合25",
    72: "组合26",
    73: "组合34",
    74: "组合35",
    75: "组合36",
    76: "组合45",
    77: "组合46",
    78: "组合56",
    79: "和值4",
    80: "和值5",
    81: "和值6",
    82: "和值7",
    83: "和值8",
    84: "和值9",
    85: "和值10",
    86: "和值11",
    87: "和值12",
    88: "和值13",
    89: "和值14",
    90: "和值15",
    91: "和值16",
    92: "和值17",
    //轮盘玩法
    101: "直接注",
    102: "分注",
    103: "街注",
    104: "三数",
    105: "4 个号码",
    106: "角注",
    107: "列注(列 1)",
    108: "列注(列 2)",
    109: "列注(列 3)",
    110: "线注",
    111: "打一 ",
    112: "打二",
    113: "打三",
    114: "红",
    115: "黑",
    116: "大",
    117: "小",
    118: "单",
    119: "双",
    //番摊玩法
    130: "1 番",
    131: "2 番",
    132: "3 番",
    133: "4 番",
    134: "1 念 2",
    135: "1 念 3",
    136: "1 念 4",
    137: "2 念 1",
    138: "2 念 3",
    139: "2 念 4",
    140: "3 念 1",
    141: "3 念 2",
    142: "3 念 4",
    143: "4 念 1",
    144: "4 念 2",
    145: "4 念 3",
    146: "角(1,2)",
    147: "单",
    148: "角(1,4)",
    149: "角(2,3)",
    150: "双",
    151: "角(3,4)",
    152: "1,2 四 通",
    153: "1,2 三 通",
    154: "1,3 四 通",
    155: "1,3 二 通 ",
    156: "1,4 三 通",
    157: "1,4 二 通 ",
    158: "2,3 四 通",
    159: "2,3 一 通",
    160: "2,4 三 通",
    161: "2,4 一 通 ",
    162: "3,4 二 通",
    163: "3,4 一 通",
    164: "三门(3,2,1)",
    165: "三门(2,1,4)",
    166: "三门(1,4,3)",
    167: "三门(4,3,2)",
    //终极德州扑克玩法
    180: "底注+盲注",
    181: "一倍加注",
    182: "二倍加注",
    183: "三倍加注",
    184: "四倍加注",
    //7 牛牛玩法
    211: "闲 1 平倍",
    212: "闲 1 翻倍",
    213: "闲 2 平倍",
    214: "闲 2 翻倍",
    215: "闲 3 平倍",
    216: "闲 3 翻倍",
    207: "庄 1 平倍",
    208: "庄 1 翻倍",
    209: "庄 2 平倍",
    210: "庄 2 翻倍",
    217: "庄 3 平倍",
    218: "庄 3 翻倍",
    //21 點(Blackjack)玩法
    220: "底注",
    221: "分牌",
    222: "保险",
    223: "分牌保险",
    224: "加注",
    225: "分牌加注",
    226: "完美对子",
    227: "21+3",
    228: "旁注",
    229: "旁注分牌",
    230: "旁注保险",
    231: "旁注分牌保险",
    232: "旁注加注",
    233: "旁注分牌加注",
    //炸金花玩法
    260: "龙",
    261: "凤",
    262: "对 8 以上",
    263: "同花",
    264: "顺子",
    265: "豹子",
    266: "同花顺",
    //斗牛玩法
    270: "黑牛",
    271: "红牛",
    272: "和",
    273: "牛一",
    274: "牛二",
    275: "牛三",
    276: "牛四",
    277: "牛五",
    278: "牛六",
    279: "牛七",
    280: "牛八",
    281: "牛九",
    282: "牛牛",
    283: "双牛牛",
    284: "银牛/金牛/炸弹/五小牛",
  };
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    var agData = JSON.parse(btdata[2]);
    if (agData.dataType == "BR" && "playType" in data && agBet[data.playType]) {
      data = '<span style="color:blue;">' + agBet[data.playType] + "</span>";
    } else {
      data = "";
    }
    return data;
  }
});

Vue.filter("IM", (datastr) => {
  if (datastr) {
    datastr = JSON.parse(datastr.match(/\{([\s\S]*)\}/)[0]);
    var data = datastr.DetailItems;
    var str = "";
    if (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].Selection == "A") {
          data[i].Selection = "客队";
        } else if (data[i].Selection == "H") {
          data[i].Selection = "主队";
        }
        var str1 = "";
        if (i + 1 == data.length && data.length > 1) {
          str1 = "<span>" + data[i].CompetitionName + "</span><br>";
        } else {
          if (data.length <= 1) {
            str1 = "<span>" + data[i].CompetitionName + "</span><br>";
          } else {
            str1 = "<span>" + data[i].CompetitionName + "</span><br><br>";
          }
        }
        str += '<span style="color:#0275d8">' + data[i].Selection + "-<span >" + data[i].Odds + "</span></span><br>" + "<span>" + data[i].BetType + "</span><br>" + "<span>" + data[i].EventName + "</span><br>" + str1;
        // if(JSON.parse(datastr).WagerType=="Single"){
        //
        // }
      }
    } else {
      str = "";
    }
    return str;
  }
});

Vue.filter("VIR", (datastr) => {
  if (datastr) {
    var btdata = datastr.split("^");
    var str1 = JSON.parse(btdata[2]);
    var awayArray = [];
    var hostArray = [];
    if (btdata[3].length > 0 && btdata[3] != null && btdata[3] != undefined) {
      var str2 = JSON.parse(btdata[3]);
      hostArray = str2.Name_Multi.split("|")[8].slice(3);
    }
    if (btdata[4].length > 0 && btdata[4] != null && btdata[4] != undefined) {
      var str3 = JSON.parse(btdata[4]);
      if (str3.Name_Multi) {
        awayArray = str3.Name_Multi.split("|")[8].slice(3);
      } else if (str3.DistanceNameMulti) {
        awayArray = str3.DistanceNameMulti.split("|")[8].slice(3);
      }
    }
    var str = "";
    if (btdata) {
      if (str1.SportCategory == 2 || str1.SportCategory == 3) {
        str += '<span style="color:#0275d8">' + str1.OddsValue + "<span ><br>" + '<span style="color: black">' + str1.OddsType + "</span><br>" + "<span>" + awayArray + "</span><br>";
        // +'<span style="color: black">'+str1.SportCategory+'</span>';
      } else {
        str += '<span style="color:#0275d8">' + str1.OddsValue + "<span ><br>" + '<span style="color: black">' + str1.OddsType + "</span><br>" + "<span>" + hostArray + '</span><span style="color: #000;">vs</span><span>' + awayArray + "</span><br>";
        // +'<span style="color: black">'+str1.SportCategory+'</span>';
      }
    } else {
      str = "";
    }
    return str;
  }
});
Vue.filter("VR", (data) => {
  if (data) {
    var btdata = data.split("^");
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    var agData = JSON.parse(btdata[2]);
    data = '<div style="color:blue;">' + '<span style="word-wrap:break-word;">' + agData.betTypeName + "</span>" + '<span style="margin-left: 10px;word-wrap:break-word;">' + agData.position + "</span>" + "</div>" + '<div style="color:blue;">' + '<span style="word-wrap:break-word;">' + agData.number + "</span>" + '<span style="margin-left: 10px;word-wrap:break-word;">@' + agData.odds + "</span>" + "</div>";
    return data;
  }
});

Vue.filter("BG", (data) => {
  var d = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
  return d ? d.playName : "";
});

Vue.filter("FY", (data) => {
  if (data) {
    var btdata = data.split("^");
    var result = "";

    if (btdata[1] == "V2") {
      data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);

      result = '<span> <span style="font-weight: bold;">(' + data.Category + ")</span> " + data.League + "</span><br>";
      result += "<span>" + data.Bet + " " + data.Content + ' @ <span style="color:#5283e2;">' + data.Odds + "</span></span><br>";
      result += "<span>" + data.Match + "</span><br>";
      result += '<span style="color:#5283e2;">赛果:</span> ' + data.Result + "";
    } else {
      result += "";
    }
  } else {
    result = "";
  }
  return result;
});

Vue.filter("SEXY", (data) => {
  var beting = {
    banker: "庄",
    banker: "庄",
    banker: "庄",
    bankerbonus: "庄龙宝",
    bankerbonus: "庄龙宝",
    bankerins: "庄保险",
    bankerpair: "庄对",
    bankerpair: "庄对",
    bankerpair: "庄对",
    big: "大",
    big: "大",
    dragon: "龙",
    dragon: "龙",
    dragon: "龙",
    phoenix: "朱雀对子",
    phoenix: "朱雀对子",
    player: "闲",
    player: "闲",
    player: "闲",
    playerbonus: "闲龙宝",
    playerbonus: "闲龙宝",
    playerins: "闲保险",
    playerpair: "闲对",
    playerpair: "闲对",
    playerpair: "闲对",
    small: "小",
    small: "小",
    tie: "和",
    tie: "和",
    tie: "和",
    tiger: "虎",
    tiger: "虎",
    tiger: "虎",
    turtle: "玄武对子",
    turtle: "玄武对子",
    banker: "庄",
    bankerbonus: "庄龙宝",
    bankerins1: "庄保险 (闲补牌前)",
    bankerins2: "庄保险 (闲补牌后)",
    bankerpair: "庄对",
    big: "大",
    dragon: "龙",
    sicanytriple: "全围",
    sicbig: "大",
    sicd11: "对子 1",
    sicd22: "对子 2",
    sicd33: "对子 3",
    sicd44: "对子 4",
    sicd55: "对子 5",
    sicd66: "对子 6",
    siceven: "双",
    sicodd: "单",
    sicpt10: "和值 10",
    sicpt11: "和值 11",
    sicpt12: "和值 12",
    sicpt13: "和值 13",
    sicpt14: "和值 14",
    sicpt15: "和值 15",
    sicpt16: "和值 16",
    sicpt17: "和值 17",
    sicpt4: "和值 4",
    sicpt5: "和值 5",
    sicpt6: "和值 6",
    sicpt7: "和值 7",
    sicpt8: "和值 8",
    sicpt9: "和值 9",
    sics1: "单奌 1",
    sics2: "单奌 2",
    sics3: "单奌 3",
    sics4: "单奌 4",
    sics5: "单奌 5",
    sics6: "单奌 6",
    sicsmall: "小",
    sict111: "围 1",
    sict222: "围 2",
    sict333: "围 3",
    sict444: "围 4",
    sict555: "围 5",
    sict666: "围 6",
    sictd12: "组合 12",
    sictd13: "组合 13",
    sictd14: "组合 14",
    sictd15: "组合 15",
    sictd16: "组合 16",
    sictd23: "组合 23",
    sictd24: "组合 24",
    sictd25: "组合 25",
    sictd26: "组合 26",
    sictd34: "组合 34",
    sictd35: "组合 35",
    sictd36: "组合 36",
    sictd45: "组合 45",
    sictd46: "组合 46",
    sictd56: "组合 56",
    small: "小",
    tie: "和",
    tiger: "虎",
    big: "大",
    even: "双",
    five: "五",
    four: "四",
    odd: "单",
    one: "一",
    six: "六",
    small: "小",
    three: "三",
    triple: "围",
    two: "二",
    anytriple: "全围",
    odd: "单",
    even: "双",
    small: "小",
    big: "大",
    "sum 4": "和值4",
    "sum 5": "和值5",
    "sum 6": "和值6",
    "sum 7": "和值7",
    "sum 8": "和值8",
    "sum 9": "和值9",
    "sum 10": "和值10",
    "sum 11": "和值11",
    "sum 12": "和值12",
    "sum 13": "和值13",
    "sum 14": "和值14",
    "sum 15": "和值15",
    "sum 16": "和值16",
    "sum 17": "和值17",
    "triple 1": "围 1",
    "triple 2": "围 2",
    "triple 3": "围 3",
    "triple 4": "围 4",
    "triple 5": "围 5",
    "triple 6": "围 6",
    "double 1": "对子 1",
    "double 2": "对子 2",
    "double 3": "对子 3",
    "double 4": "对子 4",
    "double 5": "对子 5",
    "double 6": "对子 6",
    "combine 12": "组合12",
    "combine 13": "组合13",
    "combine 14": "组合14",
    "combine 15": "组合15",
    "combine 16": "组合16",
    "combine 23": "组合23",
    "combine 24": "组合24",
    "combine 25": "组合25",
    "combine 26": "组合26",
    "combine 34": "组合34",
    "combine 35": "组合35",
    "combine 36": "组合36",
    "combine 45": "组合45",
    "combine 46": "组合46",
    "combine 56": "组合56",
    "single 1": "单奌 1",
    "single 2": "单奌 2",
    "single 3": "单奌 3",
    "single 4": "单奌 4",
    "single 5": "单奌 5",
    "single 6": "单奌 6",
  };
  if (data) {
    var btdata = data.split("^");
    var result = "";

    if (btdata[1] == "V2") {
      data = JSON.parse(btdata[2]);
      result += '<span style="color:#0f9fe6;">' + beting[data.category.toLowerCase()] + "</span>";
    } else {
      result += "";
    }
  } else {
    result = "";
  }
  return result;
});

Vue.filter("N2", (data) => {
  var mgbet = {
    //Baccarat
    "": "",
    _90091_bank: "庄",
    _90091_player: "闲",
    _90091_tie: "和",
    _90091_playerdp: "闲对",
    _90091_bankdp: "庄对",
    _90091_big: "大",
    _90091_small: "小",
    _90091_player0: "闲点0",
    _90091_player1: "闲点1",
    _90091_player2: "闲点2",
    _90091_player3: "闲点3",
    _90091_player4: "闲点4",
    _90091_player5: "闲点5",
    _90091_player6: "闲点6",
    _90091_player7: "闲点7",
    _90091_player8: "闲点8",
    _90091_player9: "闲点9",
    _90091_bank0: "庄点0",
    _90091_bank1: "庄点1",
    _90091_bank2: "庄点2",
    _90091_bank3: "庄点3",
    _90091_bank4: "庄点4",
    _90091_bank5: "庄点5",
    _90091_bank6: "庄点6",
    _90091_bank7: "庄点7",
    _90091_bank8: "庄点8",
    _90091_bank9: "庄点9",
    _90091_Super6: "超級六",
    _90091_perfectdp: "完美对子",
    _90091_eitherdp: "任一对子",
    _90091_bankdpc: "庄对子组合",
    _90091_playerdpc: "闲对子组合",
    _90091_bankd7: "龙7",
    _90091_playerp8: "熊猫8",
    _90091_bankdb: "庄龙宝",
    _90091_playerdb: "闲龙宝",
    _90091_bankcase: "庄例牌赢",
    _90091_playercase: "闲例牌赢",
    _90092_bank: "庄",
    _90092_player: "闲",
    _90092_tie: "和",
    _90092_playerdp: "闲对",
    _90092_bankdp: "庄对",
    _90092_big: "大",
    _90092_small: "小",
    _90092_player0: "闲点0",
    _90092_player1: "闲点1",
    _90092_player2: "闲点2",
    _90092_player3: "闲点3",
    _90092_player4: "闲点4",
    _90092_player5: "闲点5",
    _90092_player6: "闲点6",
    _90092_player7: "闲点7",
    _90092_player8: "闲点8",
    _90092_player9: "闲点9",
    _90092_bank0: "庄点0",
    _90092_bank1: "庄点1",
    _90092_bank2: "庄点2",
    _90092_bank3: "庄点3",
    _90092_bank4: "庄点4",
    _90092_bank5: "庄点5",
    _90092_bank6: "庄点6",
    _90092_bank7: "庄点7",
    _90092_bank8: "庄点8",
    _90092_bank9: "庄点9",
    _90092_Super6: "超級六",
    _90092_perfectdp: "完美对子",
    _90092_eitherdp: "任一对子",
    _90092_bankdpc: "庄对子组合",
    _90092_playerdpc: "闲对子组合",
    _90092_bankd7: "龙7",
    _90092_playerp8: "熊猫8",
    _90092_bankdb: "庄龙宝",
    _90092_playerdb: "闲龙宝",
    _90092_bankcase: "庄例牌赢",
    _90092_playercase: "闲例牌赢",
    _50002_chip0: "单号 0",
    _50002_chip1: "单号 1",
    _50002_chip2: "单号 2",
    _50002_chip3: "单号 3",
    _50002_chip4: "单号 4",
    _50002_chip5: "单号 5",
    _50002_chip6: "单号 6",
    _50002_chip7: "单号 7",
    _50002_chip8: "单号 8",
    _50002_chip9: "单号 9",
    _50002_chip10: "单号 10",
    _50002_chip11: "单号 11",
    _50002_chip12: "单号 12",
    _50002_chip13: "单号 13",
    _50002_chip14: "单号 14",
    _50002_chip15: "单号 15",
    _50002_chip16: "单号 16",
    _50002_chip17: "单号 17",
    _50002_chip18: "单号 18",
    _50002_chip19: "单号 19",
    _50002_chip20: "单号 20",
    _50002_chip21: "单号 21",
    _50002_chip22: "单号 22",
    _50002_chip23: "单号 23",
    _50002_chip24: "单号 24",
    _50002_chip25: "单号 25",
    _50002_chip26: "单号 26",
    _50002_chip27: "单号 27",
    _50002_chip28: "单号 28",
    _50002_chip29: "单号 29",
    _50002_chip30: "单号 30",
    _50002_chip31: "单号 31",
    _50002_chip32: "单号 32",
    _50002_chip33: "单号 33",
    _50002_chip34: "单号 34",
    _50002_chip35: "单号 35",
    _50002_chip36: "单号 36",
    _50002_chip37: "双号 0,1",
    _50002_chip38: "双号 0,2",
    _50002_chip39: "双号 0,3",
    _50002_chip40: "双号 1,2",
    _50002_chip45: "双号 4,5",
    _50002_chip50: "双号 7,8",
    _50002_chip55: "双号 10,11",
    _50002_chip60: "双号 13,14",
    _50002_chip65: "双号 16,17",
    _50002_chip70: "双号 19,20",
    _50002_chip75: "双号 22,23",
    _50002_chip80: "双号 25,26",
    _50002_chip85: "双号 28,29",
    _50002_chip90: "双号 31,32",
    _50002_chip95: "双号 34,35",
    _50002_chip42: "双号 2,3",
    _50002_chip47: "双号 5,6",
    _50002_chip52: "双号 8,9",
    _50002_chip57: "双号 11,12",
    _50002_chip62: "双号 14,15",
    _50002_chip67: "双号 17,18",
    _50002_chip72: "双号 20,21",
    _50002_chip77: "双号 23,24",
    _50002_chip82: "双号 26,27",
    _50002_chip87: "双号 29,30",
    _50002_chip92: "双号 32,33",
    _50002_chip96: "双号 35,36",
    _50002_chip41: "双号 1,4",
    _50002_chip43: "双号 2,5",
    _50002_chip44: "双号 3,6",
    _50002_chip46: "双号 4,7",
    _50002_chip48: "双号 5,8",
    _50002_chip49: "双号 6,9",
    _50002_chip51: "双号 7,10",
    _50002_chip53: "双号 8,11",
    _50002_chip54: "双号 9,12",
    _50002_chip56: "双号 10,13",
    _50002_chip58: "双号 11,14",
    _50002_chip59: "双号 12,15",
    _50002_chip61: "双号 13,16",
    _50002_chip63: "双号 14,17",
    _50002_chip64: "双号 15,18",
    _50002_chip66: "双号 16,19",
    _50002_chip68: "双号 17,20",
    _50002_chip69: "双号 18,21",
    _50002_chip71: "双号 19,22",
    _50002_chip73: "双号 20,23",
    _50002_chip74: "双号 21,24",
    _50002_chip76: "双号 22,25",
    _50002_chip78: "双号 23,26",
    _50002_chip79: "双号 24,27",
    _50002_chip81: "双号 25,28",
    _50002_chip83: "双号 26,29",
    _50002_chip84: "双号 27,30",
    _50002_chip86: "双号 28,31",
    _50002_chip88: "双号 29,32",
    _50002_chip89: "双号 30,33",
    _50002_chip91: "双号 31,34",
    _50002_chip93: "双号 32,35",
    _50002_chip94: "双号 33,36",
    _50002_chip99: "三号 1,2,3",
    _50002_chip100: "三号 4,5,6",
    _50002_chip101: "三号 7,8,9",
    _50002_chip102: "三号 10,11,12",
    _50002_chip103: "三号 13,14,15",
    _50002_chip104: "三号 16,17,18",
    _50002_chip105: "三号 19,20,21",
    _50002_chip106: "三号 22,34,24",
    _50002_chip107: "三号 25,26,27",
    _50002_chip108: "三号 28,29,30",
    _50002_chip109: "三号 31,32,33",
    _50002_chip110: "三号 34,35,36",
    _50002_chip112: "四号 1,2,4,5",
    _50002_chip114: "四号 4,5,7,8",
    _50002_chip116: "四号 7,8,10,11",
    _50002_chip118: "四号 10,11,13,14",
    _50002_chip120: "四号 13,14,16,17",
    _50002_chip122: "四号 16,17,19,20",
    _50002_chip124: "四号 19,20,22,23",
    _50002_chip126: "四号 22,23,25,26",
    _50002_chip128: "四号 25,26,28,29",
    _50002_chip130: "四号 28,29,31,32",
    _50002_chip132: "四号 31,32,34,35",
    _50002_chip113: "四号 2,3,5,6",
    _50002_chip115: "四号 5,6,8,9",
    _50002_chip117: "四号 8,9,11,12",
    _50002_chip119: "四号 11,12,14,15",
    _50002_chip121: "四号 14,15,17,18",
    _50002_chip123: "四号 17,18,20,21",
    _50002_chip125: "四号 20,21,23,24",
    _50002_chip127: "四号 23,24,26,27",
    _50002_chip129: "四号 26,27,29,30",
    _50002_chip131: "四号 29,30,32,33",
    _50002_chip133: "四号 32,33,35,36",
    _50002_chip134: "六号 1,2,3,4,5,6",
    _50002_chip135: "六号 4,5,6,7,8,9",
    _50002_chip136: "六号 7,8,9,10,11,12",
    _50002_chip137: "六号 10,11,12,13,14,15",
    _50002_chip138: "六号 13,14,15,16,17,18",
    _50002_chip139: "六号 16,17,18,19,20,21",
    _50002_chip140: "六号 19,20,21,22,23,24",
    _50002_chip141: "六号 22,23,24,25,26,27",
    _50002_chip142: "六号25,26,27,28,29,30",
    _50002_chip143: "六号28,29,30,31,32,33",
    _50002_chip144: "六号31,32,33,34,35,36",
    _50002_chip148: "组1",
    _50002_chip149: "组2",
    _50002_chip150: "组3",
    _50002_chip145: "列1",
    _50002_chip146: "列2",
    _50002_chip147: "列3",
    _50002_chip154: "单",
    _50002_chip153: "双",
    _50002_chip155: "红色",
    _50002_chip156: "黑色",
    _50002_chip151: "小",
    _50002_chip152: "大",
    _50002_chip97: "三号 0,1,2",
    _50002_chip98: "三号 0,2,3",
    _50002_chip111: "四号 0,1,2,3",

    _60001_chip0: "大",
    _60001_chip1: "小",
    _60001_chip2: "单",
    _60001_chip3: "双",
    _60001_chip22: "围骰",
    _60001_chip16: "围骰1",
    _60001_chip17: "围骰2",
    _60001_chip18: "围骰3",
    _60001_chip19: "围骰4",
    _60001_chip20: "围骰5",
    _60001_chip21: "围骰6",
    _60001_chip10: "逢双1",
    _60001_chip11: "逢双2",
    _60001_chip12: "逢双3",
    _60001_chip13: "逢双4",
    _60001_chip14: "逢双5",
    _60001_chip15: "逢双6",
    _60001_chip4: "骰点 1",
    _60001_chip5: "骰点 2",
    _60001_chip6: "骰点 3",
    _60001_chip7: "骰点 4",
    _60001_chip8: "骰点 5",
    _60001_chip9: "骰点 6",
    _60001_chip23: "点数 4",
    _60001_chip24: "点数 5",
    _60001_chip25: "点数 6",
    _60001_chip26: "点数 7",
    _60001_chip27: "点数 8",
    _60001_chip28: "点数 9",
    _60001_chip29: "点数 10",
    _60001_chip30: "点数 11",
    _60001_chip31: "点数 12",
    _60001_chip32: "点数 13",
    _60001_chip33: "点数 14",
    _60001_chip34: "点数 15",
    _60001_chip35: "点数 16",
    _60001_chip36: "点数 17",
    _60001_chip37: "骰点 1,2",
    _60001_chip38: "骰点 1,3",
    _60001_chip39: "骰点 1,4",
    _60001_chip40: "骰点 1,5",
    _60001_chip41: "骰点 1,6",
    _60001_chip42: "骰点 2,3",
    _60001_chip43: "骰点 2,4",
    _60001_chip44: "骰点 2,5",
    _60001_chip45: "骰点 2,6",
    _60001_chip46: "骰点 3,4",
    _60001_chip47: "骰点 3,5",
    _60001_chip48: "骰点 3,6",
    _60001_chip49: "骰点 4,5",
    _60001_chip50: "骰点 4,6",
    _60001_chip51: "骰点 5,6",
    _60001_chip52: "4中3(1,2,3,4)",
    _60001_chip53: "4中3(2,3,4,5)",
    _60001_chip54: "4中3(2,3,5,6)",
    _60001_chip55: "4中3(3,4,5,6)",
  };
  if (data) {
    var btdata = data.split("^");
    var result = "";

    if (btdata[1] == "V2") {
      data = JSON.parse(btdata[2]);
      //result += '<span style="color:#0f9fe6;">' + beting[data.category.toLowerCase()] + '</span>';
      result += '<span style="color:blue;">' + mgbet[data.playtype.trim()] + "</span>";
    } else {
      result += "";
    }
  } else {
    result = "";
  }
  return result;
});

Vue.filter("SingBetSports", (data) => {
  var offerType = {
    101001: "全场让分",
    101002: "全场⼤⼩",
    101081: "全场⾓球数让分",
    101082: "全场⾓球数⼤⼩",
    101111: "全场黄牌数让分",
    101112: "全场黄牌数⼤⼩",
    102001: "上半场让分",
    102002: "上半场⼤⼩",
    102081: "上半场⾓球数让分",
    102082: "上半场⾓球数⼤⼩",
    102111: "上半场黄牌数让分",
    102112: "上半场黄牌数⼤⼩",
    103001: "下半场让分",
    103002: "下半场⼤⼩",
    104001: "延⻓赛让分",
    104002: "延⻓赛⼤⼩",
    105001: "全场PK赛让分",
    105002: "全场PK赛⼤⼩",
    122001: "第⼀局让分",
    122002: "第⼀局⼤⼩",
    123001: "第⼆局让分",
    123002: "第⼆局⼤⼩",
    124001: "第三局让分",
    124002: "第三局⼤⼩",
    125001: "第四局让分",
    125002: "第四局⼤⼩",
    132251: "赢得局数让分",
    133241: "赢得盘数让分",
    134001: "第⼀盘让分",
    134002: "第⼀盘⼤⼩",
    135001: "第⼆盘让分",
    135002: "第⼆盘⼤⼩",
    136001: "第三盘让分",
    136002: "第三盘⼤⼩",
    140001: "上半场PK赛让分",
    140002: "上半场PK赛⼤⼩",
    201003: "全场独赢",
    201004: "半全场",
    201005: "全场三路",
    201006: "全场单双",
    201007: "全场波胆",
    201085: "全场⾓球数三路",
    201086: "全场⾓球数单双",
    201115: "全场黄牌数三路",
    201268: "赢得冠军",
    202003: "上半场独赢",
    202005: "上半场三路",
    202006: "上半场单双",
    202007: "上半场波胆",
    202085: "上半场⾓球数三路",
    202086: "上半场⾓球数单双",
    202115: "上半场黄牌数三路",
    203003: "下半场独赢",
    203005: "下半场三路",
    203006: "下半场单双",
    203007: "下半场波胆",
    204005: "延⻓赛三路",
    204006: "延⻓赛单双",
    205005: "全场PK赛三路",
    222003: "第⼀局独赢",
    222006: "第⼀局单双",
    223003: "第⼆局独赢",
    223006: "第⼆局单双",
    224003: "第三局独赢",
    224006: "第三局单双",
    225003: "第四局独赢",
    225006: "第四局单双",
    232253: "赢得局数独赢",
    233243: "赢得盘数独赢",
    234003: "第⼀盘独赢",
    235003: "第⼆盘独赢",
    236003: "第三盘独赢",
    254003: "终场独赢",
  };
  var sportsType = {
    1: "⾜球",
    2: "冰球",
    3: "籃球",
    4: "英式橄欖球",
    5: "網球",
    6: "美式⾜球",
    7: "棒球",
    8: "⼿球",
    9: "芬蘭棒球",
    10: "福樂球",
    11: "班迪球",
    12: "⾼爾夫球",
    13: "排球",
    14: "板球",
    15: "桌球",
    16: "斯諾克",
    17: "室內⾜球",
    18: "⾃由搏擊",
    19: "拳擊",
    20: "⾶鏢",
    21: "草地滾球",
    22: "機動競賽(賽⾞, 機⾞, 越野)",
    23: "蓋爾式⾜球",
    24: "⽔球",
    25: "競輪",
    26: "澳式⾜球",
    27: "曲棍球",
    28: "聯盟式橄欖球",
    29: "⾼⼭滑雪",
    30: "冬季兩項",
    31: "冰壺",
    32: "北歐混合式滑雪",
    33: "跳台滑雪",
    34: "越野滑雪",
    35: "有舵雪橇",
    36: "⾃由式滑雪",
    37: "無舵雪橇",
    38: "短道競速滑冰",
    39: "俯式冰橇",
    40: "單板滑雪",
    41: "競速滑冰",
    42: "花式滑冰",
    43: "其他",
    44: "輪式冰球",
    45: "沙灘⾜球",
    46: "⽻⽑球",
    47: "射箭",
    48: "⽥徑",
    49: "沙灘排球聯賽",
    50: "⽔肺潛⽔",
    51: "⾺術",
    52: "擊劍",
    53: "柔道",
    54: "現代五項",
    55: "獨⽊⾈",
    56: "體操",
    57: "划船",
    58: "射擊",
    59: "⽔上芭蕾",
    60: "游泳",
    61: "跆拳道",
    62: "蹦床",
    63: "鐵⼈三項",
    64: "舉重",
    65: "摔⾓",
    66: "帆船",
    67: "賽⾺",
    68: "板棍球",
    69: "撞球",
    70: "壁球",
    71: "棋弈",
    72: "賽狗",
    73: "輕駕⾞賽⾺",
    74: "電⼦競技",
    75: "政治",
    76: "娛樂",
  };
  var betType = {
    1: "|101001|101081|101111|102001|102081|102111|103001|104001|105001|122001|123001|124001|125001|132251|133241|134001|135001|136001|140001|", //让分
    2: "|101002|101082|101112|102002|102082|102112|103002|104002|105002|122002|123002|124002|125002|134002|135002|136002|140002|", //大小
    3: "|201003|202003|203003|222003|223003|224003|225003|232253|233243|234003|235003|236003|254003|", //独赢
    4: "|201007|202007|203007|", //波胆
    5: "|201006|201086|202006|202086|203006|204006|222006|223006|224006|225006|", //单双
    6: "|201005|201085|201115|202005|202085|202115|203005|204005|205005|", //三路
    7: "|201268|", //冠军
    8: "|201004|", //半场／全场
  };
  var OddsTypes = {
    1: "香港盘",
    2: "欧洲盘",
    3: "印尼盘",
    4: "马来西亚盘",
  };
  if (data) {
    data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
    if (data.SportID == 0) {
      //parlay
      var parlayStr = "";
      var oddsType = OddsTypes[data.OddsType];
      data.Items.forEach(function (item, index) {
        var league = item.League.NameCN || item.League.Name;
        var homeTeam = item.HomeTeam.NameCN || item.HomeTeam.Name;
        var awayTeam = item.AwayTeam.NameCN || item.AwayTeam.Name;
        var isruning = item.IsRunning ? "<span>(滚球)</span>" : "";
        parlayStr += "<span>" + sportsType[item.SportID] + "</span><br>";
        parlayStr += "<span>" + offerType[item.OfferTypeID] + "</span>" + isruning + "<br>";
        parlayStr += "<span>" + league + "</span><br>";

        if (item.Target == "h") {
          if (betType[1].includes(item.OfferTypeID) || betType[3].includes(item.OfferTypeID) || betType[6].includes(item.OfferTypeID)) {
            parlayStr += "<span>" + homeTeam + (item.Target == "h" ? '  <span style="color:red">' + Math.abs(items.Line) + "</span>  " : "") + " v " + awayTeam + (item.Target == "a" ? '  <span style="color:red">' + Math.abs(items.Line) + "</span>  " : "") + "</span><br>";
            parlayStr += "<span>" + homeTeam + "</span>";
          }
          if (betType[2].includes(item.OfferTypeID)) {
            parlayStr += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
            parlayStr += '<span>大</span> <span style="color: blue;">' + item.Line;
          }
          if (betType[4].includes(item.OfferTypeID) || betType[7].includes(item.OfferTypeID) || betType[8].includes(item.OfferTypeID)) {
            parlayStr += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
            parlayStr += "<span>" + homeTeam + "</span>";
          }
          if (betType[5].includes(item.OfferTypeID)) {
            parlayStr += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
            parlayStr += "<span>单</span>";
          }
        }
        if (item.Target == "a") {
          if (betType[1].includes(item.OfferTypeID) || betType[3].includes(item.OfferTypeID) || betType[6].includes(item.OfferTypeID)) {
            parlayStr += "<span>" + awayTeam + "</span>";
          }
          if (betType[2].includes(item.OfferTypeID)) {
            parlayStr += '<span>小</span> <span style="color: blue;">' + item.Line;
          }
          if (betType[5].includes(item.OfferTypeID)) {
            parlayStr += "<span>双</span>";
          }
        }
        if (item.Target == "d") {
          if (betType[3].includes(item.OfferTypeID) || betType[6].includes(item.OfferTypeID)) {
            parlayStr += "<span>和</span>";
          }
        }
        parlayStr += ' </span> @ <span style="color: blue;"> ' + item.Odds + "</span> <span>(" + oddsType + ")</span>";
        if (index < data.Items.length - 1) {
          parlayStr += '<hr style="margin:5px 0;" />';
        }
      });
      return parlayStr;
    } else {
      var items = data.Items[0];
      var str = "";
      var isruning = items.IsRunning ? "<span>(滚球)</span>" : "";
      var league = items.League.NameCN || items.League.Name;
      var homeTeam = items.HomeTeam.NameCN || items.HomeTeam.Name;
      var awayTeam = items.AwayTeam.NameCN || items.AwayTeam.Name;
      var oddsType = OddsTypes[data.OddsType];
      str += "<span>" + sportsType[items.SportID] + "</span><br>";
      str += "<span>" + offerType[items.OfferTypeID] + "</span>" + isruning + "<br>";
      str += "<span>" + league + "</span><br>";

      if (items.Target == "h") {
        if (betType[1].includes(items.OfferTypeID) || betType[3].includes(items.OfferTypeID) || betType[6].includes(items.OfferTypeID)) {
          str += "<span>" + homeTeam + (items.Target == "h" ? ' <span style="color:red">' + Math.abs(items.Line) + "</span> " : "") + " v " + awayTeam + (items.Target == "a" ? ' <span style="color:red">' + Math.abs(items.Line) + "</span> " : "") + "</span><br>";
          str += "<span>" + homeTeam + "</span>";
        }
        if (betType[2].includes(items.OfferTypeID)) {
          str += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
          str += '<span>大</span> <span style="color: blue;">' + items.Line;
        }
        if (betType[4].includes(items.OfferTypeID) || betType[7].includes(items.OfferTypeID) || betType[8].includes(items.OfferTypeID)) {
          str += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
          str += "<span>" + homeTeam + "</span>";
        }
      }
      if (items.Target == "a") {
        if (betType[1].includes(items.OfferTypeID) || betType[3].includes(items.OfferTypeID) || betType[6].includes(items.OfferTypeID)) {
          str += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
          str += "<span>" + awayTeam + "</span>";
        }
        if (betType[2].includes(items.OfferTypeID)) {
          str += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
          str += '<span>小</span> <span style="color: blue;">' + items.Line;
        }
      }
      if (items.Target == "d") {
        if (betType[3].includes(items.OfferTypeID) || betType[6].includes(items.OfferTypeID)) {
          str += "<span>" + homeTeam + " v " + awayTeam + "</span><br>";
          str += "<span>和</span>";
        }
      }
      str += ' </span> @ <span style="color: blue;"> ' + items.Odds + "</span> <span> (" + oddsType + ")</span>";
      return str;
    }
  }
});

Vue.filter("WM", (data) => {
  if (data) {
    var btdata = data.split("^");
    var result = "";
    if (btdata[1] == "V2") {
      //data = angular.fromJson(data.match(/\{(\S*)\}/)[0])
      data = JSON.parse(data.match(/\{([\s\S]*)\}/)[0]);
      result += '<span style="color:#464646;">桌号:' + data.tableId + "</span><br> ";
      result += '<span style="color:#464646;">' + data.betResult + "</span> ";
    } else {
      result += "";
    }
  } else {
    result = "";
  }
  return result;
});

Vue.filter("IBCSports", (data) => {
  var result = "请进入沙巴体育查看投注详情";
  return result;
});

Vue.filter("GameType", (data) => {
  var result = "";
  switch (data) {
    case 1:
      result = "真人视讯";
      break;
    case 2:
      result = "电子游艺";
      break;
    case 3:
      result = "体育竞技";
      break;
    case 4:
      result = "彩票游戏";
      break;
    case 5:
      result = "棋牌游戏";
      break;
    case 6:
      result = "捕鱼游戏";
      break;

    default:
      result = "其他";
      break;
  }
  return result;
});
Vue.filter("replaceComma", (value) => {
  if (!value) return "";
  return value.replace(/,/g, " ");
});

Vue.filter("timeYearMonth", (value) => {
  if (!value) return "";
  value = value.toString();
  return value.slice(0, 4) + "/" + value.slice(4);
});
