<template>
  <f7-page no-toolbar no-swipeback name="financial-detail" class="page-messgae-center" @page:init="onPageInit"
    @page:beforein="onPageBeforeIn">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="backMemberCenter()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title v-if="readActiveIndex==2">{{ $t("financial_detail_0020") }}</f7-nav-title> 
      <f7-nav-title v-else>{{ $t("activity_0023") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-tabs class="tabs-financial-detail">
      <div class="financial">
        <div class="nameSpan">{{ financialDetail.CnName_FinancialProductName }}</div>
        <div style="border-bottom: 1px solid #e5e5e5;text-align: left;"><span class="">{{ financialDetail.EnableFrom |
          time2 }} ~ {{ financialDetail.EnableTo | time2 }}</span></div>
        <div class="logo"><img :src="financialDetail.ImgUrl"></div>

        <div v-show="readActiveIndex != 2" style="font-size: 13px;">
          <div class="financialtwo">
            <div style="width: 40%;">{{ $t("activity_0015") }}:</div>
            <div class="" style="width: 60%;text-align: right"><span class="symbol">{{ currencySymbol }}</span>{{
              financialDetail.MinSingleSubscriptionAmount | formatCurrency2 }} ~
              <span class="symbol">{{ currencySymbol }}</span>{{ financialDetail.MaxSingleSinSubscriptionAmount | formatCurrency2 }}</div>
          </div>
          <div class="financialtwo">
            <div style="width: 60%;">{{ $t("activity_0016") }}:</div>
            <div class="" style=" width: 40%;text-align: right;color: var(--f7-THEME-color);"
              v-if="financialDetail.EnableFixedDailyResponseRate">{{
                financialDetail.FixedDailyResponseRate }}%</div>
            <div class="" style="width: 40%;text-align: right;color: var(--f7-THEME-color);"
              v-else>{{ financialDetail.MinDailyResponseRate }}% - {{
                financialDetail.MaxDailyResponseRate }}%</div>
          </div>
          <div class="financialtwo">
            <div style="width: 60%;">{{ $t("financial_detail_0030") }}:</div>
            <div style="text-align: right;width: 40%;">{{ financialDetail.InterestCalculationMethod=='SimpleInterest'?$t("financial_detail_0031"):$t("financial_detail_0032") }}</div>
          </div>
          <div class="financialtwo" v-if="financialDetail.InterestCalculationMethod=='SimpleInterest'">
            <div>{{ $t("financial_detail_0021") }}:</div>
            <div style="text-align: right;">{{ $t("financial_detail_0022",[financialDetail.PeriodDays]) }}({{(financialDetail.PeriodDays * financialDetail.FixedDailyResponseRate) | formatCurrency }}%)</div>
          </div>
          <div class="financialtwo" v-else>
            <div>{{ $t("activity_0047") }}:</div>
            <div style="text-align: right;">{{ $t("financial_detail_0022",[financialDetail.PeriodDays]) }}</div>
          </div>
          <!-- <div class="financialtwo">
            <div v-if="new Date() > new Date(financialDetail.EnableFrom)" style="display: flex;width: 100%;">
              <div style="width: 50%;">结束日期:</div>
              <div style="width: 50%;text-align: right;">{{ financialDetail.EnableTo | time2 }}</div>
            </div>
            <div v-else>
              <div style="width: 50%;">开始日期:</div>
              <div style="width: 50%;text-align: right;">{{ financialDetail.EnableFrom | time2 }}</div>
            </div>
          </div> -->
          <div class="financialtiao" v-if="financialDetail.EnableProgressBar">
            <div style="width: 100%;position: relative;" >{{ $t("activity_0048") }}：<span class="num">{{financialDetail.ProgressBarValue}}%</span></div>
            <div><f7-progressbar :progress="(financialDetail.ProgressBarValue / 100) * 100" style="height:7px;border-radius: 5px; background-color: rgb(231 231 231);" color="red" /></div>
          </div>
          <div style="padding: 5px 0 5px 10px;">{{ $t("activity_0031") }}:</div>
          <div class="term" >
            <div class="info">
              <div v-if="financialDetail.VIPLevelNameStr!==''&&financialDetail.VIPLevelNameStr">
                <div>{{ $t("activity_0032") }}：</div>
                <div class="viplevel">
                  <p v-for="(item2, index) in (financialDetail.VIPLevelNameStr.split(','))" :key="index">{{ item2 }}</p>
                </div>
              </div>
              <!-- <div v-if="financialDetail.EnableReferrerLevelLimit&&financialDetail.EnableReferrerLevels!==''">
                <div>{{ $t("activity_0046") }}：</div>
                <div class="viplevel">
                  <p v-for="(item2, index) in (financialDetail.ReferrerLevelNameStr.split(','))" :key="index">{{ item2 }}</p>
                </div>
              </div> -->
              <div v-show="financialDetail.SubTeamMemberCountLimit > 0">
                <div>{{ $t("financial_detail_0025") }}:</div>
                <div style="height: 20px;width: 70px;text-align: center;border: 1px solid #dfdfdf;border-radius: 5px;">≥{{ financialDetail.SubTeamMemberCountLimit }}</div>
              </div>
              <div v-show="financialDetail.CreditScoreLimit > 0">
                <div>{{ $t("financial_detail_0026") }}:</div>
                <div style="height: 20px;width: 70px;text-align: center;border: 1px solid #dfdfdf;border-radius: 5px;">≥{{ financialDetail.CreditScoreLimit }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="title" style="position: relative;margin-top: 10px;">{{ $t("financial_detail_0027") }}</div>
        <div style="padding: 0px 5px 10px 5px;">{{ financialDetail.CnName_SubTitle }}</div>
        <div class="title" style="position: relative;">{{ $t("financial_detail_0028") }}</div>
        <div style="padding: 0px 5px 10px 5px;" v-html="financialDetail.CnName_FinancialProductDesc"></div>
      </div>
    </f7-tabs>
    <f7-block class="block-button">
      <f7-segmented round tag="p" class="buttonstyle" >
        <f7-button round outline class="goshoppy" @click="showPopup()" v-if="readActiveIndex == 2">{{
          $t("financial_detail_0018") }}</f7-button>
        <f7-button round outline class="goshoppy" @click="showPopup()" v-else>{{
          $t("financial_detail_0006") }}</f7-button>
      </f7-segmented>
    </f7-block>

    <van-popup v-model="vantshow" position="bottom"   class="vanbuy">
      <div class="sheet-modal-swipe-step">
        <div class="display-flex padding justify-content-space-between align-items-center buypopup">
          <div class="yiban one">
            <div style="line-height: 37px;font-size: 16px;">{{ $t("financial_detail_0007") }}</div>
            <div style="position: relative;text-align: right;">
              <i class="f7-icons" style="color:yellow" id="add" @click="topUp()">plus_circle</i>
              <div class="yuer">
                <span class="">{{ $t("financial_detail_0008") }}</span><br />
                <span><span class="symbol">{{ currencySymbol }}</span>{{ memberBalance | formatCurrency}}</span>
              </div>
            </div>
          </div>
          <div class="paddding" style="">{{ financialDetail.CnName_FinancialProductName }}</div>
          <div class="yiban two paddding" v-show="readActiveIndex != 2">
            <div style="color:#707070 ;">{{ $t("financial_detail_0001") }}</div>
            <div style="color:#707070 ;"><span class="symbol">{{ currencySymbol }}</span>{{ financialDetail.MinSingleSubscriptionAmount | formatCurrency2}}-<span class="symbol">{{ currencySymbol }}</span>{{
              financialDetail.MaxSingleSinSubscriptionAmount | formatCurrency2}}</div>
          </div>
          <div class="inputPrice">
            <div style="padding: 0 0 10px 5px;" v-show="readActiveIndex != 2">{{ $t("financial_detail_0017")}}{{ financialDetail.MaxSingleSinSubscriptionAmount | formatCurrency2}}</div>
            <div class="kun">
              <span class="reduce" @click="jian100()"><img src="../../../static/images/yt999/home/ico/ZZicon/reduce.png" alt="" width="18px"></span>
              <input type="number" v-model="price" oninput="validity.valid||(value='0');" min="1" step="1">
              <span class="sum" @click="price = price + 1000"><img src="../../../static/images/yt999/home/ico/ZZicon/sum.png" alt="" width="18px"></span>
              <!-- <img src="../../../static/images/yt999/home/ico/ZZicon/sum.png" alt="" class="sum" @click="price=price+100"> -->
            </div>
          </div>
          <div class="paddding  price" style="display: flex;justify-content: right;padding-bottom: 13px;">
            <span v-for="(item,index) in financialDetail.QuickAmountList" :key="index" @click="selectPrice(item)" :class="price == item ? `selectBg` : ``">{{ item | formatCurrency2}}</span>
            <span @click="price = Math.trunc(memberBalance);" :class="price == Math.trunc(memberBalance) ? `selectBg` : ``">{{
              $t("financial_detail_0010") }}</span>
          </div>
          <div class="yiban two paddding" v-show="readActiveIndex != 2">
            <div style="color:#707070 ;">{{ $t("financial_detail_0016") }}</div>
            <div v-if="financialDetail.InterestCalculationMethod=='SimpleInterest'" style="color:#707070 ;">{{ price | formatCurrency2}}×{{ financialDetail.FixedDailyResponseRate }}%×{{financialDetail.PeriodDays }}={{ Calculatebenefits() | formatCurrency2}}</div>
            <div v-else style="color:#707070 ;">{{ price | formatCurrency2}}×(1+{{ financialDetail.FixedDailyResponseRate }}%)<span style="position: relative;top: -5px;font-size: 12px;">{{financialDetail.PeriodDays }}</span></div>
            
          </div>
          <div class="yiban four paddding price">
            <div><span style="color: #707070;">{{ $t("financial_detail_0011") }}</span><span style="color: var(--f7-THEME-color);font-size: 20px;">{{ currencySymbol }}&nbsp;{{ price  | formatCurrency2}}</span></div>
            <div class="paybtn" @click="financialCreateOrder(financialDetail.FinancialProductID)">
              {{ $t("financial_detail_0012") }}</div>
          </div>
        </div>
      </div>
    </van-popup>

  </f7-page>
</template>

<script>
import { getCookie,getStore } from "@/config/utils";
import { createOrder, getBalancesDetail, memberFinancialProductByIdGet } from "../../../axios/api";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      readActiveIndex: 0,
      financialDetail: {},
      price: 100,
      // select: 1,
      memberBalance: 0,
      mySheetOpened: true,
      vantshow: null,
      lilv:0
    };
  },
  watch: {
    // price(newValue,oldValue){
    //   if(Number.isInteger(newValue)){
    //     this.price = Math.trunc(newValue);
    //   }
    // }
  },
  computed: {
    ...mapGetters(["getFinancialDetail", "userInfo"])
  },
  methods: {
    showPopup() {
      this.vantshow = true;
    },
    colsemySheetOpened(){
      this.mySheetOpened = false;
      this.$refs.mySheet.close();
    },
    selectPrice(price) {
      this.price = price;
      // this.select = index;
    },
    Calculatebenefits(){
      let cal= ((this.price * this.financialDetail.FixedDailyResponseRate * this.financialDetail.PeriodDays) / 100);
      return cal;
    },
    onPageInit() {
      const info = JSON.parse(this.$f7route.params.Info);
      this.readActiveIndex = info['index'];
      const financialid = info['financialid'];
      this.getMemberFinancialProductByIdGet(financialid);

    },
    jian100() {
      if ((this.price - 100) <= 0) return;
      this.price = this.price - 1000;
    },
    backMemberCenter() {
      this.$f7router.back();
    },
    onPageBeforeIn() {
      this.$f7.tab.show("#tab-user-message");

    },
    getMemberFinancialProductByIdGet(id) {
      const params = { "FinancialProductID": id };
      const d = { data: JSON.stringify(params) };
      memberFinancialProductByIdGet(d).then((data) => {
        if (data.Code === "NoError") {
          this.financialDetail = data.Data;
          this.price = this.financialDetail.MinSingleSubscriptionAmount;

          let lixi =  this.calculateFinalProfitAmountCompoundInterest(1000,this.financialDetail.FixedDailyResponseRate,this.financialDetail.PeriodDays);
          this.lilv =  this.calculateFinalResponseRate(lixi,1000);
        }
      });
    },
    getsBalancesDetail() {
      getBalancesDetail().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          this.memberBalance = data.Data.Balance;
        }
      });
    },
    financialCreateOrder(productId) {
      if(this.price<=0) {
        return;
      }
      const params = { "ProductId": productId, "OrderAmount": this.price };
      const d = { data: JSON.stringify(params) };
      createOrder(d).then((data) => {
        if (data.Code === "NoError") {
          this.getsBalancesDetail();
          if(this.readActiveIndex  == 2){
            data.Data = "<span style='color: #2366cc;font-weight: bold;font-size:16px;'>" + this.$t("financial_detail_0029") + "</span>";
          }else{
            data.Data = "<span style='color: #2366cc;font-weight: bold;font-size:16px;'>" + this.$t("financial_detail_0013") + "</span><br><br><span style='font-size:14px;'>" + this.$t("financial_detail_0014") + "<br>" + this.$t("financial_detail_0015") + "</span>";
          }
         
        }
        self.vantshow = false;
        this.logout(data);

      });
    },
    topUp() {
      this.$f7.sheet.close();
      this.$f7router.navigate("/member-center/deposit/");
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
    // 支付结果弹窗
    logout(data) {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog.create({
        title: this.$t(""),
        // text: this.$t("member_center_0055"),
        text: data.Data,
        cssClass: "dialog-logout",
        buttons: [
          {
            text: this.$t("common_0001"),
            cssClass: "dialog-ok",
            onClick: () => {
              self.$f7.$(".dialog-backdrop").css("background", "transparent");
              if (data.Code === "NoError") {
                if(self.readActiveIndex==2){
                  self.$f7router.back();
                }else{
                  self.$f7router.navigate('/member-center/financial-center/' + self.readActiveIndex + '/');

                }
              }
            },
          },
        ],
      }).open();
    },
    handlePopState() {
      // const currentRoute = this.$f7route.path;
      // this.$f7.sheet.close();
      this.$f7.sheet.close();
      location.reload();

    },
    calculateFinalProfitAmountCompoundInterest(orderAmount, fixedDailyResponseRate, days) {
      let finalAmount = orderAmount;
      for (let i = 0; i < days; i++) {
          finalAmount += (finalAmount * fixedDailyResponseRate) / 100;
      }
      const totalInterest = finalAmount - orderAmount;
      return Math.floor(totalInterest * 100) / 100;
    },
    calculateFinalResponseRate(profitAmount, orderAmount) {
      return Math.trunc((profitAmount / orderAmount) * 1000000) / 1000000 * 100;
    }
  },
  created() {
    this.currencySymbol = getStore('currencySymbol');
    this.getsBalancesDetail();
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopState);
  },
  destroyed() {

  }
};
</script>
<style lang="less" scoped>
.tabs-financial-detail {
  width: 95%;
  margin: auto;

  .financial {
    padding: 5px;

    .nameSpan {
      margin: 5px 0;
      font-weight: bold;
      color: #000;
      text-align: left;
    }

    .logo {
      text-align: center;

      img { 
        width: 50%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        margin-top: 8px;
      }
    }

    .financialone {
      padding: 5px;
      height: 60px;
      // border-bottom: 1px solid #e5e5e5;

      img {
        position: absolute;
        height: 60px;
      }

      span {
        display: block;
        padding-left: 70px;
      }


      .toRight {
        height: 20px;
        position: absolute;
        right: 17px;
        top: 24px;
      }
    }

    .financialtwo {
      // margin: 10px 0 0 0;
      // color: #5f6165;
      padding: 0 10px;
      display: flex;
      border-bottom: 1px solid #e5e5e5;

      div {
        width: 50%;
        height: 32px;
        line-height: 32px;
      }
    }
    .financialtiao{
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 15px;
      .num{
        position: absolute;
        right: 0;
        color: var(--f7-THEME-color);
      }
    }

    .term {
      width: 93%;
      margin: auto;
      border-radius: 6px;
      padding: 5px 10px;
      background: #f8f8f8;
      margin-bottom: 5px;
      
      .info {
      border-radius: 5px;
      .viplevel{
        overflow-wrap: break-word;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 0 10px;
        p{
          margin: 4px 0px;
          text-align: center;
          border: 1px solid #dfdfdf;
          border-radius: 5px;
        }
      }
      div {
        margin-bottom: 5px;
        div:nth-child(1) {

          
        }

        div:nth-child(2) {
         
        }
      }
      
      
    }
    }
  }

  .yiban {
    display: flex;
    
    line-height: 37px;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .one {
    background: var(--f7-THEME-color);
    color: white;
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }

  .title {
    font-weight: bold;
    padding-left: 23px;
  }

  .title::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 10px;
    transform: translateX(-50%);
    width: 3px;
    height: 11px;
    background-color: var(--f7-THEME-color);
  }
}

.goshoppy {
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  width: 230px;
  border: none;
  border-radius: 10px !important;
  margin: 0 auto;
  text-transform: none;
  background: radial-gradient(circle, var(--f7-THEME-color), var(--f7-THEME-color));
  
}

.buttonstyle {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 0;
  height: 60px;
  z-index: 10;
  padding-top: 7px;
}

.buypopup {
  display: block !important;
  padding: 0px !important;

  #add {
    position: absolute;
    right: 25px;
    top: 5px;
    font-size: 23px;
  }

  .yuer {
    width: 100%;
    position: absolute;
    right: 60px;
  }

  .yiban {
    display: flex;

    div:nth-child(1) {
      width: 40%;
    }

    div:nth-child(2) {
      width: 60%;
    }
  }

  .inputPrice {
    color: rgb(112, 112, 112);
    margin: 10px;

    .kun {
      border: 1px solid #ccc;
      border-radius: 5px;
      position: relative;
      height: 40px;
      margin: 0 5px;

      input {
        width: 80%;
        height: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: 0 auto;
      }
    }

    .reduce {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sum {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .paddding {
    margin: 10px;
    padding: 5px;
    border-bottom: 1px solid #e1e0e0;
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;

    /* 超出部分显示省略号 */


    div:nth-child(2) {
      text-align: right;
    }

    .selectBg {
      background: var(--f7-THEME-color);
      color: white;
    }
  }
  .price{
    span {
      min-width: 50px;
      height: 20px;
      display: block;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
      border: 1px solid var(--f7-THEME-color);
      margin: 0px 3px;
      padding: 0 5px;

    }
  }
  .one {
    background: var(--f7-THEME-color);
    color: white;
    padding: 11px 0 7px 28px;
  }

  .four {
    border: none;

    span {
      border: none;
      text-align: left;
    }
  }

  .paybtn {
    text-align: center !important;
    line-height: 36px;
    border-radius: 20px;
    background: var(--f7-THEME-color);
    height: 36px;
    color: #ffffff;
    font-size: 16px;
    width: 113px !important;
    margin-left: 25%;
    position: relative;
    bottom: -4px;
  }
}
.vanbuy{
  border-radius: 20px 20px 0 0;
}
</style>
