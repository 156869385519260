<template>
  <f7-page no-toolbar no-swipeback name="invite-friends" class="page-messgae-center">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="backMemberCenter">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("invite_friends_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="content">
      <div class="info">
        <div class="one">
          <div>{{ $t("invite_friends_0002") }}</div>
          <div>{{numberCode}}</div>
          <div class="coppy"><a href="#" class="list-button color-black" v-clipboard:copy="numberCode" v-clipboard:success="onCopy" v-clipboard:error="onError" style="padding: 0;">{{ $t("invite_friends_0004") }}</a></div>
        </div>
        <div class="two" style="position: relative;">
          <div v-clipboard:copy="link" v-clipboard:success="onCopy" v-clipboard:error="onError"><img src="../../../static/images/yt999/home/ico/ZZicon/add1.png" alt=""
              style="height: 35px;margin: 13px 0 3px 0 ;"></div>
          <div>{{ $t("invite_friends_0003") }}</div>
        </div>
        <div>
          <div @click="logout()"><img src="../../../static/images/yt999/home/ico/ZZicon/add2.png" alt=""
              style="height: 35px;margin: 13px 0 3px 0 ;"></div>
          <div>{{ $t("invite_friends_0005") }}</div>
        </div>
      </div>
      <div class="num">
        <div class="">
          <div style="font-size: 16px;line-height: 58px;">{{ totalReferrerDownlineCount }}</div>
          <div>{{ $t("invite_friends_0006") }}</div>
        </div>
        <div style="position: relative;">
          <div style="font-size: 16px;line-height: 58px;">{{ todayProfitAmount | formatCurrency}}</div>
          <div>{{ $t("invite_friends_0007") }}</div>
        </div>
        <div>
          <div style="font-size: 16px;line-height: 58px;">{{ totalProfitAmount | formatCurrency}}</div>
          <div>{{ $t("invite_friends_0008") }}</div>
        </div>
      </div>
      <div class="hen" @click="$f7router.navigate('/member-center/invite-overview/')">
        <span>{{ $t("invite_friends_0009") }}</span>
        <i class="f7-icons rig">chevron_right</i>
      </div>
      <div class="hen" @click="$f7router.navigate('/member-center/friend-details/')">
        <span>{{ $t("invite_friends_0010") }}</span>
        <i class="f7-icons rig">chevron_right</i>
      </div>
      <div class="hen" @click="goCommission()">
        <span>{{ $t("invite_friends_0011") }}</span>
        <i class="f7-icons rig">chevron_right</i>
      </div>
      <div class="wenzi">
        <h3 style="color: var(--f7-THEME-color)" >{{ $t("invite_friends_0012") }}</h3>
        <p>1. {{ $t("invite_friends_0013") }}</p>
        <p>2. {{ $t("invite_friends_0014") }}</p>
        <p>3. {{ $t("invite_friends_0015") }}</p>
        <p style="color: red;">4. {{ $t("invite_friends_0016") }}</p>
      </div>
    </div>
    <div class="code"  id="addcode" style="display: none;">
      <div class="er" id="qrcode"></div>
      <div class="un">
        <div class="num">
          <div style="text-align: center;width: 100%;">{{numberCode}}</div>
        </div>
        <div style="text-align: left;">{{ $t("invite_friends_0017") }}:</div>
        <div class="href">
          <div>
            {{link}}
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import QRCode from "qrcodejs2";
import { getCookie } from "@/config/utils";
import { referrerinfo } from "../../../axios/api";

export default {
  components: {QRCode},
  props: {},
  data() {
    return {
      readActiveIndex: 0,
      link: " ",
      numberCode: 0,
      referrerinfoList: [],
      totalReferrerDownlineCount : 0,  //好友人数
      todayProfitAmount: 0,//今日收益
      monthProfitAmount: 0,//月收益
      totalProfitAmount: 0, //总收益

    };
  },
  watch: {},
  computed: {
    
  },
  methods: {
    //  生成二维码
    qrcode() {
      let self = this;
      this.numberCode = getCookie("p");
      this.link = window.location.protocol + "//" + window.location.host + "/#/register/" + "?d=" + this.numberCode;
      let qrcode = new QRCode("qrcode", {
        width: 150,
        height: 150, // 高度
        text: this.link, // 二维码内容
        useSVG: true, // 使用 SVG 而非 Canvas
        correctLevel: QRCode.CorrectLevel.L, // 使用较低的纠错等级
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      });
    },// 弹窗
    logout() {      
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
   
      self.$f7.dialog.create({
        title: this.$t(""),
        text: document.getElementById('addcode').innerHTML,
        cssClass: "code",
        buttons: [
          
        ],
  
      }).open();

    },

    getreferrerinfo() {
      const self = this;
      referrerinfo().then((data)=>{
        if(data.Code === 'NoError'){
          self.referrerinfoList = data.Data;
          self.totalReferrerDownlineCount = data.Data["TotalReferrerDownlineCount_L1"]+data.Data["TotalReferrerDownlineCount_L2"]+data.Data["TotalReferrerDownlineCount_L3"];
          self.todayProfitAmount = data.Data["TodayProfitAmount"];
          self.totalProfitAmount = data.Data["TotalProfitAmount"];
          self.monthProfitAmount = data.Data["MonthProfitAmount"];

        }
      });
    },
    goCommission(){
      const queryParam = {"TodayProfitAmount":this.todayProfitAmount,"TotalProfitAmount":this.totalProfitAmount,"MonthProfitAmount":this.monthProfitAmount};
      this.$f7router.navigate(`/member-center/commission-details/${JSON.stringify(queryParam)}/`);
    },
    backMemberCenter() {
      this.$f7router.back();
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
    onCopy(e) {
      const self = this;
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
  },
  created() {
    this.getreferrerinfo();
   },
  mounted() { 
    this.qrcode();
    
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 90%;
  margin: 10px auto;

  .info {
    display: flex;
    background: var(--f7-THEME-color);
    border-radius: 10px;
    color: white;

    div {
      width: 33.3%;

      div {
        width: 100%;
        text-align: center;
      }
    }

    .two:before {
      content: '';
      position: absolute;
      top: 17px;
      left: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 55px;
      background-color: #ff7b71;
    }

    .two:after {
      content: '';
      position: absolute;
      top: 17px;
      right: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 55px;
      background-color: #ff7b71;
    }

    .one {
      div {
        height: 20px;
        line-height: 20px;
        margin-top: 6px;
      }

      div:nth-child(2) {
        font-size: 20px;
      }

      .coppy {
        background: var(--f7-THEME-color2);
        color: #000;
        width: 70px;
        border-radius: 10px;
        margin: 7px auto;
        display: grid;
        align-content: center;
      }
    }
  }

  .num {
    display: grid;
    grid-template-columns: auto auto auto;
    border: 1px solid #f2f3f5;
    border-radius: 10px;
    margin: 15px 0;
    height: 76px;
    box-shadow: 0px 1px 4px rgb(185 182 152);

    div {

      div {
        width: 100%;
        text-align: center;
        height: 38px;
        line-height: 38px;
      }
    }

    div:nth-child(2):before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 55px;
      background-color: #f3f3f3;
    }

    div:nth-child(2):after {
      content: '';
      position: absolute;
      top: 10px;
      right: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 55px;
      background-color: #f3f3f3;
    }
  }

  .hen {
    background: var(--f7-THEME-color2);
    border: 1px solid #abd1f7;
    border-radius: 10px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 30px;
    position: relative;
    margin: 0 0 10px 0;

    .rig {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 22px;
    }
  }
}
// 邀请二维码
.code{
  width: 370px;
  height: 425px;
  background: #fff;
  border-radius: 5px;
  .er{
    width: 200px;
    height: 200px;
    padding: 20px;
    border-bottom: 1px dashed #8f8f8f;  
    display: grid;
    justify-content: center;
    align-content: center;
    position: relative;
  }
  .er::before {
    content: '';
    position: absolute;
    left: -26px;
    bottom: -10px;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    background: #514d4d;
    box-sizing: border-box;
  }
  .er::after {
    content: '';
    position: absolute;
    right: -26px;
    bottom: -10px;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    background: #514d4d;
    box-sizing: border-box;
  }
  .un{
    margin: 0 auto;
    .num{
      display: flex;
      background: var(--f7-THEME-color);
      border-radius: 5px;
      color: white;
      height: 35px;
      line-height: 35px;
      padding: 0 15px;
      margin: 20px 0px;
      div:nth-child(1){
        width: 50%;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
      }
      div:nth-child(2){
        width: 50%;
        text-align: right;
        a{
          color: white;
        }
      }
    }
    .href{
      background: var(--f7-THEME-color2);
      border: 1px solid var(--f7-THEME-color2);
      border-radius: 5px;
      padding: 10px;
      min-height: 30px;
      color: #514d4d;
      overflow-wrap: break-word; //文字溢出换行
    }
   
  }
  #qrcode{
    img{
      margin: 0px auto;
      padding: 50px 0;
      height: 150px;
    }
    
  }

}

</style>
