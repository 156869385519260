<template>
  <f7-page class="page-home" name="home" @page:init="onPageInit" @page:beforein="onPageBeforeIn" style="padding-bottom: var(--f7-safe-area-bottom)" @page:beforeUnmount="onPageBeforeunmount">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left class="">
        <img height="30" :src="`../static/images/yt999/merchant/${$f7.params.fileName}/logo/dlog.png`" style="width: 150px; height: auto; margin-left: 10px" />
      </f7-nav-left>
      <f7-nav-title sliding></f7-nav-title>
      <f7-nav-right class="nav-balance">
        <!-- <f7-link external :href="dowloadLink" v-if="!isAppDevice && dowloadLink != ''">
          <img class="btn-dowload" src="../static/images/yt999/app_dowload.png" height="22" />
        </f7-link> -->
        <f7-link external target="_blank" :href="dowloadLink" v-if="!isAppDevice && dowloadLink != ''">
          <img class="btn-dowload" src="../static/images/yt999/down.png" height="22" />
        </f7-link>
        <!-- /member-center/online-cs/ -->
        <!-- <f7-link external  target="_blank" href="https://tawk.to/chat/667432c79d7f358570d1ad5b/1i0qucna3">
          <img class="btn-dowload" src="../static/images/yt999/ting.png" height="22"/>
        </f7-link> -->
        <f7-link external target="_blank" v-if="mobileService && mobileService != ''" :href="mobileService">
          <img class="btn-dowload" src="../static/images/yt999/ting.png" height="22" />
        </f7-link>
        <f7-link @click="openChangeLanguagePopup">
          <img class="btn-dowload" src="../static/images/yt999/lang.png" height="24" style="margin-right: 10px" />
        </f7-link>
        <!-- <f7-link @click="openChangeCurrencyPopup" class="btn-currency" v-show="currencyList.length > 1">
          <span :class="['icon-currency', 'icon-' + currency]"></span>
          <span class="code">{{ currency }}</span>
        </f7-link> -->
      </f7-nav-right>
    </f7-navbar>
    <div style="background: linear-gradient(180deg, #ffffff 93.51%, #f8f8f8 100%); box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07)">
      <!-- Swiper banner -->
      <!-- 顶部滑动广告 -->
      <!-- <f7-block class="block-banner home-banner"> -->
      <f7-block class="block-banner">
        <f7-swiper pagination next-button prev-button data-lazy="{'enabled': true}" :params="swiperParams" class="swiper-banner" v-if="bannerShow">
          <f7-swiper-slide v-for="(item, index) in bannerList" :key="index">
            <img :src="`${bannerBaseUri + item.Url}`" class="swiper-lazy" />
            <div class="preloader swiper-lazy-preloader"></div>
          </f7-swiper-slide>
        </f7-swiper>
      </f7-block>

      <!-- Top User info-->
      <div class="main-category">
        <marquee :lamplist="lampList"></marquee>
        <!-- <div class="user-info">
          <div class="user-detail row no-gap">
            <div class="left-area col-35">
              <div class="balance-title">{{ $t("home_0001") }}</div>
              <div v-if="getLoginState" class="login-balance">
                <span v-if="memberBalance || memberBalance == 0.0">{{ memberBalance | formatCurrency }}</span>
                <preloader :list="4" class="incenter" v-else></preloader>
                <span class="referr" v-if="memberBalance || memberBalance == 0.0" @click="getUserMemberBalance()">
                  <img src="../static/images/yt999/home/icon-refresh2.png" alt="" height="16" />
                </span>
              </div>
              <div v-else class="non-balance" @click="$f7router.navigate('/login/')">{{ $t("home_0002") }}</div>
            </div>
            <div class="right-area col-65 row no-gap"
              style="width: calc((85% - var(--f7-grid-gap) * (var(--f7-cols-per-row) - 1)) / var(--f7-cols-per-row)); margin-top: 10px">
              <div class="action col-25" @click="$f7router.navigate('/member-center/deposit/')">
                <a>
                  <p>
                    <img :src="`../static/images/yt999/home/deposit_${$f7.params.currLang}.png`"
                      :onerror="`this.onerror=''; src='../static/images/yt999/home/deposit_enUS.png'`"
                      alt="DepositQA" />
                  </p>
                </a>
              </div>
              <div class="action col-25" @click="$f7router.navigate('/member-center/withdraw/')">
                <a>
                  <p>
                    <img :src="`../static/images/yt999/home/withdraw_${$f7.params.currLang}.png`"
                      :onerror="`this.onerror=''; src='../static/images/yt999/home/withdraw_enUS.png'`"
                      alt="Withdraw" />
                  </p>
                </a>
              </div>
              <div class="action col-25" @click="$f7router.navigate('/member-center/order-center/0/')">
                <a>
                  <p>
                    <img :src="`../static/images/yt999/home/task_${$f7.params.currLang}.png`"
                      :onerror="`this.onerror=''; src='../static/images/yt999/home/task_enUS.png'`" alt="Order" />
                  </p>
                </a>
              </div>
              <div class="action col-25" @click="$f7router.navigate('/member-center/invite-friends/')">
                <a>
                  <p>
                    <img :src="`../static/images/yt999/home/invite_${$f7.params.currLang}.png`"
                    :onerror="`this.onerror=''; src='../static/images/yt999/home/invite_enUS.png'`" alt="Order" />
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div> -->

        <div class="menu">
          <!-- <div @click="$f7router.navigate('/member-center/deposit/')">
            <img src="../static/images/yt999/home/ico/ZZicon/ck.png" alt="" srcset="">
            <span>{{ $t("home_0078") }}</span>
          </div> -->
          <div @click="$f7router.navigate('/member-center/withdraw/')">
            <img src="../static/images/yt999/home/ico/ZZicon/qk.png" alt="" srcset="" />
            <span class="">{{ $t("home_0079") }}</span>
          </div>
          <div @click="$f7router.navigate('/cis/')">
            <img src="../static/images/yt999/home/ico/ZZicon/cs.png" alt="" srcset="" />
            <span>{{ $t("home_0080") }}</span>
          </div>

          <div @click="$f7router.navigate('/member-center/invite-friends/')">
            <img src="../static/images/yt999/home/ico/ZZicon/yq.png" alt="" srcset="" />
            <span>{{ $t("home_0082") }}</span>
          </div>
          <!-- <div @click="$f7router.navigate('/member-center/online-cs/')">
            <img src="../static/images/yt999/i_withdraw.png" alt="" srcset="">
            <span>{{ $t("member_center_0048") }}</span>
          </div> -->
          <!-- <div @click="stayTuned()">
            <img src="../static/images/yt999/home/ico/ZZicon/bzsc.png" alt="" srcset="">
            <span class="">{{ $t("home_0081") }}</span>
          </div> -->
          <div class="news" @click="$f7router.navigate('/member-center/message-center/2/')">
            <img src="../static/images/yt999/home/ico/ZZicon/xx.png" alt="" srcset="" />
            <span>{{ $t("home_0081") }}</span>
            <span class="messageNum" v-show="userMessageList.length > 0">{{ userMessageList.length }}</span>
          </div>
          <div @click="luckyDrawBoxEntryOpen ? goluckybox() : stayTuned(), { replaceState: true, ignoreCache: true }">
            <img src="../static/images/yt999/home/ico/ZZicon/luckybox.png" alt="" srcset="" />
            <span class="">{{ $t("home_0098") }}</span>
          </div>
          <!-- <div @click="stayTuned()" >
            <img src="../static/images/yt999/home/ico/ZZicon/luckybox.png" alt="" srcset="">
            <span class="">{{ $t("home_0098") }}</span>
          </div> -->
          <div @click="$f7router.navigate('/about/')">
            <img src="../static/images/yt999/home/ico/ZZicon/gygs.png" alt="" srcset="" />
            <span class="">{{ $t("home_0085") }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="" class="home-trusted container plain" style="margin: 10px 0 0 0px;" @click="gohome()">
      <div class="heading" style="position: relative">
        <div class="h1" >{{ $t("home_0016") }}</div>
        <div class="h2" @click="goMainSite()">
          <f7-link style="margin: 0 5px;color: #fff;" external target="_blank" >{{ mainSiteUrl }}</f7-link>
          <img src="../static/images/yt999/home/ico/ZZicon/gohome.png" alt="" height="20px">
        </div>
      </div>
    </div> -->

    <div class="comein2" v-if="mainSiteUrl">
      <f7-link external :target="!isAppDevice?'_blank':'_self'" :href="mainSiteUrl?mainSiteUrl:''">
        <img src="../static/images/yt999/home/guangwang.png" width="100%" height="123">
        <div class="text">
          <p><span style="font-size: 15px;font-weight: bold;">{{ $t("about_0002") }}</span>&nbsp;{{ $t("home_0016") }}</p>
          <div class="red-button">{{ $t("home_0092") }}</div>
        </div>
      </f7-link>
    </div>


    <div class="comein" v-if="baseInfo.OfficialChannelLink_Whatsapp||baseInfo.OfficialChannelLink_Telegram">
      <f7-link external>
        <img src="../static/images/yt999/home/comein.png" width="100%" height="123">
        <div class="text">
          <p>{{ $t("home_0099") }}</p>
          <p>{{ $t("home_0100") }}</p>
          <p>
            <img @click="officialChannelLink(baseInfo.OfficialChannelLink_Whatsapp)" v-if="baseInfo.OfficialChannelLink_Whatsapp" src="../static/images/yt999/home_ico/WhatsApp.png" height="27px" srcset="">
            <img @click="officialChannelLink(baseInfo.OfficialChannelLink_Telegram)" v-if="baseInfo.OfficialChannelLink_Telegram" src="../static/images/yt999/home_ico/telegram.png" height="27px" srcset="">
          </p>
        </div>
      </f7-link>
    </div>

    <div class="vipgo" style="display: flex">
      <div class="logo">
        <img src="../static/images/yt999/awardImg.png" alt="" srcset="" height="90px" />
      </div>
      <div style="width: 72%">
        <div class="text1">{{ $t("home_0094") }}</div>
        <div class="text2">{{ $t("home_0095") }}</div>
        <div class="red-button" @click="$f7router.navigate('/award/')">{{ $t("home_0096") }}</div>
      </div>
    </div>

    <!-- <ul>
        <li>
          <img class="img-title" src="../static/images/yt999/home/trusted_1.png" />
          <span style="text-align: left">
            {{ $t("home_0017") }}
            <strong>{{ $t("home_0018") }}</strong>
            {{ $t("home_0019") }}
          </span>
        </li>
        <li>
          <img class="img-title" src="../static/images/yt999/home/trusted_2.png" />
          <span style="text-align: left">
            {{ $t("home_0020") }}
            <strong>{{ $t("home_0021") }}</strong>
            {{ $t("home_0022") }}
            <strong>{{ $t("home_0023") }}</strong>
            {{ $t("home_0024") }}
            <strong>{{ $t("home_0025") }}</strong>
          </span>
        </li>
        <li>
          <img class="img-title" src="../static/images/yt999/home/trusted_3.png" />
          <span style="text-align: left">
            {{ $t("home_0026") }}
            <strong>{{ $t("home_0027") }}</strong>
            {{ $t("home_0028") }}
          </span>
        </li>
      </ul> -->

    <f7-popup v-show="showLiveRatePopup" class="demo-popup" :opened="popupLiveRateOpened" @popup:open="openLiveRatePopup" @popup:close="closeLiveRatePopup">
      <f7-page>
        <f7-navbar :title="$t('home_0046')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="home-liveRates" style="margin-top: 16px">
          <div class="liveRates-content">
            <f7-row no-gap class="liveRates-name">
              <f7-col width="35">{{ $t("home_0038") }}</f7-col>
              <f7-col width="25" class="title-center">{{ $t("home_0039") }}</f7-col>
              <f7-col width="40" class="title-center">{{ $t("home_0040") }}</f7-col>
            </f7-row>
            <div class="liveRate-lists">
              <f7-row v-for="(item, key) of liveRateList" :key="key" no-gap class="rate-item">
                <f7-col width="35">
                  <f7-row no-gap>
                    <f7-col class="country" width="33">
                      <div>
                        <span :class="['icon-country', 'icon-' + item.Source]"></span>
                      </div>
                      <div>
                        <span :class="['icon-country', 'icon-' + item.Target]"></span>
                      </div>
                    </f7-col>
                    <f7-col width="66" class="country-name">
                      <div>{{ item.Source }}/{{ item.Target }}</div>
                      <div>{{ item.Target }}/{{ item.Source }}</div>
                    </f7-col>
                  </f7-row>
                </f7-col>
                <f7-col class="increase" width="25">
                  <div>
                    <img v-if="item.PreviousRate < item.Rate" src="../static/images/yt999/home/up.png" alt="" />
                    <img v-else src="../static/images/yt999/home/down.png" alt="" />
                  </div>
                  <div>
                    <img v-if="item.TargetPreviousRate < item.TargetRate" src="../static/images/yt999/home/up.png" alt="" />
                    <img v-else src="../static/images/yt999/home/down.png" alt="" />
                  </div>
                </f7-col>
                <f7-col class="ask" width="40">
                  <div>{{ item.Rate }}</div>
                  <div>{{ item.TargetRate }}</div>
                </f7-col>
              </f7-row>
            </div>
          </div>
        </div>
      </f7-page>
    </f7-popup>

    <div class="home-liveRates">
      <div class="liveRates-title">
        <span class="title">{{ $t("home_0076") }}</span>
        <i class="f7-icons" style="font-size: 20px" @click="$f7router.navigate('member-center/order-center/0/')">chevron_right</i>
        <!-- <span class="more"><f7-button class="liveRate-popup" popup-open=".demo-popup"></f7-button></span> -->
      </div>
      <!-- <div class="liveRates-content">
        <f7-row no-gap class="liveRates-name">
          <f7-col width="35">{{ $t("home_0038") }}</f7-col>
          <f7-col width="25" class="title-center">{{ $t("home_0039") }}</f7-col>
          <f7-col width="40" class="title-center">{{ $t("home_0040") }}</f7-col>
        </f7-row>
        <div class="liveRate-lists">
          <f7-row v-for="(item, key) of liveRateIncludesList" :key="key" no-gap class="rate-item">
            <f7-col width="35">
              <f7-row no-gap>
                <f7-col class="country" width="33">
                  <div>
                    <span :class="['icon-country', 'icon-' + item.Source]"></span>
                  </div>
                  <div>
                    <span :class="['icon-country', 'icon-' + item.Target]"></span>
                  </div>
                </f7-col>
                <f7-col width="66" class="country-name">
                  <div>{{ item.Source }}/{{ item.Target }}</div>
                  <div>{{ item.Target }}/{{ item.Source }}</div>
                </f7-col>
              </f7-row>
            </f7-col>
            <f7-col class="increase" width="25">
              <div>
                <img v-if="item.PreviousRate < item.Rate" src="../static/images/yt999/home/up.png" alt="" />
                <img v-else src="../static/images/yt999/home/down.png" alt="" />
              </div>
              <div>
                <img v-if="item.TargetPreviousRate < item.TargetRate" src="../static/images/yt999/home/up.png" alt="" />
                <img v-else src="../static/images/yt999/home/down.png" alt="" />
              </div>
            </f7-col>
            <f7-col class="ask" width="40">
              <div>{{ item.Rate }}</div>
              <div>{{ item.TargetRate }}</div>
            </f7-col>
          </f7-row>
        </div>
      </div> -->
    </div>
    <!-- 视频滑动 -->
    <div class="main-suggest-content">
      <f7-swiper :params="swiperNormalOption">
        <f7-swiper-slide v-for="(item, index) in gamemediarandomlist" :key="index">
          <div class="music-all">
            <div class="music-img" v-for="(item1, index) in item" :key="index">
              <div class="music-logo">
                <img class="music-img" :src="`${item1.ProviderGameExtensionData}`" @click="$f7router.navigate('member-center/order-center/0/')" />
              </div>
              <div class="music-info">
                <div class="music-name">{{ item1.GameName }}</div>
                <!-- <div class="music-author">{{ item1.SubTitle }}</div> -->
              </div>
            </div>
          </div>
        </f7-swiper-slide>
      </f7-swiper>
    </div>

    <!-- 会员等级去升级 -->
    <!-- <div class="vipgo" style="display: flex;">
      <div  class="logo">
        <img src="../static/images/yt999/home/ico/ZZicon/Group6.png" alt="" srcset="" height="90px">
      </div>
      <div style="width: 70%;">
        <div class="text1">{{ $t("home_0086") }}</div>
        <div class="text2">{{ $t("home_0087") }}</div>
        <div class="red-button" @click="$f7router.navigate('/member-center/vip/'+userInfo.VipLevel+'/')">{{ $t("home_0088") }}</div>
      </div>
    </div> -->


    <!-- 热门理财产品 -->
    <div class="home-liveRates">
      <div class="liveRates-title">
        <span class="title">{{ $t("home_0077") }}</span>
        <i class="f7-icons" style="font-size: 20px" @click="$f7router.navigate('/activity/0/')">chevron_right</i>
        <!-- <span class="more"><f7-button class="liveRate-popup" popup-open=".demo-popup"></f7-button></span> -->
      </div>
    </div>
    <!-- 产品滑动 -->
    <div class="main-suggest-content">
      <f7-swiper :params="swiperNormalOption">
        <f7-swiper-slide v-for="(item, index) in memberFinancialList" :key="index">
          <div class="" style="width: 100%; display: flex">
            <div class="product-container chanping" v-for="(item1, index) in item" :key="index">
              <p class="bold-black" style="width: 95%; margin: 0 auto; height: 60px">{{ item1.CnName_FinancialProductName }}</p>
              <p class="red-text" v-if="item1.EnableFixedDailyResponseRate">{{ item1.FixedDailyResponseRate }}%</p>
              <p class="red-text" v-else>{{ item1.MinDailyResponseRate }}% - {{ item1.MaxDailyResponseRate }}%</p>
              <p class="grey-text">{{ $t("home_0089") }}</p>
              <p class="black-text" style="padding: 0 3px">
                <span class="symbol">{{ currencySymbol }}</span>
                {{ item1.MinSingleSubscriptionAmount | formatCurrency2 }} {{ $t("home_0090") }}
              </p>
              <button class="button" @click="goToFinancialDetail(item1)">{{ $t("home_0091") }}</button>
            </div>
          </div>
        </f7-swiper-slide>
      </f7-swiper>
    </div>

    <div class="home-download plain">
      <div class="content">
        <!-- <h2>{{ $t("home_0060") }}</h2>
        <div class="content-text">
          <p>{{ $t("home_0061") }}</p>
        </div>
        <div class="downloadLink">
          <strong>
            <a href="#" target="_blank" title="Free Money Transfers App">{{ $t("home_0062") }}</a>
          </strong>
        </div> -->
        <!-- <div class="content-text2">
          <p>{{ $t("home_0063") }}</p>
        </div>
        <div class="content-text3">
          <p>{{ $t("home_0064") }}</p>
        </div> -->
        <!-- <div class="phone-img">
          <img src="../static/images/yt999/home/mobilemodule.png" alt="" />
        </div> -->
        <!-- <div class="content-text">
          <p>{{ $t("home_0065") }}</p>
        </div>
        <div class="content-text4">
          <p>{{ $t("home_0066") }}</p>
        </div> -->
        <!-- <div class="downloadLink">
          <strong>
            <a href="#" target="_blank">{{ $t("home_0067") }}</a>
            {{ $t("home_0068") }}&nbsp;
            <a href="#" target="_blank">{{ $t("home_0069") }}</a>
            {{ $t("home_0070") }}
            <a href="#" target="_blank">{{ $t("home_0071") }}</a>
            {{ $t("home_0072") }}
          </strong>
        </div> -->
        <!-- <div class="downloadLink-img">
          <a class="google-play" href="#"></a>
          <a class="app-store" href="#"></a>
        </div> -->
      </div>
    </div>

    <!-- <div class="" id="checkout-cb-explainer" style="margin: auto; margin-top: 10px">
      <h2 class="title">
        <div class="wrap">
          <span class="first">KOI APP</span>
          <em style="width: 145px">{{ $t("home_0029") }}</em>
          <span>{{ $t("home_0030") }}</span>
        </div>
      </h2>
      <div class="scrolling ui-container">
        <f7-swiper :params="swiperParams2" class="swiper-test">
          <f7-swiper-slide>
            <p class="point-title">1.{{ $t("home_0031") }}</p>
            <span class="newpppppp">{{ $t("home_0032") }}</span>
          </f7-swiper-slide>
          <f7-swiper-slide>
            <p class="point-title">2.{{ $t("home_0033") }}</p>
            <span class="newpppppp">{{ $t("home_0034") }}</span>
          </f7-swiper-slide>
          <f7-swiper-slide>
            <p class="point-title">3.{{ $t("home_0035") }}</p>
            <span class="newpppppp">{{ $t("home_0036") }}</span>
          </f7-swiper-slide>
        </f7-swiper>
      </div>
    </div> -->
    <!-- <div class="container">
      <span class="container_vip_title">{{ $t("vip_club_0028") }}</span>
      <div class="vip_div">
        <ul class="vip">
          <li class="pic" v-for="item in simpleVipList" :key="item.Id">
            <span v-show="getLoginState && item.Name.toUpperCase() === userInfo.VipLevelName" class="s1">&nbsp;&nbsp;{{
              $t("vip_club_0025") }}</span>
            <span class="s2">&nbsp;&nbsp;{{ item.DailyOrderCountLimit }}&nbsp;{{ $t("vip_club_0026") }}</span>
            <span class="s3">&nbsp;&nbsp;{{ $t("vip_club_0027") }}&nbsp;<span class="symbol">{{ currencySymbol }}</span>{{ item.DepositTotalAmount }}</span>
            <span v-if="getLoginState && item.Name.toUpperCase() === userInfo.VipLevelName" class="s4-active">{{
              (item.CommissionRate * 100) | formatCurrency }}%</span>
            <span v-else class="s4">{{ (item.CommissionRate * 100) | formatCurrency }}%</span>
            <img v-if="getLoginState && item.Name.toUpperCase() === userInfo.VipLevelName" class="img1"
              :src="`../static/images/yt999/vip_html/${item.Name}_2.png`" />
            <img v-else class="img1" :src="`../static/images/yt999/vip_html/${item.Name}.png`" />
          </li>
        </ul>
      </div>
    </div> -->
    <!-- <div class="home-login container plain">
      <div class="content">
        <div class="content-text">
          <p>{{ $t("home_0041") }}</p>
        </div>
        <div class="content-button">
          <button>{{ $t("home_0059") }}</button>
        </div>
      </div>
    </div> -->
    <!-- <div class="home-fraudAware plain">
      <h2>{{ $t("home_0042") }}</h2>
      <div>
        <p>
          {{ $t("home_0043") }}
          <a href="#" target="_self">{{ $t("home_0044") }}</a>
          &nbsp;{{ $t("home_0046") }}
        </p>
      </div>
      <h2>{{ $t("home_0047") }}</h2>
      <div class="steps-lists">
        <div class="steps-img">
          <img src="../static/images/yt999/home/steps-path-01.png" alt="" />
          <a href="#">{{ $t("home_0073") }}</a>
        </div>
        <div class="steps-img">
          <img src="../static/images/yt999/home/steps-path-02.png" alt="" />
          <a href="#">{{ $t("home_0074") }}</a>
        </div>
        <div class="steps-img">
          <img src="../static/images/yt999/home/steps-path-03.png" alt="" />
          <a href="#">{{ $t("home_0075") }}</a>
        </div>
      </div>
      <div class="luluMoney-logo">
        <img src="../static/images/yt999/home/LuLuMoney.png" alt="" />
      </div>
      <div>
        <p>
          {{ $t("home_0048") }}
          <a href="#" target="_self">{{ $t("home_0049") }}</a>
          {{ $t("home_0050") }}
        </p>
      </div>
    </div> -->
    <!-- <div class="home-joinToday container plain">
      <div class="content">
        <div class="pulsLogo-img">
          <img src="../static/images/yt999/home/puls-logo.png" alt="" />
        </div>
        <div class="content-text">
          <p>
            <strong>{{ $t("home_0051") }}</strong>
            &nbsp;
            {{ $t("home_0052") }}
          </p>
        </div>
        <div class="content-button">
          <button>{{ $t("home_0053") }}</button>
        </div>
      </div>
    </div> -->
    <!-- <div class="home-testimonials container plain">
      <div>
        <h5>{{ $t("home_0054") }}</h5>
        <div class="testimonials-text">
          <p>"{{ $t("home_0055") }}"</p>
        </div>
      </div>
      <div>
        <h5>{{ $t("home_0056") }}</h5>
        <div class="testimonials-text">
          <p>"{{ $t("home_0057") }}"</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="home-helpMarketing plain">
      <p>{{ $t("home_0058") }}</p>
    </div> -->

    <!-- <div style="text-align: center; margin-top: 10px; display: none">
      <div class="containerhome03" style="z-index: 2; position: relative">
        <div class="card mb-4 shadow">
          <h5
            style="color: #fff; padding: 10px; background-color: rgb(51, 102, 204); border-top-left-radius: 10px; border-top-right-radius: 10px">
            Latest commission data list</h5>
          <h5 style="padding: 10px; font-size: 24px; font-weight: 600">Not yet open</h5>
          <h5 style="padding: 10px; font-size: 10px">Opening hours:10:00 AM - 10:00 PM（UTC+7）</h5>
        </div>
      </div>
    </div> -->
    <!-- <div style="width: 100%; margin-bottom: 11.5%"><img
        :src="`../static/images/yt999/home/protection_${$f7.params.currLang}.png`"
        :onerror="`this.onerror=''; src='../static/images/yt999/home/protection_enUS.png'`" style="width: 100%" />
    </div> -->
    <!-- Popup -->
    <f7-popup v-show="showMarqueePopup" animate backdrop id="popup-marquee" class="popup-marquee" @popup:opened="marqueePopupOpened()" @popup:close="marqueePopupClose">
      <f7-page>
        <f7-navbar :title="$t('home_0009')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block v-if="loadingSkeleton">
          <f7-card v-for="item in lampList" :key="item.Id" :content="item.Content" :footer="item.UpdateTs | time_local"></f7-card>
        </f7-block>
        <f7-block v-else>
          <f7-card v-for="item in lampList" :key="item.Id" :content="item.Content" :footer="item.UpdateTs | time_local"></f7-card>
        </f7-block>
      </f7-page>
    </f7-popup>
    <!-- <div class="dialog dialog-balance dialog-move" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent"> -->
    <div class="dialog dialog-balance" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t("home_0010") }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <f7-block-header class="block-header-totalBalance">
              <f7-row>
                <f7-col width="35">{{ $t("home_0011") }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{ totalBalance | formatCurrency }}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-refresh" @click="getAllBalanceList">{{ $t("home_0012") }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t("home_0013") }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{ walletBalance | formatCurrency }}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-recycling" @click="oneClickRecycling">{{ $t("home_0014") }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t("home_0015") }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{ paddingBalance | formatCurrency }}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30"></f7-col>
              </f7-row>
            </f7-block-header>
            <div class="block-content">
              <f7-row class="item-balance" v-for="item in gameBalanceList" :key="item.ProviderId" :class="`item-balance-${item.ProviderId}`">
                <f7-col width="35">{{ item.Name }}</f7-col>
                <f7-col width="35" class="col-balance">
                  <span v-if="totalWalletFlag">
                    <span v-if="item.IsMaintain">{{ $t("common_under_maintenance") }}</span>
                    <span v-else-if="!item.QuerySuccess">N/A</span>
                    <span v-else>{{ item.Balance | formatCurrency }}</span>
                  </span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-row class="btn-group">
                    <f7-col>
                      <f7-button raised fill class="btn-transfer" :class="{ 'btn-disabled': item.IsMaintain || !item.QuerySuccess }" @click="mainWalletToGame(item)">{{ $t("common_balance_transfer_in") }}</f7-button>
                    </f7-col>
                    <f7-col>
                      <f7-button raised fill class="btn-transferOut" :class="{ 'btn-disabled': item.IsMaintain || !item.QuerySuccess }" @click="gameWalletToMain(item)">{{ $t("common_balance_transfer_out") }}</f7-button>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="jumpPage(0, 'member-center/deposit')">{{ $t("home_deposit") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-withdrawal border-radius-right" @click="jumpPage(0, 'member-center/withdraw')">{{ $t("home_withdrawal") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <div class="dialog dialog-message" id="dialog-message">
      <div class="dialog-inner">
        <div class="dialog-title">
          <h3>{{ selectedMessage.Title }}</h3>
          <h5>{{ selectedMessage.CreateTs | time_local }}</h5>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <div class="block-content" v-html="selectedMessage.Content"></div>
            <f7-row no-gap class="row-toggle">
              <f7-col></f7-col>
              <f7-col class="text-right">
                <span class="text-14">{{ $t("common_set_readed") }}</span>
                <f7-toggle color="#2366cc" @change="toggleRead" :checked="checkRead"></f7-toggle>
              </f7-col>
            </f7-row>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-next border-radius-left" :class="userMessageList.length === 1 ? `button-disabled` : ``" @click="nextMessage($event)">{{ $t("common_next") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-close border-radius-right" @click="closeMessagePopup()">{{ $t("common_close") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <!-- 广告弹窗 -->
    <div class="dialog dialog-popupAd" id="dialog-popupAd">
      <div class="dialog-inner">
        <div class="dialog-title">
          <!-- <h3>{{ $t("home_news") }}</h3>
          <h5>{{ popupAdName }}</h5> -->
          <h3>{{ popupAdName }}</h3>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <f7-swiper :params="swiperPopupAd">
              <f7-swiper-slide v-for="(item, index) in popupAdlist" :key="index">
                <div class="block-content">
                  <img :src="`${popupAdImgUri + item.Url}`" width="100%" alt="" class="new-player-nav-border" />
                </div>
              </f7-swiper-slide>
            </f7-swiper>
            <!-- <div class="next-popup">
              {{ $t("common_next") }}
              <br />
              {{ nextPopupAdName }}
            </div> -->
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large next-button class="btn-next border-radius-left swiper-button-next">{{ $t("common_next") }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-close border-radius-right" @click="closeShowPopupAd()">{{ $t("common_close") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <!-- <f7-popup v-show="1===0" class="loadding-popup" :opened="loadingPopupOpened" tablet-fullscreen>
      <f7-page>
        <f7-block>
          <div class="loader-ring">
            <div class="loader-ring-light"></div>
            <div class="loader-ring-track"></div>
            <div class="loader-logo">
              <img :src="`../static/images/yt999/merchant/${$f7.params.fileName}/logo/loading.png`" />
            </div>
          </div>
          <div class="loadding-timeout" v-if="timeoutStatus">
            <p>{{ $t("home_network_slow") }}</p>
            <a href="javascript:location.reload();">{{ $t("home_try_refresh_page") }}?</a>
          </div>
          <div class="copy-right">
            <p>Copyright {{ $t("copyright", [$f7.params.name]) }} Inc.</p>
            <p>{{ app_version }}</p>
          </div>
        </f7-block>
      </f7-page>
    </f7-popup> -->

    <f7-fab position="right-bottom" @click="$f7.dialog.open('#dialog-activity')" v-draggable v-show="missionList.length">
      <div class="fab-contaienr">
        <img class="bg-image" src="../static/images/yt999/hongbao.png" />
        <!-- <img class="icon-close" src="../static/images/yt999/balace.png"> -->
      </div>
    </f7-fab>
  </f7-page>
</template>
<script>
import marquee from "./template/marquee";
import preloader from "./template/preloader";
import api from "../config/api";
import { getCookie, setStore, removeStore, getStore, setCookie } from "../config/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Draggable } from "draggable-vue-directive";
import { getInfo, getUserInfo, getCarouseladvertList, getLampList, getGameListp, getGameListByPId, getBalancesDetail, getGameEntry, getValidProviders, mainWalletToGame, gameWalletToMain, recyclingFunds, getUserNewsList, getPopupAd, alreadyRead, getActivityList, getPromotionDetail, getHotGameLists, getMemberBalance, getFisheListbyClass, getMissionList, getsimpleviplist, rateListget, memberFinancialProductbyHotget, gameRandomList, gamemediarandomlist } from "../axios/api";
import http from "../axios/http";
import common from "../service/common";

export default {
  components: {
    marquee,
    preloader,
  },
  directives: {
    Draggable,
  },
  data() {
    const self = this;
    return {
      baseInfo:{},
      currencySymbol: "",
      lastingDomain: "", //官网地址
      app_version: VERSION,
      promotionPageSize: 3,
      casinoPageSize: 5,
      egamePageSize: 5,
      fishPageSize: 5,
      dowloadLink: "",
      bannerList: [],
      bannerBaseUri: "",
      bannerShow: false,
      popupAdlist: [],
      popupAdImgUri: "",
      popupAdName: "",
      fishHomeImgUri: "",
      nextPopupAdName: "",
      popupAdIndex: 0,
      lampList: [], //以下关于游戏变量
      promotionList: [],
      loadingSkeleton: true, //加载消息弹窗特效标识
      skeletonTimeout: null, //消息弹窗timeout 对象
      memberBalance: 0, //钱包余额
      gameBalanceList: [], //以下是钱包变量
      simpleVipList_default: ["vip1", "vip2", "vip3", "vip4", "vip5", "vip6", "vip7", "vip8", "vip9", "vip10"],
      simpleVipList: [], //vip 列表变量
      totalWalletFlag: false,
      totalBalance: 0.0,
      walletBalance: 0.0,
      paddingBalance: 0.0,
      infiniteLoading: false, //加载条
      userMessageList: [], //个人消息
      selectedMessage: {},
      checkRead: false,
      messageNum: 0,
      isAppDevice: getStore("device"),
      getInfoStatus: false,
      getGameListStatus: true,
      timeoutStatus: false,
      popupOpened: false,
      mainSiteUrl: "",
      activityDetails: {},
      showLoading: true,
      swiperGameParams: {
        speed: 700,
        loop: false,
        direction: "vertical",
        lazy: {
          loadPrevNext: true,
        },
      },
      swiperParams: {
        speed: 700,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
        },
        lazy: {
          loadPrevNext: true,
        },
      },
      swiperNormalOption: {
        loop: false,
        spaceBetween: 12,
        slidesPerView: "auto",
        centeredSlides: true,
      },
      swiperParams2: {
        speed: 700, //滑动速度
        loop: true, //重复滚动
        autoplay: {
          disableOnInteraction: false,
          delay: 3000, // 几毫秒后自动播放
        },
      },
      swiperNewPlayerNav: {
        navigation: {
          nextEl: ".swiper-button-next",
        },
      },
      swiperPopupAd: {
        navigation: {
          nextEl: ".swiper-button-next",
        },
        on: {
          slideChangeTransitionEnd: function () {
            self.slideChangeTransition(this.activeIndex);
          },
        },
      },
      UniverseSetting: {}, //{0} 天地
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      defaultGameListAreaHeight: 509,
      footerHeigh: 70,
      gameCategory: {
        Egames: { name: this.$t("common_0006"), list: [] },
        Lottery: { name: this.$t("common_0011"), list: [] },
        SportsBook: { name: this.$t("common_0010"), list: [] },
        Casino: { name: this.$t("common_0007"), list: [] },
        Card: { name: this.$t("common_0008"), list: [] },
        Fish: { name: this.$t("common_0009"), list: [] },
      },
      autoOpenDialogQueue: [
        { id: "dialog-activity", show: false, check: false, isRead: false },
        { id: "dialog-newPlayerNav", show: false, check: true, isRead: false },
        { id: "dialog-message", show: false, check: false, isRead: false },
        { id: "dialog-popupAd", show: false, check: false, isRead: false },
      ],
      showMarqueePopup: true,
      liveRateList: [],
      liveRateIncludesList: [],
      liveRateListInterval: null,
      popupLiveRateOpened: false,
      showLiveRatePopup: true,
      memberFinancialList: [],
      gameRandomList1: [],
      gamemediarandomlist: [],
      luckyDrawBoxEntryOpen: false,
      mobileService: "",
    };
  },
  computed: {
    ...mapState(["currency", "currencyList", "missionList"]),
    ...mapGetters(["getLoginState", "userInfo", "getMerchantInfo", "getAllBalances", "firstLoadApp", "providerCodes"]),
    loadingPopupOpened() {
      return this.$f7router.currentRoute.name == "home" && this.firstLoadApp;
    },

    gameAreaHeight: function () {
      //-45 顶部
      //-10 消息和user info中间10间距
      //-90 user info高度
      //-6  game area margin 6
      //-70 底部菜单
      // SUM = -221
      //--------------------------------------------------------------
      //-125 轮播高度 暂时无用
      //var swiperHeight =110;
      //var clientWidth=document.documentElement.clientWidth;
      // if(clientWidth>=359&&clientWidth<479){
      //   swiperHeight=125;
      // }else if(clientWidth>=479&&clientWidth<539){
      //   swiperHeight=160;
      // }else if(clientWidth>=539&&clientWidth<639){
      //   swiperHeight=180;
      // }else{
      //   swiperHeight=320;
      // }
      //return (document.documentElement.clientHeight-221-swiperHeight)+'px';
      //--------------------------------------------------------------
      return document.documentElement.clientHeight - 45 - 125 - 30 - 60 - 70 - 6 + "px";
    },
    gameCategoryHeight: function () {
      //计算如上 另外再剪掉 more game 12
      //--------------------------------------------------------------
      //-125 轮播高度 暂时无用
      //var swiperHeight =110;
      // var clientWidth=document.documentElement.clientWidth;
      // if(clientWidth>=359&&clientWidth<479){
      //   swiperHeight=125;
      // }else if(clientWidth>=479&&clientWidth<539){
      //   swiperHeight=160;
      // }else if(clientWidth>=539&&clientWidth<639){
      //   swiperHeight=180;
      // }else{
      //   swiperHeight=320;
      // }
      //return (document.documentElement.clientHeight-221-swiperHeight-12)+'px';
      //--------------------------------------------------------------
      return document.documentElement.clientHeight - 221 - 12 + "px";
    },
  },
  watch: {
    autoOpenDialogQueue: {
      deep: true,
      handler(queue) {
        if (queue.every((item) => item.check)) {
          const index = queue.findIndex((item) => item.show && !item.isRead);
          if (!~index) {
            return;
          }
          const item = queue[index];
          this.$f7.dialog.open(`#${item.id}`);
        }
      },
    },
  },
  methods: {
    ...mapMutations(["SETMERCHAT_INFO", "SETALLBALANCES", "SETFIRSTLOADAPP", "SETFINANCIAL_DETAIL"]),
    ...mapActions(["getUserInfo"]),
    goToOrderSubmission() {
      this.$f7router.navigate("member-center/order-center/0/");
    },
    goluckybox() {
      this.$f7router.navigate("/luckybox/");
    },
    //敬请期待
    stayTuned() {
      const self = this;
      self.$f7.toast
        .create({
          text: this.$t("home_0093"),
          position: "center",
          closeTimeout: 1000,
          cssClass: "toast-login",
          on: {
            closed: function () {
              // router.navigate("/");
            },
          },
        })
        .open();
    },
    officialChannelLink(link){
      if (!this.isAppDevice) {
        window.open(link,"_blank");
      }else{
        window.location.href = link; 
      }
    },
    getImageURL(code, id) {
      return "../static/images/yt999/home/" + code + id + ".png";
    },
    getgameRandomList() {
      const self = this;
      gameRandomList().then((data) => {
        if (data.Code == "NoError") {
          self.gameRandomList1 = data.Data;
        }
      });
    },
    getgamemediarandomlist() {
      const self = this;
      gamemediarandomlist().then((data) => {
        if (data.Code == "NoError") {
          self.gamemediarandomlist = data.Data;
        }
      });
    },
    //获取最新理财产品
    getMemberfinancialproductbyhotget() {
      const self = this;
      memberFinancialProductbyHotget().then((data) => {
        if (data.Code == "NoError") {
          self.memberFinancialList = data.Data;
        }
      });
    },
    goToFinancialDetail(item) {
      let index = 0;
      if (item.FinancialProductType == "LowRiskProducts") {
        index = 0;
      } else if (item.FinancialProductType == "HighYieldProducts") {
        index = 1;
      } else if (item.FinancialProductType == "CharityDonationProducts") {
        index = 2;
      } else if (item.FinancialProductType == "ExclusiveFundProducts") {
        index = 3;
      }
      this.$f7router.navigate(`/member-center/financial-detail/${JSON.stringify({ index: index, financialid: item.FinancialProductID })}/`);
    },
    getSimpleVipList() {
      const self = this;
      getsimpleviplist().then((data) => {
        if (data.Code === "NoError") {
          self.simpleVipList = data.Data;
        }
      });
    },

    getInfo() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          self.getInfoStatus = true;
          const d = data.Data;
          self.baseInfo = d.BaseInfo;
          self.SETMERCHAT_INFO(d); //存储Vuex store merchantinfo
          setStore(`currencySymbol`, d.BaseInfo.DefaultCurrencySymbol);
          self.currencySymbol = d.BaseInfo.DefaultCurrencySymbol;
          self.lastingDomain = d.BaseInfo.LastingDomain;
          setStore(`ImagesURL`, d.ImagesURL);
          setStore("ShowTW", data.Data.BaseInfo.ShowTW);
          setStore(`TimeZome`, d.BaseInfo.TimeZome?d.BaseInfo.TimeZome:0);
          setStore(`AboutUsMediaUrl`, d.BaseInfo.AboutUsMediaUrl);
          if (d.GameImagesURL && d.GameImagesURL != "") {
            setStore(`GameImagesURL`, d.GameImagesURL); // 电子游艺游戏图片
          }
          self.bannerBaseUri = d.ImagesURL + window.configs.carouseladvertImg;
          self.fishHomeImgUri = d.GameImagesURL + (window.configs.fishHomeImg == null ? "static/images/fish/mobile/" : window.configs.fishHomeImg);
          // self.dowloadLink = d.BaseInfo.AppSite;
          self.dowloadLink = d.BaseInfo.AppDownloadSite;
          self.mobileService = d.BaseInfo.MobileService;
          self.UniverseSetting = d.UniverseSetting;
          self.getBannerList({ data: 2 });
          self.mainSiteUrl = d.BaseInfo.LastingDomain;
          self.luckyDrawBoxEntryOpen = d.BaseInfo.LuckyDrawBoxEntryOpen;
        }
      });
    },
    getLiveRateList() {
      const self = this;
      if (self.liveRateListInterval) {
        clearInterval(self.liveRateListInterval);
      }
      const params = {
        source_curr_code: "USD",
        curr_code: [],
      };
      const d = { data: JSON.stringify(params) };
      rateListget(d).then((res) => {
        if (res.Code === "NoError") {
          self.updateLiveRateList();
          const targetValues = ["EUR", "JPY", "AUD", "CAD"];
          self.liveRateList = res.Data;
          self.liveRateIncludesList = res.Data.filter((item) => {
            const targetUpperCase = item.Target.toUpperCase();
            return targetValues.includes(targetUpperCase);
          });
        }
      });
    },
    updateLiveRateList() {
      const self = this;
      if (self.liveRateListInterval) {
        clearInterval(self.liveRateListInterval);
      }
      // self.liveRateListInterval = setInterval(() => {
      //   self.getLiveRateList();
      // }, 30000);
    },
    getUserMemberBalance() {
      const self = this;
      self.memberBalance = null;
      getBalancesDetail().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          self.memberBalance = data.Data.Balance;
        }
      });
    },

    getSimpleVipList() {
      const self = this;
      getsimpleviplist().then((data) => {
        if (data.Code === "NoError") {
          self.simpleVipList = data.Data;
        }
      });
    },

    getBannerList(param) {
      const self = this;
      getCarouseladvertList(param).then((data) => {
        if (data.Code === "NoError") {
          self.bannerShow = true;
          self.bannerList = data.Data;
        }
      });
    },
    getLampList() {
      const self = this;
      getLampList().then((data) => {
        if (data.Code === "NoError") {
          self.lampList = data.Data;
        }
      });
    },
    getFishList() {},
    detail(item) {
      this.activityDetails = item;
      this.popupOpened = true;
    },
    goMainSite() {
      if (this.mainSiteUrl != "") {
        window.open(this.mainSiteUrl, "_blank");
      }
    },
    promptMaintain(content) {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false;
      //self.$f7.$('.dialog-backdrop').css('background-color', 'transparent');
      let promptMaintain = self.$f7.dialog
        .create({
          title: "",
          text: content,
          cssClass: "dialog-game",
        })
        .open();
      setTimeout(() => {
        self.$f7.params.dialog.closeByBackdropClick = true;
        //self.$f7.$('.dialog-backdrop').css('background-color', 'rgba(0, 0, 0, 0.74)');
        promptMaintain.close();
      }, 2000);
    },
    goGameList(id) {
      this.$f7router.navigate(`/game-list/${id}/`);
    },
    // goGameCategoryList(gameType) {
    //   this.$f7router.navigate(`/game-category-list/${gameType}/`);
    // },
    goFishGameList() {
      this.$f7router.navigate(`/fish-list/`);
    },
    showDeterminate(multiColor) {
      // 顶部 progressbar加载条
      const self = this;
      const app = self.$f7;
      if (self.infiniteLoading) return;
      self.infiniteLoading = true;
      if (multiColor) {
        app.progressbar.show("multi");
      } else {
        app.progressbar.show(app.theme === "md" ? "yellow" : "blue");
      }
    },
    async getValidProviders() {
      const self = this;
      let res = await http.post(api.validproviders);
      if (res.Code === "NoError") {
        self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
        self.$f7.dialog.open(".dialog-balance", true);
        self.gameBalanceList = res.Data;
        self.getAllBalanceList();
      }
    },
    getAllBalanceList() {
      const self = this;
      self.totalWalletFlag = false; //总钱包余额是否请求完成标识
      getBalancesDetail().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          self.totalWalletFlag = true;
          self.SETALLBALANCES(res); //存储Vuex store balance
          self.totalBalance = data.Data.Balance;
          self.paddingBalance = data.Data.FreezeAmount;
          self.walletBalance = data.Data.Balance;
        }
      });
    },
    getMissionList() {
      // const self = this;
      // getMissionList().then((data) => {
      //   const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-activity");
      //   if (data.Code === "NoError" && data.Data) {
      //     self.$store.commit("SET_MISSION_LIST", data.Data);
      //     const unShow = /true/.test(getCookie("unShow"));
      //     if (Array.isArray(data.Data) && data.Data.length && !unShow) {
      //       item.show = true;
      //     } else {
      //       item.show = false;
      //     }
      //     item.check = true;
      //   }
      // });
    },
    jumpPage(type, param) {
      const self = this;
      const router = self.$f7router;
      if (type === 0) {
        //balance popup 弹窗
        self.$f7.dialog.close(".dialog-balance");
        router.navigate(`/${param}/`);
      }
    },
    oneClickRecycling(event) {
      const self = this;
      if (!self.totalWalletFlag) return;
      self.$f7.$(".btn-recycling").addClass("progressbar");
      self.$f7.$(".btn-recycling").text(this.$t("common_game_balance_transfer_back"));
      recyclingFunds().then((data) => {
        self.$f7.$(".btn-recycling").removeClass("progressbar");
        self.$f7.$(".btn-recycling").text(this.$t("common_one_key_transfer_game_balance_back"));
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    mainWalletToGame(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !self.walletBalance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: self.walletBalance,
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass("progressbar");
      mainWalletToGame(d).then((data) => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass("progressbar");
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    gameWalletToMain(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !item.Balance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: item.Balance,
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass("progressbar");
      gameWalletToMain(d).then((data) => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass("progressbar");
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    marqueePopupOpened() {
      var self = this;
      self.showMarqueePopup = true;
      self.skeletonTimeout = setTimeout(() => {
        self.loadingSkeleton = false;
      }, 3000);
    },
    marqueePopupClose() {
      clearTimeout(this.skeletonTimeout);
      this.loadingSkeleton = true;
      this.showMarqueePopup = false;
    },
    openLiveRatePopup() {
      this.showLiveRatePopup = true;
    },
    closeLiveRatePopup() {
      this.popupLiveRateOpened = false;
      this.showLiveRatePopup = false;
    },
    openChangeLanguagePopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-language", true);
    },
    openChangeCurrencyPopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-currency", true);
    },
    openBalancePopup() {
      const self = this;
      if (self.getLoginState) {
        self.getValidProviders();
      } else {
        this.$f7router.navigate("/login/");
      }
    },
    closeDialogBalance() {
      this.$f7.dialog.close(".dialog-balance");
    },
    balanceOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    balanceCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    getUserNewsListEvent() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Status: 2,
          BeginDate: 0,
          EndDate: 0,
          PageNow: 1,
          PageSize: "100",
        }),
      };
      const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-message");
      getUserNewsList(param).then((data) => {
        if (data.Code === "NoError" && data.Data.Total > 0) {
          self.userMessageList = data.Data.Data;
          self.selectedMessage = self.userMessageList[self.messageNum];
          self.$f7.params.dialog.closeByBackdropClick = false; //点击背景不关闭
          item.show = true;
          self.$f7.dialog.open(".dialog-message");
        }

        item.check = true;
      });
    },
    showNewPlayerNav() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false; //点击背景不关闭
      // self.$f7.dialog.open('.dialog-newPlayerNav');
      const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-newPlayerNav");
      item.check = true;
      item.show = true;
      removeStore("showNewPlayerNav");
    },
    showNewPopupAd() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Type: 4,
        }),
      };
      getPopupAd(param).then((data) => {
        const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-popupAd");
        item.check = true;
        if (data.Code === "NoError" && data.Data.Status && data.Data.CarouselAdvert.length > 0) {
          self.popupAdlist = data.Data.CarouselAdvert;
          self.popupAdName = self.popupAdlist[0].Name;
          if (self.popupAdlist.length > 1) {
            self.nextPopupAdName = self.popupAdlist[1].Name;
          } else {
            self.nextPopupAdName = this.$t("common_have_no_next");
          }
          self.popupAdImgUri = getStore(`ImagesURL`) + window.configs.popupAdImg;
          self.$f7.params.dialog.closeByBackdropClick = false; //点击背景不关闭
          self.$f7.dialog.open(".dialog-popupAd");
          item.show = true;
          sessionStorage.setItem("mainPopup", 1);
        }
      });
    },
    messageOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    messageCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    toggleRead() {
      if (this.checkRead) {
        this.checkRead = false;
      } else {
        this.checkRead = true;
      }
    },
    nextMessage(event) {
      const self = this;
      self.messageNum++;
      if (self.messageNum < self.userMessageList.length) {
        if (self.checkRead) {
          alreadyRead({ data: self.selectedMessage.Id }).then((data) => {});
        }
        self.selectedMessage = self.userMessageList[self.messageNum];
        if (self.messageNum === self.userMessageList.length - 1) {
          self.$f7.$(event.currentTarget).addClass("button-disabled");
        }
      }
    },
    slideChangeTransition(index) {
      const self = this;
      self.popupAdName = self.popupAdlist[index].Name;
      if (self.popupAdlist.length - 1 > index) {
        self.nextPopupAdName = self.popupAdlist[index + 1].Name;
      } else {
        self.nextPopupAdName = this.$t("common_have_no_next");
      }
    },
    getPromotionList() {
      // const self = this;
      // const params = { data: JSON.stringify({ Type: 0 }) };
      // getActivityList(params).then((data) => {
      //   if (data.Code === "NoError") {
      //     self.promotionList = [];
      //     var current_flag = 0;
      //     var arr = [];
      //     for (let index = 0; index < data.Data.length; index++) {
      //       const element = data.Data[index];
      //       if (element.Type == 5) {
      //         arr[arr.length] = element;
      //         current_flag++;
      //         if (current_flag == self.promotionPageSize) {
      //           self.promotionList[self.promotionList.length] = arr;
      //           current_flag = 0;
      //           arr = [];
      //         }
      //       }
      //     }
      //   }
      // });
    },
    gohome() {
      window.open(this.lastingDomain, "_blank");
    },
    closeMessagePopup() {
      const self = this;
      if (self.checkRead) {
        const param = { data: self.selectedMessage.Id };
        alreadyRead(param).then((data) => {});
      }
      self.$f7.dialog.close("#dialog-message");
      const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-message");
      item.isRead = true;
    },
    closeShowNewPlayerNav() {
      const self = this;
      self.$f7.dialog.close(".dialog-newPlayerNav");
      const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-newPlayerNav");
      item.isRead = true;
    },
    closeShowPopupAd() {
      const self = this;
      self.$f7.dialog.close(".dialog-popupAd");
      const item = this.autoOpenDialogQueue.find((item) => item.id == "dialog-popupAd");
      item.isRead = true;
    },
    loadingStatusEvent() {
      const self = this;
      const start = new Date();
      let timeoutShowReload = setInterval(() => {
        const end = new Date();
        let time = (end - start) / 1000;
        if (time >= 10) {
          self.timeoutStatus = true;
          clearInterval(timeoutShowReload);
        }
      }, 1000);
      let loadingInterval = setInterval(() => {
        const end = new Date();
        let time = (end - start) / 1000;
        if (time > 3) {
          if (this.firstLoadApp && this.bannerShow && this.getInfoStatus) {
            //this.loadingPopupOpened = false;
            this.showLoading = false;
            this.$f7.popup.close(".loadding-popup");
            this.SETFIRSTLOADAPP(false);
            clearInterval(loadingInterval);
            clearInterval(timeoutShowReload);
          }
        }
      }, 1000);
    },
    onPageInit(e, page) {
      const self = this;
      if (self.liveRateListInterval) {
        clearInterval(self.liveRateListInterval);
      }
      //if (page.router.currentRoute.name == "home") {
      self.$f7ready((f7) => {
        if (self.firstLoadApp) {
          self.loadingStatusEvent();
        }
        //self.showDeterminate(false);
        self.getInfo();
        self.getLampList();
        // self.getGameList();
        self.getFishList();
        self.getPromotionList();
        self.getMissionList();
        self.getSimpleVipList();
        self.getMemberfinancialproductbyhotget();
        // self.getgameRandomList();
        self.getgamemediarandomlist();
        // self.getLiveRateList();
        if (getStore("zz") != "") {
          this.getUserInfo();
          this.getUserMemberBalance();
        }
        //广告弹窗
        if (!sessionStorage.getItem("mainPopup")) {
          self.showNewPopupAd();
        }

        //新手引导
        // if (getStore('showNewPlayerNav')) {
        //   self.showNewPlayerNav();
        // }
        //if (getCookie("zz") && page.router.currentRoute.name == "home") {
        if (getCookie("zz")) {
          self.getUserNewsListEvent();
        } else {
          const item = self.autoOpenDialogQueue.find((item) => item.id == "dialog-message");
          item.check = true;
        }
        self.$f7.on("onCloseDialog", (targetId) => {
          const item = self.autoOpenDialogQueue.find((item) => item.id == targetId);
          if (item) {
            item.isRead = true;
          }
        });
      });
      //}
    },
    onPageBeforeIn() {
      this.$f7.emit("myCustomEvent", "home");
      removeStore("mc-tab-active");
    },
    onPageBeforeunmount() {
      clearInterval(self.liveRateListInterval);
    },
    goUniverse(type) {
      const self = this;
      if (type === "") {
        let promtLoadingGame = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_keep_waiting"),
            cssClass: "dialog-preloadgame",
          })
          .open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        //self.$f7.$('.dialog-backdrop').css('background-color', 'transparent');
        setTimeout(() => {
          promtLoadingGame.close();
        }, 1500);
        return;
      }
      var mreturn = "";
      if (!getStore("device")) {
        mreturn = btoa(window.location.protocol + "//" + window.location.host);
      } else {
        const d = localStorage.getItem("d") && localStorage.getItem("d") != "" ? localStorage.getItem("d") : "";
        const appVer = localStorage.getItem("appVer") && localStorage.getItem("appVer") != "" ? localStorage.getItem("appVer") : "";
        const appHref = window.location.protocol + "//" + window.location.host + "/#/app/?d=" + d + "&device=App&appVer=" + appVer;
        mreturn = btoa(appHref);
      }
      const regist = btoa(window.location.protocol + "//" + window.location.host + "/#/register/");
      const href = `${type}?platform=mobile&system=${self.UniverseSetting.SystemCode}&merchant=${window.configs.merchantAlias}&token=${getCookie("zz") ? getCookie("zz") : ""}&return=${mreturn}&regist=${regist}`;
      if (!getStore("device")) {
        this.common.redirectURL(href);
      } else {
        let promtLoadingGame = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("home_TV_loading", [this.$f7.params.shortName]),
            cssClass: "dialog-preloadgame",
          })
          .open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        //self.$f7.$('.dialog-backdrop').css('background-color', 'transparent');
        setTimeout(() => {
          promtLoadingGame.close();
        }, 1500);
        window.location.href = href;
      }
    },
    activeTab(e) {},
    setOffsetTop() {},
  },
  created() {
    const d = this.$f7route.query.d;
    const r = this.$f7route.query.r;
    setStore("d", d);
    setStore("r", r);
  },
  mounted() {
    window.addEventListener("resize", function () {
      //-45 顶部
      //-10 消息和user info中间10间距
      //-90 user info高度
      //-6  game area margin 6
      //-70 底部菜单
      // SUM = -221
      this.clientHeight = document.body.clientHeight;
      this.clientWidth = document.body.clientWidth;
    });
  },
  beforeDestroy() {
    if (self.liveRateListInterval) {
      clearInterval(self.liveRateListInterval);
    }
    this.closeShowPopupAd();
  },
  updated() {
    this.$f7.lazy.create(this.$el);
    //this.$f7.swiper.create(this.$el);
  },
};
</script>

<style lang="less" scoped>
.activity-detail-popup {
  .block {
    margin: var(--f7-margin-size-0);
    padding: var(--f7-padding-size-0);
  }
}

.next-popup {
  color: #000;
  text-align: center;
  margin-bottom: 10px;
}

.new-player-nav-border {
  border: 1px solid #4e4e4e;
}

.martop {
  margin-top: -3px !important;
}

.referr {
  position: absolute;
  top: 18px;
  // right: 8px;
  padding-left: 10px;

  img {
    vertical-align: middle;
  }
}

.incenter {
  margin: 0 auto;
  display: inline-block;
}

.home-liveRates {
  margin: 0px 16px 0px 16px;

  .title-center {
    text-align: center;
  }

  .liveRates-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
    height: 34px;
    position: relative;

    .title {
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    .more {
      background-image: url("../static/images/yt999/home/icon_more.png");
      width: 34px;
      height: 34px;
      background-size: cover;

      .liveRate-popup {
        background: transparent;
      }
    }
  }

  .liveRates-title::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0px;
    transform: translateX(-50%);
    width: 3px;
    height: 45%;
    background-color: var(--f7-THEME-color);
  }

  .liveRates-content {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding: 16px 0px 16px 0px;

    .liveRates-name {
      font-size: 12px;
      font-weight: 600;
      margin: 0px 16px 16px 16px;
    }

    .liveRate-lists {
      overflow: hidden;

      .rate-item {
        border-bottom: 1px solid #efefef;
        margin-bottom: 12px;
        padding: 0px 0px 12px 16px;

        .country-img {
          width: 24px;
        }

        .country-name {
          font-size: 12px;
          color: #666666;
          line-height: 28px;
        }

        .increase {
          line-height: 28px;
          text-align: center;

          img {
            width: 14px;
          }
        }

        .ask {
          font: inter;
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          padding-left: 40px;
          line-height: 28px;
        }
      }
    }
  }

  .icon-country {
    background-image: url("../static/images/yt999/home/country-map.png");
    background-repeat: no-repeat;
    width: 26px;
    height: 18px;
    display: inline-block;
    background-position-y: 0px;
    background-size: 324px auto;
    margin: 3px 0px 3px 0px;

    &.icon-USD {
      background-position-y: -86px;
      background-position-x: 0px;
    }

    &.icon-AUD {
      background-position-y: 0px;
      background-position-x: 0px;
    }

    &.icon-BRL {
      background-position-y: 0px;
      background-position-x: -37px;
    }

    &.icon-CAD {
      background-position-y: 0px;
      background-position-x: -74px;
    }

    &.icon-CHF {
      background-position-y: 0px;
      background-position-x: -112px;
    }

    &.icon-CNY {
      background-position-y: 0px;
      background-position-x: -149px;
    }

    &.icon-EUR {
      background-position-y: 0px;
      background-position-x: -185px;
    }

    &.icon-GBP {
      background-position-y: 0px;
      background-position-x: -224px;
    }

    &.icon-HKD {
      background-position-y: 0px;
      background-position-x: -261px;
    }

    &.icon-INR {
      background-position-y: 0px;
      background-position-x: -299px;
    }

    &.icon-JPY {
      background-position-y: -28px;
      background-position-x: 0px;
    }

    &.icon-KRW {
      background-position-y: -28px;
      background-position-x: -37px;
    }

    &.icon-MXN {
      background-position-y: -28px;
      background-position-x: -74px;
    }

    &.icon-NOK {
      background-position-y: -28px;
      background-position-x: -112px;
    }

    &.icon-NZD {
      background-position-y: -28px;
      background-position-x: -149px;
    }

    &.icon-RUB {
      background-position-y: -28px;
      background-position-x: -185px;
    }

    &.icon-SEK {
      background-position-y: -28px;
      background-position-x: -224px;
    }

    &.icon-SGD {
      background-position-y: -28px;
      background-position-x: -261px;
    }

    &.icon-TRY {
      background-position-y: -28px;
      background-position-x: -299px;
    }

    &.icon-ZAR {
      background-position-y: -57px;
      background-position-x: 0px;
    }
  }
}

.vipgo {
  margin: 5px 10px;
  background-size: cover;
  border-radius: 5px;
  background-image: url("../static/images/yt999/music/viplevel.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 123px;
  .logo {
    width: 28%;
    text-align: center;
    padding-top: 15px;
    margin: 0 10px;
  }
  .text1 {
    margin-top: 10px;
    font-size: 16px;
    color: var(--f7-THEME-color);
    font-weight: bold;
    padding-right: 5px;
  }

  .text2 {
    margin-top: 5px;
    padding-right: 5px;
    font-size: 12px;
    color: #000;
  }

  .red-button {
    display: inline-block;
    margin: 10px 0;
    color: #ffffff;
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 14px;
    text-align: center;
    height: 12px;
    line-height: 12px;
    background: linear-gradient(to bottom, var(--f7-THEME-color2), var(--f7-THEME-color));
  }
}

.comein{
  margin: 5px 10px 0 10px;
  position: relative;
  .text{
    font-size: 12px;
    position: absolute;
    top: 23px;
    left: 32%;
    p{
     margin: 5px 0; 
    //  color: black;
    }
    p:nth-child(1){
      font-size: 16px;
      color: rgba(177, 129, 81, 1);
      font-weight: bold;
    }
    p:nth-child(2){
      color: black;
    }
  }
}
.comein2{
  margin: 10px 10px 0 10px;
  position: relative;
  .text{
    font-size: 12px;
    position: absolute;
    top: 15px;
    left: 32%;
    p{
     margin: 5px 0; 
    //  color: black;
    }

    p:nth-child(1){
      color: black;
      padding-right: 5px;
    }
  }
  .red-button {
    display: inline-block;
    margin: 8px 0px;
    color: #ffffff;
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 14px;
    text-align: center;
    height: 12px;
    line-height: 12px;
    background: rgba(61, 153, 220, 1);
  }
}

.home-download {
  .content {
    padding: 16px;
    text-align: center;

    .content-text {
      p {
        font: 300 2rem/2.5rem Roboto, Helvetica Neue, sans-serif;
        font-size: 22px;
      }
    }

    .downloadLink {
      font: 400 1rem/1.5rem Roboto, Helvetica Neue, sans-serif;

      a {
        color: #000;
        text-decoration: underline;
      }
    }

    .content-text2 {
      font: 300 2rem/2.5rem Roboto, Helvetica Neue, sans-serif;
      font-size: 20px;
      margin-top: 32px;
    }

    .content-text3 {
      font: 300 2rem/2.5rem Roboto, Helvetica Neue, sans-serif;
      font-size: 12px;
      letter-spacing: 0.02em;
      line-height: 24px;
    }

    .content-text4 {
      font: 300 14px/1.25rem Roboto, Helvetica Neue, sans-serif;
      letter-spacing: 0.02em;
      line-height: 24px;
      font-weight: 400;
    }

    .downloadLink-img {
      a {
        background-size: 158px 47px;
        display: inline-block;
        cursor: pointer;
        margin: 20px 0;
        height: 47px;
        width: 158px;
      }

      .app-store {
        background-image: url("../static/images/yt999/home/google-play.png");
      }

      .google-play {
        background-image: url("../static/images/yt999/home/app-store.png");
      }
    }
  }
}

.home-login {
  background-image: url("../static/images/yt999/home/Invite-friend.png");
  background-size: cover;

  .content {
    width: 100%;
    height: 372px;
    position: relative;

    .content-text {
      padding: 16px;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      p {
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        padding: 0px 16px;
      }
    }

    .content-button {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translateX(-50%);

      button {
        width: 120px;
        padding: 13px;
        border-radius: 20px;
        background-color: red;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.home-fraudAware {
  text-align: center;

  h2 {
    font: 300 2rem/2.5rem Roboto, Helvetica Neue, sans-serif;
    padding: 0px 20px;
    font-size: 20px !important;
  }

  p {
    font: 300 12px/1.25rem Roboto, Helvetica Neue, sans-serif;
    padding: 0px 20px;

    a {
      color: #000;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .steps-lists {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    padding: 1.875rem 1.25rem;

    .steps-img {
      box-shadow: 0 10px 30px #d0d0d0;
      position: relative;
      margin-bottom: 2.5rem;
      background-color: #fff;
      padding: 1.875rem 1.25rem;
      display: grid;
      justify-items: center;
    }

    a {
      margin: 30px 0px;
      font: 600 12px/1.25rem Roboto, Helvetica Neue, sans-serif;
      text-decoration: underline;
      color: #4e453f;
    }
  }

  .luluMoney-logo {
    margin: 60px 0;

    img {
      width: 300px;
    }
  }
}

.home-joinToday {
  background-image: url("../static/images/yt999/home/photo-bg.png");
  background-size: cover;

  .content {
    width: 100%;
    height: 314px;
    position: relative;

    .pulsLogo-img {
      position: absolute;
      left: 6%;
      top: 6%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .content-text {
      padding: 16px 16px;
      position: absolute;
      top: 30%;

      p {
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .content-button {
      position: absolute;
      top: 82%;
      left: 50%;
      transform: translateX(-50%);

      button {
        width: 120px;
        padding: 13px;
        border-radius: 20px;
        background-color: red;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.home-testimonials {
  text-align: center;

  .testimonials-text {
    padding: 0px 64px;

    p {
      font: 300 14px/1.25rem Roboto, Helvetica Neue, sans-serif;
      font-size: 16px;
    }
  }
}

.home-helpMarketing {
  height: 182px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 38px;

  p {
    font: 300 1rem/1.5rem Roboto, Helvetica Neue, sans-serif;
    font-size: 12px;
  }
}

/* home 01 */
.home-trusted {
  text-align: center;
  // margin-top: 20px;

  .h1 {
    // border-bottom: 1px solid #e5e5e5;
    padding-top: 5px;
    line-height: 20px;
    color: white;
  }

  .h2 {
    display: flex;
    // background: #d68767;
    color: #fff0ea;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    border-radius: 15px;
    height: 25px;
    padding: 0 10px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: 1px solid #f9f9f9;
    i {
      font-size: 22px;
      padding-right: 4px;
    }
  }
}

.home-trusted ul li {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.home-trusted ul li {
  position: relative;
  width: 60%;
  min-height: 100px;
  margin-right: 4%;
  padding-left: 35%;
  // background-image: url(../static/images/yt999/home/trusted_1.png);
  // background-size: 1200px auto;
  background-size: cover;
  font-size: 12px;
  margin-top: 10px;
  margin: 0 3.5% 15px 0;
  //padding: 0 0 0 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plain {
  ul {
    line-height: 22px;
    box-sizing: border-box;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .img-title {
    position: absolute;
    left: 2%;
    top: 0;
    height: 100px;
  }

  box-sizing: border-box;
  width: 100%;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;

  strong {
    font-weight: bolder;
  }

  .heading {
    width: 90%;
    background-image: url("../static/images/yt999/music/heading.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 14px;
    line-height: 30px;
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
    text-align: left;
    color: #71391c;
    height: 90px;
  }

  ul,
  li {
    padding: 0;
    margin: 10px;
    list-style: none;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 20px;
  }
}

/* home 01 */

/* home 02 */
#checkout-cb-explainer {
  display: flex;
  background-color: #525255;
  color: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 15%);
  margin-bottom: 30px;
  height: 165px;
}

#checkout-cb-explainer .title {
  width: 140px;
  font-size: 18px !important;
  line-height: 20px !important;
  padding-right: 0;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#checkout-cb-explainer .title .wrap:before {
  display: block;
  content: "";
  width: 50px;
  height: 26px;
  margin: 2px 0;
  background: url(../static/images/yt999/home/ob-cashback-lettermark.svg) left center / auto 26px no-repeat;
}

#checkout-cb-explainer .title .wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#checkout-cb-explainer .title .wrap > * {
  height: 22px;
}

#checkout-cb-explainer .scrolling {
  width: 50vw;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 12px 10px 10px;
  align-self: center;
  position: relative;
  margin-top: 0px !important;
}

#checkout-cb-explainer .point {
  /*width: 105%!important;*/
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0px;
  /*padding-right: 5px;*/
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  text-align: left;
}

#checkout-cb-explainer .title {
  width: 135px;
  font-size: 22px;
  line-height: 20px;
  padding-right: 0;
  color: #36c;
  background-color: #fff;
  margin-right: 10px;
  padding: 30px 15px;
  position: relative;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  z-index: 1;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
}

.plain {
  width: 100%;
  /*padding-right: 15px;*/
  /*padding-left: 15px;*/
  margin-right: auto;
  margin-left: auto;
}

.ui-container {
  overflow: hidden;
  /*width: 200px;*/
  /*height: 50px;*/
}

.ui-slider {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  transition: margin 0.5s;
  /* 添加过渡效果 */
}

#checkout-cb-explainer .point-title {
  font-weight: 700;
  color: #ffc56a;
  font-size: 22px;
  margin-bottom: 0;
}

#checkout-cb-explainer .title:after {
  position: absolute;
  top: -15px;
  right: -10px;
  bottom: -15px;
  width: 30px;
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 15px, #fff 15px, #fff 51%, rgba(0, 0, 0, 0) 51%), linear-gradient(to top, rgba(0, 0, 0, 0) 15px, #fff 15px, #fff 51%, rgba(0, 0, 0, 0) 51%);
  border-radius: 0 180% 180% 0;
  z-index: -1;
}

/* home 02 */

/* home 03 */

.containerhome03 {
  width: 100%;
  // padding-right: 15px;
  // padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.containerhome03 div {
  border-style: hidden !important;
  box-sizing: unset;
}

.shadow {
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1) !important;
  -ms-box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1) !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.card {
  // border-radius: 10px;
  // border: 0;
  // position: relative;
  // display: -ms-flexbox;
  // display: flex;
  // -ms-flex-direction: column;
  // flex-direction: column;
  // min-width: 0;
  // word-wrap: break-word;
  // background-color: #fff;
  // background-clip: border-box;
  // border: 1px solid rgba(0,0,0,.125);
  // border-radius: 0.25rem;

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
  }
}

/* home 03 */

/* vip */
.container {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .container_vip_title {
    width: 100%;
    display: block;
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .vip_div {
    text-align: center;
    margin-top: 10px;

    .vip {
      text-align: center;
      width: 100%;

      .pic {
        width: 50%;
        float: left;
        height: 10%;

        .s1 {
          float: left;
          position: absolute;
          color: #ffffff;
          font-size: 8px;
          text-align: left;
          width: 65px;
          margin-left: 3px;
          margin-top: 8.5%;
        }

        .s2 {
          position: absolute;
          color: #ffffff;
          font-size: 8px;
          text-align: left;
          width: 70px;
          margin-left: 3px;
          margin-top: 12%;
        }

        .s3 {
          float: left;
          position: absolute;
          color: #ffffff;
          font-size: 8px;
          text-align: left;
          width: 130px;
          margin-left: 3px;
          margin-top: 15.5%;
        }

        .s4 {
          position: absolute;
          color: #ffffff;
          width: 13%;
          margin-left: 31%;
          margin-top: 8%;
          font-size: 15px;
          text-align: center;
        }

        .s4-active {
          position: absolute;
          color: #ffdd8d;
          width: 13%;
          margin-left: 31%;
          margin-top: 8%;
          font-size: 15px;
          text-align: center;
        }

        .img1 {
          border-radius: 10px;
          margin-top: -3px;
          margin-right: 2px;
          width: 95%;
        }
      }
    }
  }

  .vip_div ul li:nth-of-type(1) {
    text-align: right;
  }

  .vip_div ul li:nth-of-type(2) {
    text-align: left;
  }

  .vip_div ul li:nth-of-type(3) {
    text-align: right;
  }

  .vip_div ul li:nth-of-type(4) {
    text-align: left;
  }

  .vip_div ul li:nth-of-type(5) {
    text-align: right;
  }

  .vip_div ul li:nth-of-type(6) {
    text-align: left;
  }

  .vip_div ul li:nth-of-type(7) {
    text-align: right;
  }

  .vip_div ul li:nth-of-type(8) {
    text-align: left;
  }

  .vip_div ul li:nth-of-type(9) {
    text-align: right;
  }

  .vip_div ul li:nth-of-type(10) {
    text-align: left;
  }
}

/* vip */

.tabs-games {
  padding-bottom: 88px;

  /deep/ .van-tabs__wrap {
    box-shadow: 0px 0px 8px 0px rgba(104, 0, 255, 0.18);

    .van-tabs__nav {
    }
  }

  /deep/ .van-tab__text {
    display: flex;
    position: relative;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 25px;
    padding: 4px 18px 4px 38px;
    height: 20px;
    font-size: 12px;
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1);

    img {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 32px;
    }
  }

  /deep/ .van-tab--active {
    &.title-SportsBook .van-tab__text {
      background: linear-gradient(45deg, #4fe387, #6ce164);
      color: #fff;
    }

    &.title-Casino .van-tab__text {
      background: linear-gradient(45deg, #56c2f2, #3cacf6);
      color: #fff;
    }

    &.title-Egames .van-tab__text {
      background: linear-gradient(45deg, #f8ae4f, #f79b2b);
      color: #fff;
    }

    &.title-Lottery .van-tab__text {
      background: linear-gradient(45deg, #f84fe6, #f72fc8);
      color: #fff;
    }

    &.title-Card .van-tab__text {
      background: linear-gradient(45deg, #efb190, #dd8864);
      color: #fff;
    }

    &.title-Fish .van-tab__text {
      background: linear-gradient(45deg, #3b7aee, #2033af);
      color: #fff;
    }
  }

  .tab-content {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 5px;

    .game-item {
      position: relative;
      width: 33.333333%;
      text-align: center;
      margin-bottom: 5px;
      box-sizing: border-box;

      .bg {
        width: calc(100% - 10px);
      }

      .item-inner {
        position: absolute;
        bottom: 10px;
        left: 15px;
        right: 15px;

        color: #fff;

        .logo {
          line-height: 0;
          height: 28px;

          text-align: left;

          img {
            height: 100%;
          }
        }

        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }

    .fish-item {
      margin-bottom: 10px;

      img {
        border-radius: 8px 8px 0 0;
        height: calc(100% - 44px);
        min-height: 100px;
        background: #f3f3f3;
      }

      .item-inner {
        margin: -5px auto 0;
        padding: 3px 0;
        font-weight: 500;
        position: unset;
        background-color: #569add;
        width: calc(100% - 10px);
        border-radius: 0 0 8px 8px;
        min-height: 40px;
      }
    }
  }
}

.fab-contaienr {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: var(--f7-fab-size);
  height: var(--f7-fab-size);

  .bg-image {
    width: 50px;
    height: 50px;
  }

  .icon-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
  }
}
.music-all {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建三个等宽的列 */
  grid-gap: 0 6px;
  margin-bottom: 10px;
}
.music-img {
  margin: 2px 0px;
  text-align: center;
  .music-logo {
    img {
      border-radius: 8px;
      border: 1px solid #d9d6d6;
    }
  }
  .music-img {
    width: 100% !important;
  }
  .music-info {
    div {
      width: 100px;
      height: 20px;
      // white-space: nowrap;    /* 禁止换行 */
      // overflow: hidden;       /* 隐藏超出部分 */
      // text-overflow: ellipsis; /* 显示省略号 */
    }
    .music-name {
      font-size: 11px;
      height: 33px;
    }
    .music-author {
      color: #818181;
      font-size: 12px;
    }
  }
}

.product-container {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid var(--f7-THEME-color);
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  padding: 10px 0;

  p {
    margin: 0;
    /* 去除段落的默认边距 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
  }

  .bold-black {
    font-weight: bold;
    /* 加粗 */
    color: #000000;
    /* 黑色字体 */
  }

  .red-text {
    font-weight: 800;
    color: #ff0000;
    /* 红色字体 */
  }

  .grey-text {
    color: #808080;
    /* 灰色字体 */
    font-size: 12px;
    /* 小字体 */
  }

  .black-text {
    color: #000000;
    max-height: 40px;
    /* 黑色字体 */
  }

  .button {
    background-color: var(--f7-THEME-color);
    color: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    margin: 0px auto;
    display: block;
    height: 41px;
    line-height: 14px;
    width: 100px;
    text-transform: none;
    font-size: 12px;
    white-space: normal !important;
  }
}

.chanping {
  width: 48%;
  margin-right: 2%;
}

.ios .page-home .main-suggest-content .swiper-slide {
  justify-content: left;
}
.ios .page-home .main-suggest-content .swiper-slide img {
  // width: 70px;
}

.main-category .menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  /* 设置网格项之间的间距 */

  img {
    display: block;
    margin: 0 auto;
    height: 35px;
    margin-bottom: 7px;
  }

  span {
    display: block;
    text-align: center;
    font-size: 13px;
  }
  .news {
    position: relative;
  }
  .messageNum {
    position: absolute;
    top: 0px;
    left: 30%;
    height: 16px;
    width: 16px;
    line-height: 16px;
    color: white;
    background: var(--f7-THEME-color);
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
  }
}

.ios .page-home .main-suggest-content {
  position: relative;
  left: -12px;
}
.dialog-message .dialog-inner .dialog-text .block-message .row-toggle span {
  color: #000;
}
::v-deep.toggle input[type="checkbox"]:checked + .toggle-icon {
  background: var(--f7-THEME-color);
}
</style>
