<template>
  <f7-page no-toolbar no-swipeback name="deposit" class="page-deposit">
    <f7-navbar :title="$t('app_0012')" back-link :no-hariline="true">
      <!-- <f7-subnavbar>
        <f7-segmented raised>
          <f7-button tab-link="#tab-online-recharge" tab-link-active>在线充值</f7-button>
          <f7-button tab-link="#tab-offline-recharge">线下充值</f7-button>
          <f7-button tab-link="#tab-bank-transfer">银行转账</f7-button>
        </f7-segmented>
      </f7-subnavbar> -->
    </f7-navbar>
    <div class="buttons-tab buttons-deposit">
      <f7-link tab-link="#tab-online-recharge" tab-link-active :text="$t('deposit_0001')"></f7-link>
      <!-- <f7-link tab-link="#tab-bank-ebank" text="网银存款"></f7-link> -->
      <!-- <f7-link tab-link="#tab-offline-recharge" :text="$t('deposit_0002')"></f7-link> -->
      <f7-link tab-link="#tab-bank-transfer" :text="$t('deposit_0003')"></f7-link>
      <f7-link tab-link="#tab-crypto-currency" :text="$t('deposit_0063')"></f7-link>
    </div>
    <f7-tabs class="tabs-deposit">
      <f7-tab @tab:show="tabInit(4)" id="tab-bank-ebank" class="page-content tab-bank-transfer">
        <f7-block v-if="eBankForm.OnlineBlankList.length > 0">
          <div class="list inline-labels list-transfer">
            <ul>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">
                      <i class="iconfont icon-yonghu"></i>
                    </div>
                    <div class="item-input-wrap">
                      <input type="number" v-model="eBankForm.Amount" :placeholder="$t('common_deposit_minmaxamount_format', [eBankForm.minAmount, eBankForm.maxAmount])" />
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="eBankForm.OnlineBlankList.length > 0">
                <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                  <select v-model="eBankForm.BankCode">
                    <option v-for="item in eBankForm.OnlineBlankList" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                  </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title"><i class="iconfont icon-yonghu"></i></div>
                      <div class="item-after">{{ eBankForm.OnlineBlankList[0][1] }}</div>
                    </div>
                  </div>
                </a>
              </li>
              <li v-if="validData.length > 0">
                <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                  <select v-model="eBankForm.PromotionId">
                    <option v-for="item in validData" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                  </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title"><i class="iconfont icon-yonghu"></i></div>
                      <div class="item-after">{{ validData[0][1] }}</div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <f7-list inset class="list-btn">
            <f7-list-button :title="$t('deposit_0007')" color="black" @click="onlineBlankSumbit"></f7-list-button>
          </f7-list>
        </f7-block>
        <template v-else>
          <f7-card class="no-data">
            <img :src="`../../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab @tab:show="tabInit(2)" id="tab-online-recharge" class="page-content tab-online" tab-active>
        <template v-if="onlinePayList.length">
          <div v-show="!onlinePayShowCode">
            <f7-block-title class="block-title-online">{{ $t("deposit_0061") }}</f7-block-title>
            <f7-block class="onlinepay-list">
              <f7-row no-gap v-if="onlinePayList.length > 0">
                <f7-col width="25" class="text-center onlinepay-item" v-for="(value, key) in onlinePayTypeData" :key="key" :class="{ active: value.code === selectedOnLinePay.code }" @click="toggleOnlinePay(value)">
                  <img v-if="value.code != 'EEE'" :src="`../../../static/images/yt999/pay/${value.code}.png`" width="60%" />
                  <p v-if="value.code != 'EEE'">{{ value.name }}</p>
                </f7-col>
                <f7-col width="25"></f7-col>
              </f7-row>
            </f7-block>

            <f7-block-title class="block-title-online">{{ $t("deposit_0008") }}</f7-block-title>
            <f7-block class="onlinepay-list">
              <f7-row no-gap v-if="onlinePayChannelList.length > 0">
                <f7-col style="padding-top: 15px" width="33" class="text-center onlinepay-item" v-for="(value, key) in onlinePayChannelList" :key="key" :class="{ active: value.PayAccountId === selectedOnLinePayChannel.PayAccountId }" @click="toggleOnlinePayChannel(value.PayAccountId)">
                  <p>{{ value.ChannelName.split("(")[0] }}</p>
                  <p>({{ value.ChannelName.split("(")[1] }}</p>
                </f7-col>
                <f7-col width="33"></f7-col>
              </f7-row>
            </f7-block>
            <f7-block-title class="block-title-online" style="white-space: normal">{{ $t("deposit_0062") }}</f7-block-title>
            <div class="list list-onlinepay inline-labels">
              <ul>
                <li v-if="onlinePayQuickAmountEnable == false">
                  <div class="item-content item-input">
                    <div class="item-inner" style="border-bottom: 1px solid rgb(138 138 138); margin-right: 15px">
                      <div class="item-title item-label" style="margin-top: 1px">
                        <!-- <i class="f7-icons" style="margin-top: 5px">money_dollar_circle</i> -->
                        <img src="../../../static/images/yt999/jiner.png" alt="" srcset="" height="25px" style="margin-top: 5px" />
                      </div>
                      <div class="item-input-wrap">
                        <input type="number" :placeholder="$t('common_deposit_onlineminmaxamount_format', [selectedOnLinePayChannel.MinAmount, selectedOnLinePayChannel.MaxAmount])" v-model="onlinePayForm.Amount" />
                      </div>
                    </div>
                  </div>
                </li>
                <li v-else>
                  <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                    <select v-model="onlinePayQuickForm.OnlinePayQuickAmount">
                      <option v-for="item in onlinePayQuickAmountList" :key="item" :value="item">{{ item }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title"><i class="iconfont icon-jinbi"></i></div>
                        <div class="item-after">{{ onlinePayQuickAmountList[0] }}</div>
                      </div>
                    </div>
                  </a>
                </li>
                <li v-if="validData.length > 0">
                  <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                    <select v-model="onlinePayForm.PromotionId">
                      <option v-for="item in validData" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title"><i class="iconfont icon-Giftliwu1"></i></div>
                        <div class="item-after">{{ validData[0][1] }}</div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <f7-list inset class="list-btn">
              <f7-list-button class="button-single" :title="$t('deposit_0007')" color="white" @click="onlinePaySubmit"></f7-list-button>
            </f7-list>
          </div>
          <f7-block v-show="onlinePayShowCode" class="block-order">
            <div class="card">
              <div class="card-content">
                <div class="list media-list">
                  <ul>
                    <li class="item-content">
                      <div class="item-media">
                        <img :src="onlineOrderData.onlinePayLogo" width="44" />
                      </div>
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">
                            <span class="order-title">{{ $t("deposit_0010") }}</span>
                            <span class="order-number">{{ onlineOrderData.orderNumber }}</span>
                          </div>
                        </div>
                        <div class="item-subtitle">
                          <span class="order-title">{{ $t("deposit_0011") }}</span>
                          <span class="order-price">{{ userInfo.currencySymbol }}{{ onlineOrderData.Amount | formatCurrency }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="order-qrcode" v-if="onlineOrderData.payQrCodeUrl">
              <img :src="onlineOrderData.payQrCodeUrl" alt width="58%" />
              <div class="express-date">
                <template v-if="!expiredShow">
                  <span>{{ $t("deposit_0012") }}</span>
                  <span class="color-date">{{ onlineOrderData.expDate | time("mm:ss") }}</span>
                </template>
                <template v-if="expiredShow">
                  <span class="color-white">{{ $t("deposit_0013") }}</span>
                </template>
              </div>
            </div>
            <div class="padding text-left order-desc">
              <p class="text-16 color-blue">{{ $t("deposit_0014") }}</p>
              <p class="text-14">
                <span class="color-blue text-bold">1.&nbsp;</span>
                {{ $t("deposit_0015") }}
              </p>
              <p class="text-14">
                <span class="color-blue text-bold">2.&nbsp;</span>
                {{ $t("deposit_0016") }}
              </p>
              <p class="text-14">
                <span class="color-blue text-bold">3.&nbsp;</span>
                {{ $t("deposit_0017") }}
              </p>
              <p class="remark text-14">
                <span class="color-red">●</span>
                {{ $t("deposit_0018") }}
              </p>
              <p class="remark text-14">
                <span class="color-red">●</span>
                {{ $t("deposit_0019") }}
              </p>
              <p class="remark text-14">
                <span class="color-red">●</span>
                {{ $t("deposit_0020") }}
              </p>
            </div>
          </f7-block>
          <f7-block class="tips-container" v-show="userInfo.Currency === 'VNDK'">
            <f7-block-title class="block-title-tip">{{ $t("common_0004") }}:</f7-block-title>
            <f7-block class="block-tip">
              <p>1.&nbsp; {{ $t("v1_deposit_0002") }}</p>
              <p>2.&nbsp; {{ $t("v1_deposit_0003") }}</p>
              <p>3.&nbsp; {{ $t("v1_deposit_0004") }}</p>
            </f7-block>
          </f7-block>
        </template>
        <template v-else-if="!showData">
          <f7-card class="no-data">
            <img :src="`../../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab @tab:show="tabInit(1)" id="tab-offline-recharge" class="page-content tab-offline">
        <template v-if="qrCodeData.length > 0 && qrCodeDataInit">
          <!-- <img class="panel-img" :src="selectedPay.qrCodeImg" @click="clickRedirectTo(selectedPay.ClickRedirect)"> -->
          <f7-block class="panel-qrcode">
            <div class="qrcode-info text-center" v-if="selectedIndex > 0">
              <img :src="selectedPay.qrCodeImg" @click="clickRedirectTo(selectedPay.ClickRedirect)" />
              <p class="qr-selectCode">{{ selectedPay.account }} ({{ selectedPay.name }})</p>
              <p class="qr-remark">{{ $t("deposit_0021") }}</p>
              <!-- <div class="qr-name">{{offlinePayList[selectedIndex].item[0].Name}}</div> -->
              <f7-segmented round tag="p" class="qrcode-list-btn qrcode-detail-list" v-if="selectedQRCode.length > 0">
                <f7-button round outline v-for="(item, key) in selectedQRCode" :key="key" :active="item.Id === selectedPay.offlineId" @click="selectQrCodeDetail(item)">{{ item.Name }}</f7-button>
              </f7-segmented>
              <f7-button class="click-copy" v-clipboard:copy="selectedPay.account" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t("deposit_0022") }}</f7-button>
            </div>
            <f7-segmented round tag="p" class="qrcode-list-type-btn" v-if="selectedIndex > 0">
              <f7-button round outline v-for="(value, key) in offlinePayList" :key="key" :active="value.type == selectedQRCode[0].Type" @click="toggleOfflinePay(value)">{{ value.name }}</f7-button>
            </f7-segmented>
          </f7-block>
          <div class="block-title block-title-payinfo">{{ $t("deposit_0023") }}</div>
          <div class="list list-pay inline-labels">
            <ul>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">
                      <i class="iconfont icon-jinbi"></i>
                    </div>
                    <div class="item-input-wrap">
                      <input type="number" :placeholder="$t('common_deposit_onlineminmaxamount_format', [selectedPay.offlineMinAmt, selectedPay.offlineMaxAmt])" v-model="offlineForm.Amount" />
                    </div>
                  </div>
                </div>
              </li>
              <li v-show="selectedPay.EnableRealDepositRate">
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label"><i class="iconfont icon-jinbi"></i></div>
                    <div class="item-input-wrap">
                      <label>{{ $t("deposit_0025") }} {{ getQRRealAmount }}</label>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">
                      <i class="iconfont icon-mingxiqingdanlei"></i>
                    </div>
                    <div class="item-input-wrap">
                      <input type="text" :placeholder="$t('deposit_0026') + ' ' + offlineConfirmNoDesc" v-model="offlineForm.AccountName" />
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="validData.length > 0">
                <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                  <select name="select-valid" v-model="offlineForm.PromotionId">
                    <option v-for="item in validData" :value="item[0]" :key="item[0]">{{ item[1] }}</option>
                  </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title"><i class="iconfont icon-Giftliwu1"></i></div>
                      <div class="item-after">{{ validData[0][1] }}</div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div v-show="selectedPay.EnableRealDepositRate"></div>
          <f7-list inset class="list-btn">
            <f7-list-button class="button-single" :title="$t('deposit_0007')" color="white" @click="offlineSubmit"></f7-list-button>
          </f7-list>
          <f7-block-title class="block-title-tip">{{ $t("deposit_0027") }}:</f7-block-title>
          <f7-block class="block-tip">
            <p>1.&nbsp; {{ $t("deposit_0028") }}</p>
            <p>2.&nbsp; {{ $t("deposit_0029") }}</p>
            <p>3.&nbsp; {{ $t("deposit_0030") }}</p>
          </f7-block>
        </template>
        <template v-if="qrCodeData.length == 0 && qrCodeDataInit">
          <f7-card class="no-data">
            <img :src="`../../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab @tab:show="tabInit(3)" id="tab-bank-transfer" class="page-content tab-bank-transfer">
        <div v-if="offlinePayCheck==1">
          <f7-block v-if="payBankListData.length > 0 && payBankListDataInit">
            <div class="list" v-if="payBankListData.length">
              <ul>
                <li>
                  <a class="item-link smart-select smart-select-init" data-open-in="sheet" @smartselect:close="selectBankEvent">
                    <select v-model="bankTransferForm.BankId">
                      <option v-for="item in payBankListData" :key="item.Id" :value="item.Id">{{ item.BankName }} - {{ item.CardNumber }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title"><i class="iconfont icon-yonghu"></i></div>
                        <div class="item-after">{{ payBankListData[0].BankName }} - {{ payBankListData[0].CardNumber }}</div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="card bank-card" v-if="payBankListData.length">
              <div class="card-header">
                <div class="card-avatar"><img src="../../../static/images/yt999/banklogo/cardLogo.png" width="25" /></div>
                <div class="card-name">{{ selectBankInfo.bankName }}</div>
              </div>
              <div class="card-content">
                <div class="card-info text-24 card-number">{{ selectBankInfo.bankNo | fmtBankNumber }}
                  <img style="margin-left: 5px;" src="../../../static/images/yt999/pay/copy.png" width="20" v-clipboard:copy="selectBankInfo.bankNo" v-clipboard:success="onCopy" v-clipboard:error="onError">
                </div>
                <div>
                  <div class="card-info card-name">
                    <div class="label">{{ $t("v1_deposit_0001") }}&nbsp;
                      <img style="margin-bottom: 3px;" src="../../../static/images/yt999/pay/copy.png" width="20" v-clipboard:copy="selectBankInfo.accoutName" v-clipboard:success="onCopy" v-clipboard:error="onError">
                    </div>
                    <div class="value">
                      <span>{{ selectBankInfo.accoutName }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-info card-address" style="padding-bottom: 8px;">
                  <div class="label">{{ $t("deposit_0032") }}&nbsp;
                    <img style="margin-bottom: 3px;" src="../../../static/images/yt999/pay/copy.png" width="20" v-clipboard:copy="selectBankInfo.bankAddress" v-clipboard:success="onCopy" v-clipboard:error="onError">
                  </div>
                  <div class="value">
                    <span>{{ selectBankInfo.bankAddress }}</span>
                  </div>
                </div>
                
              </div>
              <!-- <div class="card-footer">
                <f7-block>
                  <f7-row>
                    <f7-col>
                     
                    </f7-col>
                    <f7-col>
                      
                    </f7-col>
                  </f7-row>
                </f7-block>
              </div> -->
            </div>
            <div v-if="selectBankInfo.memberRemarks != ''" class="remarks">
              <span class="tips">{{ $t("deposit_0024") }}:</span>
              <br />
              <span class="tips-remarks">{{ selectBankInfo.memberRemarks }}</span>
            </div>
            <div class="list inline-labels list-transfer">
              <ul>
                <li class="hidden">
                  <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                    <select v-model="bankTransferForm.TransferType">
                      <option selected value="BankBar">{{ $t("deposit_0036") }}</option>
                      <option value="Mobile">{{ $t("deposit_0037") }}</option>
                      <option value="NetBank">{{ $t("deposit_0038") }}</option>
                      <option value="ATM">{{ $t("deposit_0039") }}</option>
                      <option value="ATMCash">{{ $t("deposit_0040") }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title"><i class="iconfont icon-yonghu"></i></div>
                        <div class="item-after">{{ $t("deposit_0036") }}</div>
                      </div>
                    </div>
                  </a>
                </li>
                <li style="display: none">
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label"><i class="iconfont icon-yonghu"></i></div>
                      <div class="item-input-wrap">
                        <input type="text" id="date" v-model="bankTransferForm.DepositTs" readonly />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label"><i class="iconfont icon-yonghu"></i></div>
                      <div class="item-input-wrap">
                        <input type="text" v-model="bankTransferForm.AccountName" :placeholder="$t('deposit_0044')" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label"><i class="iconfont icon-jinbi"></i></div>
                      <div class="item-input-wrap">
                        <input type="number" v-model="bankTransferForm.Amount" :placeholder="$t('common_deposit_minmaxamount_format', [selectBankInfo.minAmount, selectBankInfo.maxAmount])" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label"><i class="iconfont icon-yonghuziliaogerenxinxigerenziliao-xianxing"></i></div>
                      <div class="item-input-wrap">
                        <input type="number" v-model="bankTransferForm.BankAccountNo" :placeholder="$t('deposit_0047')" />
                      </div>
                    </div>
                  </div>
                </li>
                <li v-if="bankListData.length > 0" style="display: none">
                  <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                    <select v-model="bankTransferForm.BankCode">
                      <option v-for="item in bankListData" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title"><i class="iconfont icon-yonghu"></i></div>
                        <div class="item-after">{{ bankListData[0][1] }}</div>
                      </div>
                    </div>
                  </a>
                </li>
                <li v-if="validData.length > 0">
                  <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                    <select v-model="bankTransferForm.PromotionId">
                      <option v-for="item in validData" :key="item[0]" :value="item[0]">{{ item[1] }}</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title"><i class="iconfont icon-Giftliwu1"></i></div>
                        <div class="item-after">{{ validData[0][1] }}</div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <f7-list inset class="list-btn">
              <f7-list-button class="button-single" :title="$t('deposit_0007')" color="white" @click="bankTransferSubmit"></f7-list-button>
            </f7-list>
          </f7-block>
          <template v-if="payBankListData.length == 0 && payBankListDataInit">
            <f7-card class="no-data">
              <img :src="`../../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
            </f7-card>
          </template>
        </div>
        <div v-if="offlinePayCheck==2" >
          <div class="offlinePayCheck">
            <img src="../../../static/images/yt999/pay/underReview.png" alt="" />
            <div class="ticketTip">{{ $t("deposit_0068") }}</div>
            <div class="ticketTitle">{{ $t("deposit_0069") }}</div>
            <div class="ticketNo">{{ offlinePayCheckData.TicketNo }}</div>
            <f7-list inset class="list-btn">
              <f7-list-button class="button-single" :title="$t('deposit_0070')" color="white" v-clipboard:copy="offlinePayCheckData.TicketNo" v-clipboard:success="onCopy" v-clipboard:error="onError" ></f7-list-button>
            </f7-list>
          </div>
        </div>

      </f7-tab>
      <f7-tab @tab:show="tabInit(5)" id="tab-crypto-currency" class="page-content tab-crypto-currency">
        <f7-block >
          <div style="text-align: center;margin: 20px 0 10px 0;"><img src="../../../static/images/yt999/pay/cryptocurrencyBg.png" width="70%" srcset=""></div>
          <div style="text-align: center;margin: 20px 0 ;">
            <p style="margin: 5px 0 ;">{{$t('deposit_0064')}}</p>
            <p style="margin: 5px 0 ;">{{$t('deposit_0065')}}</p>
            <p style="margin: 5px 0 ;">{{$t('deposit_0066')}}</p>
          </div>
          <f7-list inset class="list-btn">
            <f7-list-button class="button-single" :title="$t('deposit_0067')" color="white" @click="$f7router.navigate('/member-center/online-cs/')"></f7-list-button>
          </f7-list>
        </f7-block>
      </f7-tab>
    </f7-tabs>

    <!-- PWA弹窗支付 -->
    <f7-popup id="PWAPlay" v-if="showPWAPlay" class="onlineCustom-popup" :opened="showPWAPlayOpened" @popup:open="openPWAPlay" @popup:close="closePWAPlay">
      <f7-page style="position: relative">
        <img src="../../../static/images/yt999/pwa_close.png" ref="draggableButton" class="popupCloseBtn" @click="closePWAPlay()" />
        <iframe :src="PWAPlayUrl" class="iframe-onlineCustom" frameborder="0" style="transform: none !important; width: 100%; height: 100%"></iframe>
      </f7-page>
    </f7-popup>


  </f7-page>
</template>

<script>
import { getCookie, setStore, getStore } from "../../../config/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getValidList, getQrList, payQR, getOnlinePayList, onlinePayRequest, requestBankList, getPayBankList, payBankTransfer, getOnlineBlankList, onlineBankSumbit,getBlankList,offlinePaymentOrderCheck } from "../../../axios/api";
import common from "../../../service/common";
import api from "../../../config/api";
import http from "../../../axios/http";
import moment from "moment";

export default {
  components: {},
  props: {},
  data() {
    return {
      showData: true,
      validData: [],
      qrCodeData: [],
      qrCodeDataInit: false,
      selectedPay: {
        qrCodeImg: "",
        offlineId: 0,
        offlineMinAmt: 0,
        offlineMaxAmt: 0,
        name: "",
        account: "",
        EnableRealDepositRate: false,
        RealDepositRate: 1,
        ClickRedirect: "",
      },
      selectedQRCode: [],
      offlineEnum: {
        1: this.$t("deposit_0049"),
        2: this.$t("deposit_0050"),
        3: this.$t("deposit_0051"),
        4: this.$t("deposit_0052"),
        5: this.$t("deposit_0053"),
        101: "UPI",
        102: "Paytm",
        90: "USDT(Erc20)",
        91: "USDT(Trc20)",
        92: "USDT(Omni)",
      },
      offlinePayList: {},
      selectedIndex: -1,
      offlineForm: {
        BankId: null,
        PayType: null,
        Amount: "",
        AccountName: "",
        BankAccountNo: "",
        PromotionId: "0",
        RealDepositRate: 0,
      },
      onlinePayList: [], //在线充值
      selectedOnLinePay: {},
      selectedOnLinePayChannel: {},
      onlinePayTypeData: {},
      onlinePayQuickForm: {
        OnlinePayQuickAmount: "",
      },
      emptyOnlinePayEntity: {
        PayAccountId: -1,
        Type: 15,
        ChannelList: [],
        MinAmount: 0,
        MaxAmount: 0,
        Status: true,
      },
      onlinePayForm: {
        PayAccountId: 0,
        PromotionId: "0",
        PayPlatformType: "",
        Amount: "",
        SupportPlatform: 2,
      },
      onlinePayChannelList: [],
      onlinePayQuickAmountEnable: false,
      onlinePayQuickAmountList: [],
      onlinePayShowCode: false,
      expiredShow: false,
      onlineOrderData: {
        payQrCodeUrl: "",
        expDate: "",
        Amount: 0,
        orderNumber: "",
        onlinePayLogo: "",
      },
      intervalTime: null,
      bankListData: [], //银行转账
      payBankListData: [],
      payBankListDataInit: false,
      bankTransferForm: {
        BankId: "",
        BankCode: "",
        TransferType: "BankBar",
        PayType: 1,
        Amount: "",
        BankAccountNo: "",
        AccountName: "",
        PromotionId: "0",
        DepositTs: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      eBankForm: {
        minAmount: 0,
        maxAmount: 0,
        Amount: "",
        BankCode: "",
        PromotionId: "0",
        OnlineBlankList: [],
      },
      selectBankInfo: {
        bankName: "",
        accoutName: "",
        bankNo: "",
        bankAddress: "",
        minAmount: 0,
        maxAmount: 0,
      },
      offlinePayCheck: 0,
      offlinePayCheckData: {},
      pwaPlayFlag: false,
      showPWAPlay: true,
      showPWAPlayOpened: false,
      PWAPlayUrl: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"]),

    getQRRealAmount: function () {
      if (this.selectedPay.EnableRealDepositRate == false) {
        return 0;
      } else {
        if (this.offlineForm.Amount <= 0) {
          return 0;
        }
        if (this.selectedPay.RealDepositRate <= 0) {
          return 0;
        }
        return Math.round((this.offlineForm.Amount / this.selectedPay.RealDepositRate) * 1000) / 1000;
      }
    },
    offlineConfirmNoDesc: function () {
      switch (this.selectedIndex) {
        case 90:
        case 91:
        case 92:
          return this.$t("deposit_0054");
        default:
          return this.$t("deposit_0055");
      }
    },
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    getofflinePaymentOrderCheck() {
      offlinePaymentOrderCheck().then((data) => {
        if (data.Code === "NoError") {
          this.offlinePayCheckData = data.Data;
          if (!this.offlinePayCheckData) {
            this.offlinePayCheck = 1;
          } else {
            this.offlinePayCheckData.TicketNo ? (this.offlinePayCheck = 2) : (this.offlinePayCheck = 1);
          }
        }
      });
    },
    validList(type) {
      const self = this;
      const param = { data: type };
      getValidList(param).then((data) => {
        if (data.Code === "NoError") {
          self.validData = data.Data;
          if (data.Data.length > 0) {
            self.offlineForm.PromotionId = data.Data[0][0];
            self.onlinePayForm.PromotionId = data.Data[0][0];
            self.bankTransferForm.PromotionId = data.Data[0][0];
            self.eBankForm.PromotionId = data.Data[0][0];
          } else {
            self.offlineForm.PromotionId = 0;
            self.onlinePayForm.PromotionId = 0;
            self.bankTransferForm.PromotionId = 0;
            self.eBankForm.PromotionId = 0;
          }
        }
      });
    },
    qrList() {
      const self = this;
      getQrList().then((data) => {
        if (data.Code === "NoError") {
          self.qrCodeData = data.Data;
          self.qrCodeDataInit = true;
          if (data.Data.length > 0) {
            self.selectedIndex = data.Data[0].Type;
            data.Data.forEach((item) => {
              if (!self.offlinePayList[item.Type]) {
                self.offlinePayList[item.Type] = {
                  name: "",
                  item: [],
                  type: "",
                };
              }
              self.offlinePayList[item.Type]["type"] = item.Type;
              self.offlinePayList[item.Type]["name"] = self.offlineEnum[item.Type];
              self.offlinePayList[item.Type]["item"].push(item);
            });
            self.selectedPay.qrCodeImg = getStore("ImagesURL") + window.configs.qrcodeImg + self.qrCodeData[0].QRList[0].Url;
            self.selectedQRCode = self.qrCodeData[0].QRList;
            self.selectedPay.offlineId = self.offlineForm.BankId = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].Id;
            self.selectedPay.offlineMinAmt = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].MinAmount;
            self.selectedPay.offlineMaxAmt = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].MaxAmount;
            self.selectedPay.name = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].Name;
            self.selectedPay.account = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].CardNumber;
            self.selectedPay.EnableRealDepositRate = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].EnableRealDepositRate;
            self.selectedPay.RealDepositRate = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].RealDepositRate;
            self.selectedPay.ClickRedirect = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].ClickRedirect;
          }
        }
      });
    },
    openPWAPlay() {
      this.showPWAPlay = true;
      this.showPWAPlayOpened = true;
    },
    closePWAPlay() {
      this.showPWAPlayOpened = false;
      this.$f7.popup.close("#PWAPlay", true);
    },
    selectQrCodeDetail(item) {
      this.selectedIndex = item.Type;
      this.selectedPay.qrCodeImg = getStore("ImagesURL") + window.configs.qrcodeImg + item.Url;
      this.selectedPay.offlineId = this.offlineForm.BankId = item.Id;
      this.selectedPay.offlineMinAmt = item.MinAmount;
      this.selectedPay.offlineMaxAmt = item.MaxAmount;
      this.selectedPay.name = item.Name;
      this.selectedPay.account = item.CardNumber;
      this.selectedPay.EnableRealDepositRate = item.EnableRealDepositRate;
      this.selectedPay.RealDepositRate = item.RealDepositRate;
      this.selectedPay.ClickRedirect = item.ClickRedirect;
    },
    toggleOfflinePay(d) {
      //线下充值选择支付方式
      this.selectedIndex = d.type;
      this.selectedQRCode = d.item[0].QRList;
      this.selectedPay.qrCodeImg = getStore("ImagesURL") + window.configs.qrcodeImg + d["item"][0].QRList[0].Url;
      this.selectedPay.offlineId = this.offlineForm.BankId = d["item"][0].QRList[0].Id;
      this.selectedPay.offlineMinAmt = d["item"][0].QRList[0].MinAmount;
      this.selectedPay.offlineMaxAmt = d["item"][0].QRList[0].MaxAmount;
      this.selectedPay.name = d["item"][0].QRList[0].Name;
      this.selectedPay.account = d["item"][0].QRList[0].CardNumber;
      this.selectedPay.EnableRealDepositRate = d["item"][0].QRList[0].EnableRealDepositRate;
      this.selectedPay.RealDepositRate = d["item"][0].QRList[0].RealDepositRate;
      this.selectedPay.ClickRedirect = d["item"][0].QRList[0].ClickRedirect;
    },
    offlineSubmit() {
      //线下充值
      const self = this;
      if (self.offlineForm.Amount == "" || self.offlineForm.Amount < self.selectedPay.offlineMinAmt || self.offlineForm.Amount > self.selectedPay.offlineMaxAmt) {
        self.$f7.dialog.alert(this.$t("common_deposit_minmaxamount_format", [self.selectedPay.offlineMinAmt, self.selectedPay.offlineMaxAmt]), this.$t("common_0004"));
        return false;
      }
      // if (!self.offlineForm.BankAccountNo) {
      //   self.$f7.dialog.alert(`请输入支付账户`, "温馨提示");
      //   return false;
      // }
      if (!self.offlineForm.AccountName) {
        self.$f7.dialog.alert(this.$t("deposit_0026") + self.offlineConfirmNoDesc, this.$t("common_0004"));
        return false;
      }
      if (self.selectedPay.EnableRealDepositRate) {
        self.offlineForm.RealDepositRate = self.selectedPay.RealDepositRate;
      } else {
        self.offlineForm.RealDepositRate = 0;
      }
      self.offlineForm.PayType = self.selectedIndex * 1 + 1;
      self.offlineForm.BankAccountNo = "--";
      const param = { data: JSON.stringify(self.offlineForm) };
      payQR(param).then((data) => {
        if (data.Code === "NoError") {
          self.offlineForm = {
            PayType: null,
            Amount: "",
            BankAccountNo: "",
            AccountName: "",
            RealDepositRate: 0,
          };
          self.$f7.dialog.alert(this.$t("deposit_0056"), this.$t("common_0004"));
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    deepCopy(obj) {
      var newObj = {};
      for (vl in obj) {
        if (typeof obj[vl] === "object" && obj[vl] !== null) {
          newObj[vl] = deepCopy(obj[vl]);
        } else {
          newObj[vl] = obj[vl];
        }
      }
      return newObj;
    },
    clickRedirectTo(url) {
      if (typeof url == "undefined" || url == "") {
        return;
      }
      this.common.redirectURL(url);
    },
    onlinePayTypeList() {
      //在线充值
      const self = this;
      getOnlinePayList({ data: 2 }).then((data) => {
        self.showData = self.onlinePayList.length > 0;
        if (data.Code === "NoError" && data.Data.length) {
          // console.log(data.Data);
          var total = data.Data.length;
          if (total > 0) {
            var check = total % 4;
            //console.log(check);
            if (check > 0) {
              for (let index = 0; index < 4 - check; index++) {
                data.Data[data.Data.length] = self.emptyOnlinePayEntity;
              }
            }
          }
          self.onlinePayList = data.Data;

          //console.log(self.onlinePayList.length);
          const formatOnlineType = common.getOnlinePayType();
          self.onlinePayForm.PayPlatformType = formatOnlineType[data.Data[0].Type].code;
          self.onlinePayList.forEach((item, index) => {
            if (item.Status) {
              //console.log(formatOnlineType[item.Type]);
              //console.log(item);
              self.onlinePayTypeData[index] = formatOnlineType[item.Type] || {};
              self.onlinePayTypeData[index].MaxAmount = item.MaxAmount;
              self.onlinePayTypeData[index].MinAmount = item.MinAmount;
              self.onlinePayTypeData[index].ChannelList = item.ChannelList;
              //self.onlinePayTypeData[index].PayAccountId = item.PayAccountId;
              if (item.ChannelList.length == 0) {
                self.onlinePayTypeData[index].code = "EEE";
                self.onlinePayTypeData[index].name = "EEE";
              }
            }
          });
          self.selectedOnLinePay = self.onlinePayTypeData[0];
          self.onlinePayChannelList = self.selectedOnLinePay.ChannelList;
          self.selectedOnLinePayChannel = self.onlinePayChannelList[0];
          self.onlinePayForm.PayAccountId = self.selectedOnLinePay.ChannelList[0].PayAccountId;
          self.onlinePayQuickAmountEnable = self.selectedOnLinePay.ChannelList[0].EnableQuickAmount && self.selectedOnLinePay.ChannelList[0].QuickAmountList.length > 0;
          self.onlinePayQuickAmountList = self.selectedOnLinePay.ChannelList[0].QuickAmountList;
          if (self.onlinePayQuickAmountEnable) {
            this.onlinePayQuickForm.OnlinePayQuickAmount = self.onlinePayQuickAmountList[0];
          } else {
            this.onlinePayQuickForm.OnlinePayQuickAmount = 0;
          }
        }
      });
    },
    toggleOnlinePay(value) {
      if (value.code == "EEE") {
        return;
      }
      const self = this;
      const formatOnlineType = common.getOnlinePayType();
      self.selectedOnLinePay = value;
      self.onlinePayForm.PayPlatformType = value.code;
      self.onlinePayChannelList = self.selectedOnLinePay.ChannelList;
      self.selectedOnLinePayChannel = self.onlinePayChannelList[0];
      self.onlinePayForm.PayAccountId = self.selectedOnLinePay.ChannelList[0].PayAccountId;
      self.onlinePayQuickAmountEnable = self.selectedOnLinePay.ChannelList[0].EnableQuickAmount && self.selectedOnLinePay.ChannelList[0].QuickAmountList.length > 0;
      self.onlinePayQuickAmountList = self.selectedOnLinePay.ChannelList[0].QuickAmountList;
      if (self.onlinePayQuickAmountEnable) {
        self.onlinePayQuickForm.OnlinePayQuickAmount = self.onlinePayQuickAmountList[0];
      } else {
        self.onlinePayQuickForm.OnlinePayQuickAmount = 0;
      }
    },
    toggleOnlinePayChannel(value) {
      const self = this;
      // console.log('1:'+value);
      // console.log('2:'+self.onlinePayForm.PayAccountId);
      if (self.onlinePayForm.PayAccountId == value) {
        return;
      }
      self.selectedOnLinePay.ChannelList.forEach((item, index) => {
        if (item.PayAccountId === value) {
          self.onlinePayForm.PayAccountId = value;
          self.selectedOnLinePayChannel = item;
          self.onlinePayQuickAmountList = item.QuickAmountList;
          self.onlinePayQuickAmountEnable = item.EnableQuickAmount && self.onlinePayQuickAmountList.length > 0;
          if (self.onlinePayQuickAmountEnable) {
            self.onlinePayQuickForm.OnlinePayQuickAmount = item.QuickAmountList[0];
          } else {
            self.onlinePayQuickForm.OnlinePayQuickAmount = 0;
          }
        }
      });
    },
    onlinePaySubmit() {
      const self = this;
      if (self.onlinePayQuickAmountEnable) {
        self.onlinePayForm.Amount = self.onlinePayQuickForm.OnlinePayQuickAmount;
      }
      if (self.onlinePayForm.Amount == "" || self.onlinePayForm.Amount > self.selectedOnLinePayChannel.MaxAmount || self.onlinePayForm.Amount < self.selectedOnLinePayChannel.MinAmount) {
        self.$f7.dialog.alert(this.$t("common_deposit_minmaxamount_format", [self.selectedOnLinePayChannel.MinAmount, self.selectedOnLinePayChannel.MaxAmount]), this.$t("common_0004"));
        return false;
      }
      const param = { data: JSON.stringify(self.onlinePayForm) };

      // if (!getStore("device")) {
      //   sessionStorage.setItem("payInfo", JSON.stringify(self.onlinePayForm));
      //   this.common.redirectURL(`/#/member-center/init-online-payment/1/`);
      //   return;
      // }

      let onlineLoading = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("deposit_0057"),
          cssClass: "dialog-preloadgame",
        })
        .open();

      onlinePayRequest(param).then((data) => {
        if (data.Code === "NoError") {
          setTimeout(() => {
            onlineLoading.close();
          }, 1000);
          const d = data.Data;
          if (d[5] === 0) {
            self.onlinePayShowCode = true;
            self.onlineOrderData.payQrCodeUrl = d[2];
            self.onlineOrderData.expDate = d[1] * 60000;
            self.onlineOrderData.Amount = d[0];
            self.onlineOrderData.orderNumber = d[4];
            self.onlineOrderData.onlinePayLogo = `../../../static/images/yt999/pay/${self.onlinePayForm.PayPlatformType}.png`;
            self.intervalTime = setInterval(() => {
              if (self.onlineOrderData.expDate == 0) {
                clearInterval(self.intervalTime);
                self.expiredShow = true;
                self.onlineOrderData.payQrCodeUrl = `../../../static/images/yt999/pay-expired.png`;
                self.$f7.dialog.alert(this.$t("deposit_0058"), this.$t("common_0004"), () => {
                  self.onlinePayShowCode = false;
                  self.expiredShow = false;
                });
              }
              self.onlineOrderData.expDate -= 1000;
            }, 1000);
          } else if (d[5] === 1) {
            if(self.pwaPlayFlag){
              self.PWAPlayUrl = data.Data[2];
              self.openPWAPlay();
              return;
            }else{
              // window.location.href = d[2];
              // return;
              if (getStore("device") === "App") {
                window.location.href = d[2];
                return;
              } else {
                self.$f7.dialog
                  .create({
                    title: this.$t("deposit_0071"),
                    text: this.$t("deposit_0072"),
                    buttons: [
                      {
                        text: this.$t("deposit_0073"),
                      },
                      {
                        text: this.$t("deposit_0074"),
                        onClick: function () {
                          window.open( data.Data[2], "_blank");
                        },
                      },
                    ],
                  })
                  .open();
                return;
              }
            }
          } else if (d[5] === 2) {
            if (getStore("device")) {
              document.write(d[2]);
              self.onlinePayForm.Amount = "";
            } else {
              setTimeout(() => {
                // let win = window.open("");
                // win.document.write(d[2]);
                document.write(d[2]);
                self.onlinePayForm.Amount = "";
              }, 500);
            }
          }
        } else {
          onlineLoading.close();
          let onlineErrorLoading = self.$f7.dialog
            .create({
              title: "",
              text: `${data.Data}`,
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            onlineErrorLoading.close();
          }, 1000);
        }
      });
    },
    payBankList() {
      const self = this;
      getPayBankList().then((data) => {
        if (data.Code === "NoError") {
          self.payBankListDataInit = true;
          if (data.Data.length > 0) {
            self.payBankListData = data.Data;
            self.bankTransferForm.BankId = data.Data[0].Id;
            self.selectBankInfo = {
              bankName: data.Data[0].BankName,
              accoutName: data.Data[0].AccountName,
              bankNo: data.Data[0].CardNumber,
              bankAddress: data.Data[0].BankInfo,
              minAmount: data.Data[0].MinAmount,
              maxAmount: data.Data[0].MaxAmount,
              memberRemarks: data.Data[0].MemberRemarks,
            };
          }
        }
      });
    },
    bankList() {
      const self = this;
      // self.$f7.request.promise.getJSON(api.getBlankList).then((data) => {
      //   if (data.Code == "NoError") {
      //     self.bankListData = data.Data;
      //     self.bankTransferForm.BankCode = data.Data[0][0];
      //   }
      //   console.log(data.Data)
      // });

      getBlankList().then((data) => {
        if (data.Code == "NoError") {
          self.bankListData = data.Data;
          self.bankTransferForm.BankCode = data.Data[0][0];
        }
      });
    },
    getOnlineBlankList() {
      const self = this;
      self.$f7.request.promise.getJSON(api.getOnlineBlankList).then((data) => {
        if (data.Code === "NoError" && data.Data.length >= 3) {
          self.eBankForm.minAmount = data.Data[0];
          self.eBankForm.maxAmount = data.Data[1];
          self.eBankForm.OnlineBlankList = data.Data[2];
          self.eBankForm.BankCode = self.eBankForm.OnlineBlankList[0][0];
        }
      });
    },
    selectBankEvent() {
      let selectBankItem = this.payBankListData.filter((item) => {
        return item.Id === this.bankTransferForm.BankId;
      });
      this.selectBankInfo = {
        bankName: selectBankItem[0].BankName,
        accoutName: selectBankItem[0].AccountName,
        bankNo: selectBankItem[0].CardNumber,
        bankAddress: selectBankItem[0].BankInfo,
        minAmount: selectBankItem[0].MinAmount,
        maxAmount: selectBankItem[0].MaxAmount,
        MemberRemarks: selectBankItem[0].MemberRemarks,
        BankCode: selectBankItem[0].BankCode,
      };
      this.bankTransferForm.BankCode = selectBankItem[0].BankCode;

    },
    bankTransferSubmit() {
      const self = this;
      if (!self.bankTransferForm.AccountName) {
        self.$f7.dialog.alert(this.$t("deposit_0059"), this.$t("common_0004"));
        return false;
      }
      if (!self.bankTransferForm.Amount) {
        self.$f7.dialog.alert(this.$t("deposit_0060"), this.$t("common_0004"));
        return false;
      }
      if (self.bankTransferForm.Amount > self.selectBankInfo.maxAmount || self.bankTransferForm.Amount < self.selectBankInfo.minAmount) {
        self.$f7.dialog.alert(this.$t("common_deposit_minmaxamount_format", [self.selectBankInfo.minAmount, self.selectBankInfo.maxAmount]), this.$t("common_0004"));
        return false;
      }
      if (!self.bankTransferForm.BankAccountNo) {
        self.$f7.dialog.alert(this.$t("deposit_0047"), this.$t("common_0004"));
        return false;
        //self.bankTransferForm.BankAccountNo = "--";
      }
      const param = {
        data: JSON.stringify(self.bankTransferForm),
      };
      payBankTransfer(param).then((data) => {
        if (data.Code === "NoError") {
          self.$f7.dialog.alert(this.$t("deposit_0056"), this.$t("common_0004"),()=>{
            self.getofflinePaymentOrderCheck();
          });
          self.bankTransferForm.AccountName = "";
          self.bankTransferForm.Amount = "";
          self.bankTransferForm.BankAccountNo = "";
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    onCopy(e) {
      const self = this;
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    tabInit(d) {
      const self = this;
      if (d === 1) {
        self.onlinePayShowCode = false;
        clearInterval(self.intervalTime);
        self.validList("QrCodeOfflinePay");
        self.qrList();
        clearInterval(self.intervalTime);
      } else if (d === 2) {
        self.onlinePayShowCode = false;
        self.validList("QrCodeOnlinePay");
        self.onlinePayTypeList();
      } else if (d === 3) {
        self.onlinePayShowCode = false;
        clearInterval(self.intervalTime);
        self.validList("OfflineBankPay");
        self.getofflinePaymentOrderCheck();
        self.payBankList();
        self.bankList();
      } else if (d === 4) {
        self.onlinePayShowCode = false;
        self.validList("BankOnlinePay");
        self.getOnlineBlankList();

      }
    },

    initPickerDate() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      let date1 = new Date();
      const today = new Date(date1);
      today.setDate(date1.getDate() - 8);
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#date",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(), today.getHours() < 10 ? `0${today.getHours()}` : today.getHours(), today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes(), today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }

            self.bankTransferForm.DepositTs = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
    onlineBlankSumbit() {
      const self = this;
      if (self.eBankForm.Amount > self.eBankForm.maxAmount || self.eBankForm.Amount < self.eBankForm.minAmount) {
        self.$f7.dialog.alert(this.$t("common_deposit_minmaxamount_format", [self.eBankForm.minAmount, self.eBankForm.maxAmount]), this.$t("common_0004"));
        return false;
      }
      const data = {
        Amount: self.eBankForm.Amount,
        BankCode: self.eBankForm.BankCode,
        PromotionId: self.eBankForm.PromotionId,
      };

      if (!getStore("device")) {
        sessionStorage.setItem("payInfo", JSON.stringify(data));
        this.common.redirectURL(`/#/member-center/init-online-payment/0/`);
        return;
      }

      let onlineLoading = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("deposit_0057"),
          cssClass: "dialog-preloadgame",
        })
        .open();

      const param = { data: JSON.stringify(data) };
      onlineBankSumbit(param).then((data) => {
        if (data.Code === "NoError") {
          setTimeout(() => {
            onlineLoading.close();
            ``;
          }, 1000);
          if (data.Data[0] === 1) {
            window.location.href = data.Data[1];
          } else {
            document.write(data.Data[1]);
          }
        } else {
          onlineLoading.close();
          let onlineErrorLoading = self.$f7.dialog
            .create({
              title: "",
              text: `${data.Data}`,
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            onlineErrorLoading.close();
          }, 1000);
        }
      });
    },
  },
  created() {
    // this.validList("OfflineBankPay");
    // this.payBankList();
    // this.bankList();

    //this.validList("QrCodeOfflinePay");
    //this.qrList();

    this.validList("QrCodeOnlinePay");
    this.onlinePayTypeList();
    this.getUserInfo();
  },
  mounted() {
    this.initPickerDate();
  },
  updated() {
    this.$f7.lazy.create(this.$el);
  },
};
</script>
<style lang="less" scoped>
.ios .page-deposit .page-content .buttons-deposit {
  height: 40px;
  width: 90% !important;
  margin: 10px auto;
  border: 1px solid #2366cc;
  border-radius: 10px;
}
.ios .page-deposit .page-content .buttons-deposit a.tab-link {
  color: #000;
  border-right: 1px solid #2366cc;
  :last-child {
    border-right: none;
  }
}
.ios .page-deposit .page-content .buttons-deposit a.tab-link:last-child {
    border-right: none;
  }
.ios .page-deposit .page-content .buttons-deposit a.tab-link.tab-link-active {
  border: 0;
  background: #2366cc;
  color: #fff;
}
.ios .page-deposit .page-content .buttons-deposit a.tab-link:last-child:after {
  width: 0;
  height: 0;
  display: none;
}
.offlinePayCheck {
  text-align: center;
  img {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .ticketTip {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 65%;
  }
  .ticketTitle {
    font-weight: 600;
    margin-bottom: 4px;
  }
  .ticketNo {
    color: red;
  }
  .button-single{
    background: #2366cc;
    border-radius: 8px;
  }
}
.popupCloseBtn {
  display: inline-block;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 25px;
  z-index: 100;
}
</style>
