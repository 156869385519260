<template>
  <f7-app :params="f7params" :class="'lang-' + f7params.currLang">
    <!-- Status bar overlay for fullscreen mode-->
    <!-- <f7-statusbar></f7-statusbar> -->

    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas">
      <!-- <div class="game-more row" v-if="currentRouterUrl===`home`">
        <div class="game-list-more col-25">
          <img src="../static/images/yt999/home/more.png" />
        </div>
      </div> -->

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      <f7-view url="/" :main="true" :animate="false" :preloadPreviousPage="false" :iosSwipeBack="false" :iosSwipeBackAnimateShadow="false" :iosSwipeBackAnimateOpacity="false" :mdSwipeBack="false" :mdSwipeBackAnimateShadow="false" :mdSwipeBackAnimateOpacity="false" :auroraSwipeBack="false" :auroraSwipeBackAnimateShadow="false" :auroraSwipeBackAnimateOpacity="false" class="safe-areas" @view:init="onViewInit"></f7-view>
      <div class="placeholder"></div>
      <!-- Tabbar for switching views-tabs -->
      <f7-toolbar tabbar labels bottom class="toolbar-footer">
        <f7-link tab-link no-fast-click href="/" :ignore-cache="true" :tab-link-active="currentRouterUrl === `home`">
          <img :class="currentRouterUrl === `home` ? 'img-home-act' : 'img-home'" />
          <span>{{ $t("app_0004") }}</span>
        </f7-link>
        <f7-link  tab-link no-fast-click href="/member-center/order-center/0/" :tab-link-active="currentRouterUrl === `order-center`">
          <img v-if="currentRouterUrl === `order-center`" class="img-balance-act" />
          <img v-else class="img-balance" />
          <span>{{ $t("home_0033") }}</span>
        </f7-link>
        <f7-link v-if="getLoginState" tab-link no-fast-click :href="`/member-center/vip/${userInfo.VipLevel}/`" :tab-link-active="currentRouterUrl === `vip`">
          <img v-if="currentRouterUrl === `vip`" class="img-promotion-act" />
          <img v-else class="img-promotion" />
          <span>{{ $t("app_0003") }}</span>
        </f7-link>
        <f7-link v-else tab-link no-fast-click :href="false" @click="openActions()">
          <img class="img-login" />
          <span>{{ $t("app_0002") }}</span>
        </f7-link>
        <f7-link tab-link no-fast-click href="/activity/0/" :ignore-cache="true" :tab-link-active="currentRouterUrl === `activity`">
          <img v-if="currentRouterUrl === `activity`" class="img-service-act" />
          <img v-else class="img-service" />
          <!-- <img class="img-service"> -->
          <span>{{ $t("app_0005") }}</span>
        </f7-link>
        <f7-link tab-link no-fast-click href="/member-center/" :ignore-cache="true" :tab-link-active="currentRouterUrl === `member-center`">
          <img v-if="currentRouterUrl === `member-center`" class="img-vip-act" />
          <img v-else class="img-vip" />
          <span>{{ $t("app_0006") }}</span>
        </f7-link>
      </f7-toolbar>

      <!-- Grid -->
      <f7-actions :grid="true" :opened="actionServiceOpened" @actions:closed="actionServiceOpened = false" class="actions-common actions-service">
        <f7-actions-group>
          <f7-actions-button :close="false">
            <f7-link :href="false" :class="{ 'link-active-qq': actionQQ }" @click="openServicePopup('qq')" icon="iconfont icon-qq" icon-size="24" :text="$t('app_0007')"></f7-link>
          </f7-actions-button>
          <f7-actions-button>
            <f7-link :href="false" icon="iconfont icon-kefu1" @click="onlineCustomService" icon-size="24" :text="$t('app_0008')"></f7-link>
          </f7-actions-button>
          <f7-actions-button :close="false">
            <f7-link :href="false" :class="{ 'link-active-wechat': actionWechat }" @click="openServicePopup('wechat')" icon="iconfont icon-weixin1" icon-size="24" :text="$t('app_0009')"></f7-link>
          </f7-actions-button>
        </f7-actions-group>
      </f7-actions>
      <f7-actions :grid="true" :opened="actionPromotionOpened" @actions:closed="actionPromotionOpened = false" class="actions-common actions-promotion">
        <f7-actions-group>
          <f7-actions-button>
            <f7-link href="/daily-task/" icon="iconfont icon-renwu" icon-size="24" :text="$t('app_0010')"></f7-link>
          </f7-actions-button>
          <f7-actions-button>
            <f7-link href="/activity/" icon="iconfont icon-youhuiquan" icon-size="26" :text="$t('app_0011', [f7params.name])"></f7-link>
          </f7-actions-button>
          <f7-actions-button>
            <f7-link @click="goPage('member-center')" icon="iconfont icon-youhuiquan1" icon-size="26" :text="$t('app_0015')"></f7-link>
          </f7-actions-button>
        </f7-actions-group>
      </f7-actions>
      <f7-actions :grid="true" :opened="actionTransferOpened" @actions:closed="actionTransferOpened = false" class="actions-common actions-transfer">
        <f7-actions-group>
          <f7-actions-button style="width: 50%">
            <f7-link href="/member-center/deposit/" icon="iconfont icon-cunkuan" icon-size="26" :text="$t('app_0012')"></f7-link>
          </f7-actions-button>
          <f7-actions-button style="width: 50%">
            <f7-link href="/member-center/withdraw/" icon="iconfont icon-qukuan" icon-size="26" :text="$t('app_0013')"></f7-link>
          </f7-actions-button>
          <!-- <f7-actions-button>
            <f7-link href="/member-center/transfer/" icon="iconfont icon-zhuanzhang" icon-size="26" :text="$t('app_0014')"></f7-link>
          </f7-actions-button> -->
        </f7-actions-group>
      </f7-actions>

      <!-- 在线客服popup -->
      <f7-popup v-show="showOnlinePopup" class="onlineCustom-popup" :opened="onlinePopupOpened" @popup:open="openOnlinePopup" @popup:close="closeOnlinePopup">
        <f7-page>
          <f7-navbar :title="$t('app_0016')">
            <f7-nav-right>
              <f7-link popup-close><i class="f7-icons">multiply_circle</i></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <iframe :src="onlineCustomUrl" class="iframe-onlineCustom" frameborder="0"></iframe>
        </f7-page>
      </f7-popup>
    </f7-views>
    <div class="dialog dialog-change-language" id="dialog-change-language" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t("member_center_0018") }}
          <f7-link @click="closeDialog('dialog-change-language')">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <div class="block-content">
              <div v-for="item in langList" :key="item.code">
                <f7-row v-show="item.code!='zhTW' || (item.code=='zhTW' && getShowTW()=='true')"  class="item-balance"  :class="`item-balance-${9999}`">
                  <f7-col width="25" class="icon-col">
                    <span :class="['icon-lang', 'icon-' + item.code]"></span>
                  </f7-col>
                  <f7-col width="45" class="col-balance">
                    <span>
                      <span>{{ item.name }}</span>
                    </span>
                  </f7-col>
                  <f7-col width="30">
                    <f7-row class="btn-group">
                      <f7-col>
                        <f7-button style="text-transform: none" raised fill class="btn-transfer" :class="{ 'btn-disabled': item.code == f7params.currLang }" @click="changeToTargetLanguage(item)">{{ $t("common_0041") }}</f7-button>
                      </f7-col>
                    </f7-row>
                  </f7-col>
                </f7-row>
              </div>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="closeDialog('dialog-change-language')">{{ $t("common_0002") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>



    <div class="dialog dialog-change-language" id="dialog-change-currency" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t("dialog_change_currency") }}
          <f7-link @click="closeDialog('dialog-change-currency')">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <div class="block-content">
              <f7-row class="item-balance" v-for="code in currencyList" :key="code" :class="`item-balance-${code}`">
                <f7-col width="25" class="icon-col">
                  <span :class="['icon-currency', 'icon-' + code]"></span>
                </f7-col>
                <f7-col width="45" class="col-balance">
                  <span>
                    <span>{{ code }}</span>
                  </span>
                </f7-col>
                <f7-col width="30">
                  <f7-row class="btn-group">
                    <f7-col>
                      <f7-button raised fill class="btn-transfer" :class="{ 'btn-disabled': code == currency }" @click="changeCurrency(code)">{{ $t("common_0041") }}</f7-button>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="closeDialog('dialog-change-currency')">{{ $t("common_0002") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>

    <div class="dialog dialog-activity" id="dialog-activity" @dialog:closed="balanceCloseEvent($event, 'dialog-activity')" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="activity-item" v-for="item of missionList" :key="item.MissionID">
          <img class="ico" :src="`../static/images/yt999/activity/${item.MissionType}.png`" alt="" />
          <div class="item-mid">
            <div class="title">{{ missionNameMaps[item.MissionType] }} {{ getTaskRange(item) }}</div>
            <i18n path="dialog_activity_0001" tag="div" class="desc">
              <span class="amount">{{ item.MaxBonusAmount }}</span>
            </i18n>
          </div>
          <f7-link class="btn-details" @click="goActivityPage(item)" :text="$t('dialog_activity_0003')"></f7-link>
        </div>
      </div>

      <div class="dialog-bottom">
        <f7-checkbox :checked="unShow" @change="setTodayNotDisplay()"></f7-checkbox>
        <div class="label">{{ $t("dialog_activity_0002") }}</div>
      </div>

      <div class="btn-close" @click="closeDialog('dialog-activity')">
        <img class="icon-close" src="../static/images/yt999/activity/close.png" />
      </div>
    </div>

    <!-- <div class="dialog dialog-orderConfirm" id="dialog-orderConfirm" @dialog:closed="balanceCloseEvent($event, 'dialog-orderConfirm')" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner" @click="closeDialog('dialog-orderConfirm')">
        <img :src="`../static/images/yt999/lucky_${f7params.currLang}.png`" style="width:100%" alt="">
      </div>
    </div> -->
  </f7-app>
</template>
<script>
import routes from "../router/routes";
import { getInfo, checkUserLogin, checkPermissions } from "../axios/api";
import http from "../axios/http";
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import common from "../service/common";
import axios from "axios";
import { getCookie, setCookie, setStore, getStore } from "../config/utils";
import { LANGLIST } from "../locales/index";
const brand = {
  def: {
    name: "Rangoon Gaming", // App name
    shortName: "Rangoon",
    fileName: "rg666",
    languageList: ["enUS", "thTH", "viVN", "idID", "msMY", "myMY", "ptPT", "zhTW", "urPK", "zhCN"],
  },
  ace88: {
    name: "KOI APP", // App name
    shortName: "KOI APP",
    fileName: "default",
    languageList: ["enUS", "urPK", "zhTW"],
  },
};
export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        routes,
        name: "KOI APP Market",
        id: "com.KOI APP.009",
        ...brand["ace88"],
        theme: "ios", // Automatic theme detection
        version: "1.0.0",
        currLang: getCookie("lang"),
        touch: {
          fastClicks: true, //删除链接和表单元素300毫秒的延迟
          fastClicksDelayBetweenClicks: 10,
          iosTouchRipple: true,
          tapHold: false, //enable tap hold events
        },
        input: {
          scrollIntoViewOnFocus: false,
          scrollIntoViewCentered: false,
          scrollIntoViewAlways: false,
        },
        view: {
          // 注意这里 pushState 是 view 的属性，直接是直接挂在 framework7 上
          pushState: true,
          pushStateSeparator: "#",
          swipeBack: false,
          preloadPreviousPage: false,
        },
        lazy: {
          placeholder: "../static/images/yt999/lazy-placeholder.png",
          threshold: 50,
          sequential: false,
          observer: !(common.getBrowser.isUC || common.getBrowser.isOppoBrowser),
        },
        dialog: {
          title: this.$t("common_1025"),
          backdrop: true,
          closeByBackdropClick: true,
          buttonOk: this.$t("common_0001"),
          buttonCancel: this.$t("common_0002"),
        },
        swiper: {
          lazy: {
            loadPrevNext: true,
          },
        },
        smartSelect: {
          sheetCloseLinkText: this.$t("common_0001"),
          closeOnSelect: true,
          formColorTheme: "pink",
          navbarColorTheme: "lightblue",
          routableModals: false,
          virtualList: true,
        },
        picker: {
          toolbarCloseText: this.$t("common_0001"),
        },

        // App root data
        data: function () {
          return {
            device: "",
          };
        },

        // App routes
        routes: routes,
      },
      actionServiceOpened: false,
      actionPromotionOpened: false,
      actionTransferOpened: false,
      actionQQ: false,
      actionWechat: false,
      swiperdWechat: {
        direction: "horizontal",
        spaceBetween: 30,
        disableOnInteraction: false,
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="swiper-pagination-bullet iconfont icon-weixin1"></span>';
          },
        },
      },
      swiperdQQ: {
        direction: "horizontal",
        spaceBetween: 30,
        disableOnInteraction: false,
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="swiper-pagination-bullet iconfont icon-qq"></span>';
          },
        },
      },
      customerService: {
        WeChat: {
          Account: [],
        },
        QQ: {
          Account: [],
        },
      },
      onlinePopupOpened: false,
      onlineCustomUrl: "",
      loginCheckInterval: null,
      currentRouterUrl: "home",
      unShow: /true/.test(getCookie("unShow")),
      showOnlinePopup: true,
    };
  },

  computed: {
    ...mapState(["currencyList", "currency", "missionList"]),
    ...mapGetters(["getMerchantInfo", "getLoginState", "onlineCustomLoaded", "missionNameMaps", "userInfo"]),
    langList() {
      return this.f7params.languageList.map((code) => LANGLIST[code]);
    },
  },
  methods: {
    ...mapMutations(["SETMERCHAT_INFO", "SETLOGINSTATE", "SET_ONLINECUSTOM_LOADSTATUS"]),
    ...mapActions(["saveLoginState"]),
    setTodayNotDisplay() {
      this.unShow = !this.unShow;
      const todayLastTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
      setCookie("unShow", this.unShow, todayLastTime);
    },
    getShowTW(){
      return getStore('ShowTW');
    },

    getInfo() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          const d = data.Data;
          self.SETMERCHAT_INFO(d); //存储Vuex store merchantinfo
          setStore(`ImagesURL`, d.ImagesURL);
          setStore(`MemberLevelMode`, d.MemberLevelMode);
          setStore(`currencySymbol`, d.BaseInfo.DefaultCurrencySymbol);
          setStore("ShowTW", data.Data.BaseInfo.ShowTW);

          self.$store.commit("SET_CURRENCY_LIST", d.SupportCurrency);
          self.$store.commit("SET_CURRENCY", getCookie("currency") || d.DefaultCurrencyCode || d.SupportCurrency[0]);

          if (d.BaseInfo.CSType == 2) {
            var s1 = document.createElement("script"),
              s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = d.BaseInfo.MobileService;
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
            window.Tawk_API.onLoad = function () {
              window.Tawk_API.hideWidget();
              self.SET_ONLINECUSTOM_LOADSTATUS(true);
            };
          }
        }
      });
    },

    openActions() {
      const self = this;
      if (self.getLoginState) {
        self.actionTransferOpened = true;
      } else {
        self.$f7.view.main.router.navigate({
          name: "login",
          path: "/login/",
        });
      }
    },
    openServiceAction() {
      const self = this;
      const baseInfo = self.getMerchantInfo.BaseInfo;
      // self.customerService = {
      //   WeChat: {
      //     'QR': (baseInfo && baseInfo.WeChatCS_QR) ? baseInfo.WeChatCS_QR.split('|') : [],
      //     'NickName': (baseInfo && baseInfo.WeChatCS_NickName) ? baseInfo.WeChatCS_NickName.split('|') : [],
      //     'Account': (baseInfo && baseInfo.WeChatCS) ? baseInfo.WeChatCS.split('|') : [],
      //   },
      //   QQ: {
      //     'QR': (baseInfo && baseInfo.QQ_QR) ? baseInfo.QQ_QR.split('|') : [],
      //     'NickName': (baseInfo && baseInfo.QQ_NickName) ? baseInfo.QQ_NickName.split('|') : [],
      //     'Account': (baseInfo && baseInfo.QQ) ? baseInfo.QQ.split('|') : [],
      //   }
      // }
      // self.actionServiceOpened = true;
      // self.onlineCustomUrl = baseInfo.MobileService;
      if (baseInfo.CSType == 2) {
        if (!this.onlineCustomLoaded) {
          let loading = self.$f7.dialog
            .create({
              title: "",
              text: "Loading...",
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            loading.close();
            this.openServiceAction();
          }, 1500);
        } else {
          window.Tawk_API.toggle();
        }
      } else {
        self.onlineCustomUrl = baseInfo.MobileService;
        self.onlinePopupOpened = true;
      }
    },
    openServicePopup(type) {
      const self = this;
      if (type === "qq") {
        self.$f7.actions.close(".actions-wechat");
        self.actionWechat = false;
        self.actionQQ = true;
      } else if (type === "wechat") {
        self.$f7.actions.close(".actions-qq");
        self.actionQQ = false;
        self.actionWechat = true;
      }
    },
    onCopy(e) {
      const self = this;
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: $t("common_0003"),
            cssClass: "dialog-preloadgame dialog-copyMsg",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    actionsOpenEvent() {
      this.$f7.lazy.loadImage(this.$f7.$(".qr-lazy"));
    },
    onlineCustomService() {
      const self = this;
      self.$f7.actions.close(".actions-qq");
      self.$f7.actions.close(".actions-wechat");
      self.onlinePopupOpened = true;
    },
    checkUserLogin() {
      const self = this;
      if (self.getLoginState) {
        checkUserLogin().then((data) => {
          if (!data.Data || data.Code === "SessionNotExist") {
            clearInterval(self.loginCheckInterval);
            self.SETLOGINSTATE(false);
            common.logoutEvent();
            // self.$f7.view.main.router.navigate({
            //   name: "home",
            //   path: "/",
            // });
          }
        });
      }
    },
    intervalLoginCheck() {
      const self = this;
      clearInterval(self.loginCheckInterval);
      self.checkUserLogin();
      self.loginCheckInterval = setInterval(() => {
        self.checkUserLogin();
      }, 30000);
    },
    checkPermissions() {
      const self = this;
      checkPermissions().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          self.saveLoginState();
          self.getInfo();
          if (self.getLoginState) {
            self.intervalLoginCheck();
          }
          if (!getCookie("u")) {
            const uid = common.uuid();
            setCookie("u", uid, 7);
          }
        } else {
          self.$f7.view.main.router.navigate({
            name: "limited-access",
            path: "/limited-access/",
          });
        }
      });
    },
    goPage(d) {
      setStore("mc-tab-active", 2);
      if (this.$f7.view.main.router.currentRoute.name === "member-center") {
        this.$f7.tab.show("#tab-selfHelpPrefer");
        return;
      }
      this.$f7.view.main.router.navigate({
        name: d,
        path: `/${d}/`,
      });
    },
    onViewInit() {
      const self = this;
      const languageList = this.f7params.languageList;
      if (this.f7params.currLang == "") {
        this.f7params.currLang = "ptPT";
      }
      if (Array.isArray(languageList) && !languageList.includes(this.f7params.currLang)) {
        if (languageList.length > 0) {
          this.changeLanguage(languageList[0]);
          window.location.reload();
        }
      }
      self.checkPermissions();
      self.$f7.on("myCustomEvent", function (a, b) {
        self.currentRouterUrl = a;
      });
    },
    balanceOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    balanceCloseEvent(e, targetId) {
      console.log(targetId);
      // this.$f7.$(".dialog-backdrop").css("background", "transparent");
      this.$f7.emit("onCloseDialog", targetId);
    },
    closeDialog(targetId) {
      this.$f7.dialog.close(`#${targetId}`);
      this.$f7.emit("onCloseDialog", targetId);
    },
    changeToTargetLanguage(item) {
      if (item.code == this.f7params.currLang) return;
      this.changeLanguage(item.code);
      window.location.reload();
      this.closeDialog("dialog-change-language");
    },
    changeCurrency(code) {
      if (code == this.currency) return;
      this.$store.commit("SET_CURRENCY", code);
      this.closeDialog("dialog-change-currency");
      window.location.reload();
    },
    getTaskRange(item) {
      if (["InviteMemberToOpenBonusBox", "TotalTurnoverToPlayTurnTable"].includes(item.MissionType)) return "";
      return `(${this.$t(`activity_center_${item.MissionTimeRangeType}`)})`;
    },
    goActivityPage(item) {
      this.closeDialog("dialog-activity");
      this.$f7.view.main.router.navigate(`/activity-center/${item.MissionID}/`);
    },
    openOnlinePopup() {
      this.showOnlinePopup = true;
    },
    closeOnlinePopup() {
      this.onlinePopupOpened = false;
      this.showOnlinePopup = false;
    },
  },
  created() {},
  mounted() {
    var self = this;
    // this.getMissionList()
    var host = window.location.host;
    // switch(host){
    //   case 'm.sdty22.com':
    //   case 'm.sdty2.com':
    //   case 'm.sdty3.com':
    //   case 'm.sdty4.com':
    //   case 'm.sdty6.com':
    //if(host.indexOf('yt888good')!=-1){
    // const script = document.createElement('script')
    // script.src = 'https://s4.cnzz.com/z_stat.php?id=1280227436&web_id=1280227436'
    // script.language = 'JavaScript'
    // document.body.appendChild(script)
    //break;
    //}
    //}

    switch (host) {
      case "m.sdty22.com":
        {
          // const script1 = document.createElement('script')
          // script1.src = 'https://s9.cnzz.com/z_stat.php?id=1280361041&web_id=1280361041'
          // script1.language = 'JavaScript'
          // document.body.appendChild(script1)
        }
        break;
      default:
        {
          document.title = 'Koi'
        }
        break;
    }

    if (window.acem) {
      window.acem.acevent();
    }

    self.$f7ready((f7) => {
      const self = this;
      var $html = self.$$("html");
      $html.removeClass("theme-dark theme-light").addClass(`theme-${window.configs.merchantAlias}`);
      if (self.$f7.device.iphoneX && getStore("device")) {
        self.$f7.$(".toolbar-footer").css("height", "80px");
        self.$f7.$(".toolbar-footer").css("margin-bottom", "-25px");
      }

      axios.interceptors.response.use(
        (res) => {
          // token 已过期，重定向到登录页面
          if (res.data.Code == "SessionNotExist" && !!getCookie("zz")) {
            common.logoutEvent();
            f7.dialog.alert(self.$t("app_0017"), self.$t("common_0004"), () => {
              f7.view.main.router.navigate({
                name: "login",
                path: `/login/`,
              });
            });
          }
          return res;
        },
        function (err) {
          return Promise.reject(err);
        }
      );
      document.oncontextmenu = function () {
        return false;
      };
    });
  },
  updated() {},
};
</script>

<style lang="less" scoped>
img.qr-lazy {
  width: 210px;
  height: 210px;
}

.iframe-onlineCustom {
  width: 100%;
  height: calc(100% - 0px);
  min-height: calc(100% - 0px);
}

.game-more {
  width: 95%;
  margin: 0 auto;
  position: absolute;
  z-index: 5001;
  bottom: 70px;
}

.fab-right-bottom {
  bottom: 100px;
  z-index: 5002;

  /deep/ a {
    background-color: transparent;
    box-shadow: unset;
    overflow: unset;
  }
}

.game-list-more {
  width: 24.5%;
  margin-left: 2.5%;
  height: 14px;
  position: relative;
  bottom: 0;
  background-color: #152b5f;
  text-align: center;

  img {
    width: 12px;
    height: 12px;
  }
}

.icon-col {
  display: flex;
  align-items: center;
}

.icon-lang {
  background-image: url("../static/images/yt999/home/lang-map.png");
  background-repeat: no-repeat;
  width: 35px;
  height: 24px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  display: inline-block;
  background-position-y: 0px;
  background-size: 34px auto;
  

  &.icon-zhCN {
    background-position-y: -34px;
  }

  &.icon-viVN {
    background-position-y: -69px;
  }

  &.icon-thTH {
    background-position-y: -104px;
  }

  &.icon-idID {
    background-position-y: -138px;
  }

  &.icon-msMY {
    background-position-y: -173px;
  }

  &.icon-myMY {
    background-position-y: -312px;
  }

  &.icon-zhTW {
    background-position-y: -277px;
  }

  &.icon-deDE {
    background-position-y: -418px;
  }

  &.icon-ptPT {
    background-position-y: -383px;
  }

  &.icon-esES {
    background-position-y: -453px;
  }

  &.icon-koKR {
    background-position-y: -488px;
  }

  &.icon-jaJP {
    background-position-y: -523px;
  }

  &.icon-urPK {
    background-position-y: -418px;
  }
}

.icon-currency {
  background-image: url("../static/images/yt999/currency-map.png");
  background-repeat: no-repeat;
  width: 28px;
  height: 22px;
  display: inline-block;
  background-position-y: 0px;
  background-size: cover;

  @currency: USD, USD, USD, USD, USD, USD, USD, USD, USD, USD, USD, USD;

  each(@currency, {
    &.icon-@{value} {
      background-position-x: ((@index - 1) * -44px);
    }
  });
}
</style>
