<template>
  <f7-page no-toolbar no-swipeback name="financial" class="page-center" @page:init="onPageInit()">
    <f7-navbar innerClass="navbar-inner">
      <f7-nav-left>
        <f7-link @click="$f7router.navigate('/member-center/')">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("jobApplication_001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="content">
      <div class="info">
        <div>
          <van-steps :active="state" active-color="#ffb800" center active-icon="checked" >
            <van-step>{{ $t("jobApplication_002") }}</van-step>
            <van-step>{{ $t("jobApplication_003") }}</van-step>
            <van-step active-icon="clear">{{ $t("jobApplication_004") }}</van-step>
          </van-steps>
        </div>

        <div v-if="state==1" class="state1">
          <p style="font-weight: bold;">{{ $t("jobApplication_005") }}...</p>
          <p style="font-weight: bold;">{{ $t("jobApplication_006") }}：<span style="color: red;">{{ referrerlevelupgrade_status.TargetReferrerLevelName}}</span></p>
          <p style="color: #ccc;">{{ referrerlevelupgrade_status.UpgradeRequestCreateTS | time2}}</p>
        </div>
        <div v-else-if="state==2" class="state1">
          <p style="font-weight: bold;">{{ $t("jobApplication_007") }}：</p>
          <p style="font-weight: bold;"><span style="color: red;">{{ referrerlevelupgrade_status.TargetReferrerLevelName}}</span></p>
          <p style="color: #ccc;">{{ referrerlevelupgrade_status.VerifyTS | time2 }}</p>
        </div>
        <div v-else-if="state==3" class="state1">
          <p style="font-weight: bold;">{{ $t("jobApplication_008") }}：</p>
          <p style="font-weight: bold;"><span style="color: red;display: inline-block;width: 100%;word-wrap: break-word;">{{ referrerlevelupgrade_status.VerifyRemark==''?'- -':referrerlevelupgrade_status.VerifyRemark }}</span></p>
          <p style="color: #ccc;">{{ referrerlevelupgrade_status.VerifyTS | time2 }}</p>
        </div>
        
        <div class="upload" v-if="(referrerlevelupgrade_status.MemberDocumentsVerifyStatus=='NotVerify'||referrerlevelupgrade_status.MemberDocumentsVerifyStatus=='VerifyFailed')">
          <div style="font-size: 16px;font-weight: bold;margin-bottom: 10px;">{{ $t("jobApplication_009") }}</div>
          <div style="color: red;">{{ $t("jobApplication_010") }}：</div>
          <div>{{ $t("member_info_0036") }}</div>
          <div>{{ $t("member_info_0037") }}</div>
          <div>{{ $t("member_info_0038") }}</div>
          <div>{{ $t("member_info_0039") }}</div>
          <div class="photo">
            <div class="upload-box">
              <van-uploader  v-model="filesArr[0].imageFiles" :max-count="1" multiple :upload-text="$t('jobApplication_011')" :max-size="5 * 1024 * 1024" accept="image/*" preview-size="100px" @oversize="onOversize" />
            </div>
            <div class="upload-box">
              <van-uploader  v-model="filesArr[1].imageFiles" :max-count="1" multiple :upload-text="$t('jobApplication_012')" :max-size="5 * 1024 * 1024" accept="image/*" preview-size="100px" @oversize="onOversize" />
            </div>
            <div class="upload-box">
              <van-uploader  v-model="filesArr[2].imageFiles" :max-count="1" multiple :upload-text="$t('jobApplication_013')" :max-size="5 * 1024 * 1024" accept="image/*" preview-size="100px" @oversize="onOversize" />
            </div>
          </div>
        </div>


        <div style="font-size: 16px;font-weight: bold;margin-bottom: 10px;">{{ $t("jobApplication_014") }}</div>
        <div>
          <table class="mytab">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("jobApplication_015") }}</th>
                <th>{{ $t("jobApplication_016") }}</th>
                <th>{{ $t("jobApplication_017") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in referrerlevelbymemberlist" :key="index">

                <td ><input type="radio" name="post" id="" v-model="selectLevelID" :value="item.ReferrerLevelID" :disabled="item.ReferrerLevelID<=referrerlevelupgrade_status.CurrentReferrerLevelID||state==1"></td>
                
                <td v-if="item.ReferrerLevelID==referrerlevelupgrade_status.TargetReferrerLevelID" class="txt3">{{ item.ReferrerLevelName }}</td>
                <td v-else-if="item.ReferrerLevelID<referrerlevelupgrade_status.CurrentReferrerLevelID" class="txt1">{{ item.ReferrerLevelName }}</td>
                <td v-else-if="item.ReferrerLevelID==referrerlevelupgrade_status.CurrentReferrerLevelID" class="txt2">{{ item.ReferrerLevelName }}</td>
                <td v-else >{{ item.ReferrerLevelName }}</td>

                <td v-if="item.ReferrerLevelID==referrerlevelupgrade_status.TargetReferrerLevelID" class="txt3">{{ item.BasicMonthlyBonusAmount | formatCurrency2 }}</td>
                <td v-else-if="item.ReferrerLevelID<referrerlevelupgrade_status.CurrentReferrerLevelID" class="txt1">{{ item.BasicMonthlyBonusAmount | formatCurrency2 }}</td>
                <td v-else-if="item.ReferrerLevelID==referrerlevelupgrade_status.CurrentReferrerLevelID" class="txt2">{{ item.BasicMonthlyBonusAmount | formatCurrency2 }}</td>
                <td v-else >{{ item.BasicMonthlyBonusAmount | formatCurrency2 }}</td>

                <td v-if="item.ReferrerLevelID==referrerlevelupgrade_status.TargetReferrerLevelID" class="txt3">{{ item.AdditionalMonthlyBonusAmountPerSubordinateActiveVIP | formatCurrency2 }}</td>
                <td v-else-if="item.ReferrerLevelID<referrerlevelupgrade_status.CurrentReferrerLevelID" class="txt1">{{ item.AdditionalMonthlyBonusAmountPerSubordinateActiveVIP | formatCurrency2 }}</td>
                <td v-else-if="item.ReferrerLevelID==referrerlevelupgrade_status.CurrentReferrerLevelID" class="txt2">{{ item.AdditionalMonthlyBonusAmountPerSubordinateActiveVIP | formatCurrency2 }}</td>
                <td v-else >{{ item.AdditionalMonthlyBonusAmountPerSubordinateActiveVIP | formatCurrency2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="margin: 15px 0px;display: flex;" @click="onHelp()">
          <img src="../../../static/images/yt999/position/help.png" width="18" ><span style="color: #0075ff;text-decoration: underline;margin-left: 7px;">{{ $t("jobApplication_018") }}</span>
        </div>
        <div style="margin: 15px 0px;display: flex;">
          <img v-if="check" src="../../../static/images/yt999/position/check_box.png" width="18" style="margin-right: 7px;width: 18px;height: 18px;" @click="check=false">
          <img v-if="!check" src="../../../static/images/yt999/position/check_no.png" width="18" style="margin-right: 7px;width: 18px;height: 18px;" @click="check=true">
          {{ $t("jobApplication_019") }}
        </div>
        <div class="btn" @click="mysubmit()">{{ $t("jobApplication_020") }}</div>
      </div>
     
    </div>
    <!-- <van-popup v-model="helpShow"  position="bottom"  style="height: 80%;border-radius: 10px 10px 0 0; " closeable  close-icon="close">
      <div class="title">{{ $t("jobApplication_018") }}</div>
      <div v-html="referrerlevelupgradecopywritingbymemberget" style="padding: 10px;"></div>
    </van-popup> -->
    <f7-popup v-show="helpShow" animate backdrop id="popup-marquee" class="popup-marquee" @popup:opened="marqueePopupOpened()" @popup:close="marqueePopupClose()">
      <f7-page>
        <f7-navbar style="color: black;background-color:rgb(248 248 249);" :title="$t('jobApplication_018')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block >
         <div v-html="referrerlevelupgradecopywritingbymemberget" style="padding: 10px;"></div>
        </f7-block>
      </f7-page>
    </f7-popup>

  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getreferrerlevelupgradecopywritingbymemberget,getreferrerlevelbymemberlist,getreferrerlevelupgrade_status,uploadmemberdocumentsinfo } from "../../../axios/api";
import $upload from "@/service/upload";

export default {
  components: {},
  props: {},
  data() {
    return {
      active:1,
      filesArr: [
        { name: "image_a", imageFiles: [] },
        { name: "image_b", imageFiles: [] },
        { name: "image_c", imageFiles: [] },
        { name: "isReferrerLevelUpgrade", value: 'true' },  //用于晋级审核的时候
        { name: "isUploadDocuments", value: 'true' },   //需要上传证件照的时候
        { name: "targetReferrerLevelID", value: '' },   //申请的职位
      ],
      referrerlevelupgradecopywritingbymemberget:'',
      referrerlevelbymemberlist:[],
      referrerlevelupgrade_status:{},
      currentReferrerLevelID:0,
      selectLevelID:0,
      helpShow:false, 
      check:false,
      state:0,
      mysubmitBtn:true,
    };
  },
  watch: {

  },
  computed: {},
  methods: {
    onPageInit() {
      const self = this;
      getreferrerlevelupgradecopywritingbymemberget().then((data) => {
        if (data.Code === "NoError") {
          self.referrerlevelupgradecopywritingbymemberget = data.Data.RuleContent;
        }
      });
      getreferrerlevelbymemberlist().then((data) => {
        if (data.Code === "NoError") {
          self.referrerlevelbymemberlist = data.Data;
        }
      });
      getreferrerlevelupgrade_status().then((data) => {
        if (data.Code === "NoError") {
          self.referrerlevelupgrade_status = data.Data;
          self.currentReferrerLevelID = self.referrerlevelupgrade_status.CurrentReferrerLevelID;
          if (self.referrerlevelupgrade_status.TargetReferrerLevelID>0) {
            self.selectLevelID = self.referrerlevelupgrade_status.TargetReferrerLevelID;
          } else if(self.referrerlevelupgrade_status.CurrentReferrerLevelID>0){
            self.selectLevelID = self.referrerlevelupgrade_status.CurrentReferrerLevelID;
          }
          if (!self.referrerlevelupgrade_status.IsOnProcessing) {
            self.state = 0; 
          }else{
            if(data.Data.MemberReferrerLevelUpgradeRequestVerifyStatus=='Init'){
              //待审核
              self.state = 1;
            }else if(data.Data.MemberReferrerLevelUpgradeRequestVerifyStatus=='Approved'){
              //通过
              self.state = 2;
            }else if(data.Data.MemberReferrerLevelUpgradeRequestVerifyStatus=='Rejected'){
              //失败
              self.state = 3;
            }
          }
        }
      });
    },
    marqueePopupOpened() {
      var self = this;
      self.helpShow = true;

    },
    marqueePopupClose() {
      this.helpShow = false;
    },
    onOversize() {
      this.$f7.dialog.alert(this.$t("member_info_0044"), this.$t("common_0004") );
    },
    onHelp(){
      this.helpShow =  true;
      this.$f7.popup.open('#popup-marquee');
    },
    mysubmit(){
      const self = this;

      if (!self.mysubmitBtn) {
        console.log(11)
        return;
      }


      if ('Init' == self.referrerlevelupgrade_status.MemberReferrerLevelUpgradeRequestVerifyStatus && self.referrerlevelupgrade_status.IsOnProcessing) {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(this.$t("jobApplication_021"), this.$t("common_0004") );
        return false;
      }
      if (!self.check) {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(this.$t("jobApplication_022") , this.$t("common_0004"));
        return;
      }
      if (self.selectLevelID == self.referrerlevelupgrade_status.CurrentReferrerLevelID) {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(this.$t("jobApplication_023") , this.$t("common_0004"));
        return false;
      }
      if (self.referrerlevelupgrade_status.MemberDocumentsVerifyStatus=='NotVerify'||self.referrerlevelupgrade_status.MemberDocumentsVerifyStatus=='VerifyFailed') {
        self.filesArr[4].value = true;    //证照未上传和失败，需要上传证照
      }else{
        self.filesArr[4].value = false;    //证照上传中和成功，不需要上传证照
      }
      self.filesArr[5].value = self.selectLevelID;

      //需要上传证件
      if((self.referrerlevelupgrade_status.MemberDocumentsVerifyStatus=='NotVerify'||self.referrerlevelupgrade_status.MemberDocumentsVerifyStatus=='VerifyFailed')){
        if (self.filesArr[0].imageFiles.length == 0) {
          self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
          self.$f7.dialog.alert(this.$t("member_info_0045"), this.$t("common_0004"));
          return false;
        }
        if (self.filesArr[1].imageFiles == 0) {
          self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
          self.$f7.dialog.alert(this.$t("member_info_0046"), this.$t("common_0004"));
          return false;
        }
        if (self.filesArr[2].imageFiles == 0) {
          self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
          self.$f7.dialog.alert(this.$t("member_info_0047"), this.$t("common_0004"));
          return false;
        }
      }else{
        //删除前面3个图片
        self.filesArr.splice(0, 3);
      }
      
      let Loading = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("jobApplication_024"),
          cssClass: "dialog-preloadgame",
        }).open();
      self.mysubmitBtn = false;
      $upload.uploadImg(self.filesArr).then((data) => {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));

        self.mysubmitBtn = true;
        Loading.close();   //关闭申请中提示
        
        if (data.Code === "NoError") {
          self.state = 1;
          getreferrerlevelupgrade_status().then((data) => {
            if (data.Code === "NoError") {
              self.referrerlevelupgrade_status = data.Data;
              if (!self.referrerlevelupgrade_status.IsOnProcessing) {
                self.state = 0; 
              }else{
                if(data.Data.MemberReferrerLevelUpgradeRequestVerifyStatus=='Init'){
                  //待审核
                  self.state = 1;
                }else if(data.Data.MemberReferrerLevelUpgradeRequestVerifyStatus=='Approved'){
                  //通过
                  self.state = 2;
                }else if(data.Data.MemberReferrerLevelUpgradeRequestVerifyStatus=='Rejected'){
                  //失败
                  self.state = 3;
                }
              }
            }
          });
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner {
  //头部颜色更换
  color: white;
  background: linear-gradient(to right, #357ae2, #1f64cd);
  a {
    color: white;
  }
}
.content {
  padding: 10px;
  min-height: 98%;
  background: var(--f7-THEME-color2);
  background-image: url(../../../static/images/yt999/member_center_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 12%;
  position: relative;

  .info {
    background: white;
    border-radius: 10px;
    padding: 10px 10px 20px 10px;
    margin-top: 10px;
    .state1{
      background: #fff6f6;
      border: 1px solid #eaeaea;
      width: 93%;
      margin: 0 auto;
      border-radius: 10px;
      padding: 2px 10px;
      margin-bottom: 15px;
      p{
        margin: 3px 0;
      }
    }
    ::v-deep .van-step--horizontal .van-step__title{
      font-size: 14px;
    }
    ::v-deep .van-step--horizontal .van-step__icon{
      font-size: 20px;
    }
    ::v-deep .van-step__circle{
      width: 15px;
      height: 15px;
    }
    ::v-deep .van-step--horizontal .van-step__title{
      // margin-left: 9px;
    }
    ::v-deep .van-step--finish .van-step__circle, .van-step--finish .van-step__line{
      color: var(--f7-THEME-color);
    }
    ::v-deep .van-uploader__preview{
      margin: auto;
    }
    .photo{
      display: grid;
      grid-template-columns: auto auto auto;
      padding: 15px 0;
      .upload-box {
        display: grid;
        text-align: center;
      }
      ::v-deep .van-uploader__upload{
        margin: auto;
        border-radius: 10px;
      }

    }
    .mytab{
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      th,td{
        border: 1px solid #d6d6d6;
        height: 30px;
      }
      /* 隐藏默认的 radio 按钮 */
      input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #ccc;
        position: relative;
        cursor: pointer;
        outline: none;
      }
      /* 选中时的样式 */
      input[type="radio"]:checked {
        border-color: var(--f7-THEME-color2); /* 外圈颜色 */
      }

      input[type="radio"]:checked {
        background-color: var(--f7-THEME-color);; /* 选中的颜色 */
      }
      .txt1{
        color: #ccc;
      }
      .txt2{
        color: blue;
      }
      .txt3{
        color: red;
      }
    }
    .btn{
      width: 90%;
      height: 50px;
      line-height: 50px;
      background: var(--f7-THEME-color);
      margin: 0 auto;
      color: white;
      font-size: 16px;
      text-align: center;
      border-radius: 10px;
    }
  }
  
}
::v-deep .page-content::after {
  height: 0;
}
</style>
